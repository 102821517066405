import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm } from "react-hook-form";

import "../../../assets/css/TeamFeatures.css";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import HandlerApp from "../../../utils/handlerApp";
import { useFetch } from "../../../hooks/useFecth";

const CardCreation = ({ title, description, profile, icon, endpoint }) => {

    const nav = useNavigate();


    const handleUserCreation = () => {
        nav("/" + endpoint + "/create", { state: { profile_id: profile } });
    }

    return (
        <button className="team_card-creation" onClick={() => { handleUserCreation() }}>
            <div className="team_circle-image"><span className="team_circle-icon">{icon}</span></div>
            <div className="team_card-info">
                <h3 className="team_card-info-title">{title}</h3>
                <p className="team_card-info-description">{description}</p>
            </div>
        </button>
    )
}

const CalendarForm = ({ endpoint, visible, setOptionsVisibleForm, id }) => {

    const [t, i18n] = useTranslation("global");
    const handlerApp = HandlerApp();
    const api = useFetch();
    const nav = useNavigate();

    const [bookingDateTime, setBookingDateTime] = useState("");


    const options = [
        {
            "code": 1,
            "name": t("day")
        },
        {
            "code": 3,
            "name": t("threeDay")
        },
    ]

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    useEffect(() => {

        if (bookingDateTime?.hasOwnProperty('_d')) {
            setFormData({ ...formData, booking_datetime: bookingDateTime?.format('YYYY-MM-DD') });
        }
    }, [bookingDateTime]);

    const [formData, setFormData] = useState({
        customer_id: "",
        user_id: "",
        booking_datetime: "",
        booking_observations: "",
        booking_total: "",
        booking_total_taxes: "",
        service_id: "",
        customer_id: "",
        product_id: "",
        bookingdetail_start_hour: "",
        bookingdetail_end_hour: "",
        bookingdetail_price: "",
        bookingdetail_quantity: "",
    });

    // useEffect(() => {
    //     handlerApp.setLoader(true);
    //     api.get(endpoint, {
    //         action: "getParamsUpdate"
    //     })
    //         .then(response => {
    //             if (response.success) {
    //                 setCompanies(handlerApp.getResultFromObject(response.data, 'companies'))
    //                 setCountries(handlerApp.getResultFromObject(response.data, "countries"))
    //                 if (id !== undefined) {
    //                     setTitle('customers.updateCustomer')
    //                     sendRequest();
    //                 } else {
    //                     handlerApp.setLoader(false);
    //                 }
    //             } else {
    //                 handlerApp.setLoader(false);
    //             }
    //         })
    //         .catch(error => {
    //             handlerApp.setLoader(false);
    //             handlerApp.showError(t(error.message));
    //         })
    // }, [])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
    }


    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);

        data.booking_datetime = bookingDateTime.format('YYYY-MM-DD')
        api.post(endpoint, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {

        handlerApp.setLoader(true);
        data.booking_datetime = bookingDateTime.format('YYYY-MM-DD')

        api.put(endpoint + "/" + id, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    return (
        <>
            {(visible) &&
                <section className="team_options-aside-fixed">
                    <div className="team_options-header">
                        <button className="team_options-close-button" onClick={() => { setOptionsVisibleForm(!visible) }}>
                            <span className="material-symbols-outlined">close</span>
                        </button>
                        <h3 className="team_options-title">{t('calendar.createAppoiment')}</h3>
                    </div>
                    <div className="team_options-body">
                        <div className="section-form">
                            <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)} >
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <BoldSelectValidated
                                            title={t('calendar.customer')}
                                            value={formData.barberlevel_id}
                                            name="barberlevel_id"
                                            onChange={handleForm}
                                            options={options}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <BoldSelectValidated
                                            title={t('servicesComponent.service')}
                                            value={formData.location_id}
                                            name="location_id"
                                            onChange={handleForm}
                                            options={options}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                        />
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <BoldSelectValidated
                                            title={t('storageComponent.barber')}
                                            value={formData.location_id}
                                            name="location_id"
                                            onChange={handleForm}
                                            options={options}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                        />
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <DatePicker
                                            label={t('calendar.date')}
                                            format="YYYY/MM/DD"
                                            name="userbarber_start_date"
                                            variant="outlined"
                                            onChange={(val) => { setBookingDateTime(val); }}
                                            value={bookingDateTime}
                                            slotProps={{ textField: { size: 'small', variant: "outlined" } }}
                                        />
                                    </div>

                                </div>

                                <div className="modal-booking-footer">
                                    <div className="row d-flex justify-content-center mt-auto">
                                        <div className="col-md-10 mb-2">
                                            {id === null && <button className="btn btn-primary-yellow-1 btn-full-width" type="submit">{t('create')}</button>}
                                            {id && <button className="btn btn-primary-yellow-1 btn-full-width" type="submit">{t('update')}</button>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}
export { CalendarForm, CardCreation }
