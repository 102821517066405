import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const HeaderEditScreen = ({title})=>{

    const nav = useNavigate();
    const [t] = useTranslation('global');

    return(
        <section className="container-header-screen">
            <div className="d-flex justify-content-between">
                <h3 className="title-screen">{title}</h3>
                <p role="button" onClick={()=>{nav(-1)}} className="d-flex align-items-center">
                    <span className="material-symbols-outlined" style={{fontSize: 16, marginRight: 5}}>close</span>
                    {t('cancel')}
                </p>
            </div>
        </section>
    )
}

export default HeaderEditScreen;