import React, { useEffect, useState } from "react";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox, Icon, TextField } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';



const CardSimpleWithImageServices = ({ item, index, isChecked, onCheckboxChange, formData, setFormData, selectAllChecked }) => {
    const [iconSeleccionado, setIconSeleccionado] = useState(false);
    const [t, i18n] = useTranslation("global");

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const handleCheckboxChange = () => {
        const updatedFormData = [...formData];
        updatedFormData[index].servicebarber_active = !formData[index].servicebarber_active;
        setFormData(updatedFormData);
    };

    const handleIconClick = () => {
        setIconSeleccionado(!iconSeleccionado);
    };


    const handleForm = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = [...formData];
        updatedFormData[index][name] = parseFloat(value);
        setFormData(updatedFormData);
    };

    return (
        <>
            <div className={`containerCard ${iconSeleccionado ? 'colorCard' : ''}`}>
                <div className="alignCard">
                <Checkbox
                    style={{ color: iconSeleccionado ? "#ffffff" : "" }}
                    checked={selectAllChecked || formData[index].servicebarber_active}
                    onChange={handleCheckboxChange}
                />
                    <div className="image-with-name">
                        {(item.user_photo != null) ?
                            <img className="bold-image-table" src={item.user_photo} alt={item.barber_name} />
                            : <div className="ini-not-image">{item.barber_name.substring(0, 1) + item.barber_name.substring(0, 1)}</div>}
                        <p style={{ color: iconSeleccionado ? '#ffffff' : 'inherit' }} >{item.barber_name}</p>
                    </div>
                </div>
                <div className="iconCard" style={{ backgroundColor: iconSeleccionado ? '#F0B669' : 'inherit' }} onClick={handleIconClick}>
                    <TuneIcon style={{ fontSize: 18 }} />
                </div>

            </div>


            {/* Lista secundaria (renderizada) */}
            <div className={`subContainer-card ${iconSeleccionado ? 'oculto' : ''}`}>
                {
                    <>
                        <div style={{ display: 'flex', marginLeft: 10, marginTop: 10 }}>
                            <TextField
                                name="servicebarber_price"
                                className="barberlytics_text-field w-98"
                                label={t("servicesComponent.price")} variant="outlined" size="small" type="number"
                                value={formData[index].servicebarber_price}
                                onChange={(e) => handleForm(e, index)}
                            />
                            <TextField
                                name="servicebarber_new_client_price"
                                className="barberlytics_text-field w-98"
                                label={t("servicesComponent.priceNewClient")} variant="outlined" size="small" type="number"
                                value={formData[index].servicebarber_new_client_price}
                                onChange={(e) => handleForm(e, index)}
                            />
                        </div>
                        <div style={{ display: 'flex', marginLeft: 10 }}>
                            <TextField
                                name="servicebarber_duration"
                                className="barberlytics_text-field w-98"
                                label={t("servicesComponent.timeMiunutes")} variant="outlined" size="small" type="number"
                                value={formData[index].servicebarber_duration}
                                onChange={(e) => handleForm(e, index)}
                            />
                            <TextField
                                name="servicebarber_fee_value"
                                className="barberlytics_text-field w-98"
                                label={t("servicesComponent.extraCommission")} variant="outlined" size="small" type="number"
                                value={formData[index].servicebarber_fee_value}
                                onChange={(e) => handleForm(e, index)}
                            />
                        </div>
                        <div style={{ display: 'flex', marginLeft: 10 }}>
                            <TextField
                                className="barberlytics_text-field inputNumber"
                                label={t("servicesComponent.priceLoyalty")} variant="outlined" size="small" type="number"
                            />
                        </div>
                    </>
                }

            </div>
        </>


    );
};
export default CardSimpleWithImageServices;