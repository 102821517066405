import { createBrowserRouter } from "react-router-dom";
import Home from "../components/pages/Home";
import Error404 from "../components/pages/Error404";
import Login from "../components/pages/Login";
import Products from "../components/pages/Products/Products";
import TablesView from "../components/pages/TablesView";
import ProductsForm from "../components/pages/Products/ProductsForm";
import Companies from "../components/pages/Companies/Companies";
import Locations from "../components/pages/Locations/Locations";
import App from "../components/App";
import LocationsForms from "../components/pages/Locations/LocationsForm";
import LocationsView from "../components/pages/Locations/LocationsView";
import CompaniesView from "../components/pages/Companies/CompaniesView";
import CompaniesForms from "../components/pages/Companies/CompaniesForm";
import ProductsView from "../components/pages/Products/ProductsView";
import Categories from "../components/pages/Categories/Categories";
import CategoriesView from "../components/pages/Categories/CategoriesView";
import CategoriesForms from "../components/pages/Categories/CategoriesForms";
import Subcategories from "../components/pages/Subcategories/Subcategories";
import SubcategoriesForms from "../components/pages/Subcategories/SubcategoriesForm";
import SubcategoriesView from "../components/pages/Subcategories/SubcategoriesView";
import ProductsLocations from "../components/pages/ProductsLocations/ProductsLocations";

import Orders from "../components/pages/Orders/Orders";
import OrdersView from "../components/pages/Orders/OrdersView";
import UsersLocations from "../components/pages/UsersLocations/UsersLocations";
import UsersLocationsView from "../components/pages/UsersLocations/UsersLocationsView";
import UsersLocationsForms from "../components/pages/UsersLocations/UsersLocationsForm";
import Users from "../components/pages/TeamMembers/Users";
import UsersView from "../components/pages/TeamMembers/UsersView";
import UsersForms from "../components/pages/TeamMembers/UsersForm";

import ProductsLocationsView from "../components/pages/ProductsLocations/ProductsLocationsView";
import ProductsLocationsForms from "../components/pages/ProductsLocations/ProductsLocationsForm";
import Transactions from "../components/pages/Transactions/Transactions";
import TransactionsView from "../components/pages/Transactions/TransactionsView";
import UsersCompanies from "../components/pages/UsersCompanies/UsersCompanies";
import UsersCompaniesView from "../components/pages/UsersCompanies/UsersCompaniesView";
import UsersCompaniesForm from "../components/pages/UsersCompanies/UsersCompaniesForm";
import ProductLocation2 from "../components/pages/ProductsLocation2/ProductLocation2";
import ProductLocation2View from "../components/pages/ProductsLocation2/ProductLocation2View";
import ProductLocation2Form from "../components/pages/ProductsLocation2/ProductLocation2Form";

import UserProfileForm from "../components/pages/UsersProfile/UserProfileForm";
import TaxesGroup from "../components/pages/TaxesGroup/TaxesGroup";
import TaxesGroupForm from "../components/pages/TaxesGroup/TaxesGroupForm";
import TaxesGroupView from "../components/pages/TaxesGroup/TaxesGroupView";
import VerifyOTP from "../components/pages/VerifyOTP";
import Resources from "../components/pages/Resources/Resources";
import ResourcesView from "../components/pages/Resources/ResourcesView";
import ResourcesForm from "../components/pages/Resources/ResourcesForm";
import BarberLevels from "../components/pages/BarberLevels/BarberLevels";
import BarberLevelsView from "../components/pages/BarberLevels/BarberLevelsView";
import BarberLevelsForm from "../components/pages/BarberLevels/BarberLevelsForm";
import SignUp from "../components/pages/SignUp";
import Container from "../components/theme/Barberlytics/pages/Container";
import ForgotNumber from "../components/pages/ForgotNumber";
import Contact from "../components/pages/Contact";

import Brands from "../components/pages/Brands/Brands";
import BrandsForms from "../components/pages/Brands/BrandsForms";
import BrandsView from "../components/pages/Brands/BrandsView";

import Suppliers from "../components/pages/Suppliers/Suppliers";
import SuppliersForms from "../components/pages/Suppliers/SuppliersForms";
import SuppliersView from "../components/pages/Suppliers/SuppliersView";

import Services from "../components/pages/Services/Services";
import ServicesView from "../components/pages/Services/ServicesView";
import ServicesForm from "../components/pages/Services/ServicesForm";
import ServicesCombo from "../components/pages/ServicesCombo/ServicesCombo";
import ServicesComboForm from "../components/pages/ServicesCombo/ServicesComboForm";

import ServicesCategories from "../components/pages/ServicesCategories/ServicesCategories";
import ServicesCategoriesForms from "../components/pages/ServicesCategories/ServicesCategoriesForms";
import ServicesCategoriesView from "../components/pages/ServicesCategories/ServicesCategoriesView";

import Customers from "../components/pages/Customers/Customers";
import CustomersForms from "../components/pages/Customers/CustomersForms";
import CustomersView from "../components/pages/Customers/CustomersView";
import ServicesLocation from "../components/pages/ServicesLocations/ServicesLocation";
import ServicesLocationForms from "../components/pages/ServicesLocations/ServicesLocationForms";
import ServicesLocationView from "../components/pages/ServicesLocations/ServicesLocationView";

import Storages from "../components/pages/Storages/Storages";
import StoragesView from "../components/pages/Storages/StoragesView";
import StoragesForms from "../components/pages/Storages/StoragesForms";
import PoliciesView from "../components/pages/Policies/PoliciesView";
import Policies from "../components/pages/Policies/Policies";

import Subscriptions from "../components/pages/Subscriptions/Subscriptions";
import SubscriptionsView from "../components/pages/Subscriptions/SubscriptionsView";
import SubscriptionsForms from "../components/pages/Subscriptions/SubscriptionsForms";

import ServicesBarbers from "../components/pages/ServicesBarbers/ServicesBarbers";
import ServicesBarbersView from "../components/pages/ServicesBarbers/ServicesBarbersView";
import ServicesBarbersForms from "../components/pages/ServicesBarbers/ServicesBarbersForms";
import Barbers from "../components/pages/Barbers (users)/Barbers";
import BarbersForms from "../components/pages/Barbers (users)/BarbersForms";
import BarbersView from "../components/pages/Barbers (users)/BarbersView";
import BarbersPermissionsForms from "../components/pages/Team/BarbersPermissionsForms";

import Notes from "../components/pages/Notes/Notes";
import NotesForms from "../components/pages/Notes/NotesForms";
import NotesView from "../components/pages/Notes/NotesView";


import CustomerReputation from "../components/pages/CustomerReputation/CustomerReputation";
import CustomerReputationForms from "../components/pages/CustomerReputation/CustomerReputationForms";
import CustomerReputationView from "../components/pages/CustomerReputation/CustomerReputationView";
import NotificacionesBarbers from "../components/pages/Barbers (users)/NotificacionesBarbers";
import ServicesTeamScreen from "../components/pages/ServicesLocations/ServicesTeamScreen";

import NotificacionesBarberView from "../components/pages/Barbers (users)/NotificacionesBarberView";

import UserShedule from "../components/pages/UserShedule/UserShedule";

import Calendar from "../components/pages/Calendar/Calendar";

import WalkIn from "../components/pages/Team/LineUp/WalkIn/WalkIn";
import Online from "../components/pages/Team/LineUp/Online/Online";

import ServicesComboLineUp from "../components/pages/Services/LineUp/ServicesComboLineUp";
import ServicesLineUp from "../components/pages/Services/LineUp/ServicesLineUp";
import TeamServicesScreen from "../components/pages/Barbers (users)/TeamServicesScreen";
import TermsAndConditionsScreen from "../components/pages/TermsAndConditionsScreen";
import PrivacyPoliciesScreen from "../components/pages/PrivacyPoliciesScreen";
import ChangeNumberScreen from "../components/pages/ChangeNumberScreen";
import BlockedUser from "../components/pages/BlockedUser";
import ImportCustomerI from "../components/pages/Customers/ImportCustomerI";
import NewCustomers from "../components/Migrar/screens/Customers/NewCustomers";
import NewCustomersView from "../components/Migrar/screens/Customers/NewCustomersView";
import NewCustomersForm from "../components/Migrar/screens/Customers/NewCustomersForm";
import NotificacionesCustomer from "../components/pages/Customers/CustomerNotification/NotificacionesCustomer";
import NotificacionesCustomerView from "../components/pages/Customers/CustomerNotification/NotificacionesCustomerView";
import NewLocations from "../components/Migrar/screens/Locations/NewLocations";
import NewLocationsForm from "../components/Migrar/screens/Locations/NewLocationsForm";
import NewLocationsView from "../components/Migrar/screens/Locations/NewLocationsView";

const router = createBrowserRouter([
    {
        path: "/authenticate",
        element: <Container />,
        errorElement: <Error404 />,
        children: [
            {
                path: "",
                element: <Login />
            },
            {
                path: "verifyotp",
                element: <VerifyOTP />
            },
            {
                path: "signup",
                element: <SignUp />
            },
            {
                path: "forgot-number",
                element: <ForgotNumber />
            },
            {
                path: "contact",
                element: <Contact />
            },
            {
                path: "blocked",
                element: <BlockedUser />
            },
            {
                path: "import-customer",
                element: <ImportCustomerI />
            }
        ]
    },
    {
        path: "/change-number",
        element: <Container/>,
        errorElement: <Error404/>,
        children:[
            {
                path: ":id",
                element: <ChangeNumberScreen />
            }
        ]
    },
    {
        path: "/terms",
        element: <Container/>,
        errorElement: <Error404/>,
        children:[
            {
                path: "terms-and-conditions",
                element: <TermsAndConditionsScreen/>
            },
            {
                path: "privacy-policies",
                element: <PrivacyPoliciesScreen/>
            }
        ]
    },
    {
        path: "/",
        element: <App />,
        errorElement: <Error404 />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/tables",
                element: <TablesView />
            },
            {
                path: "configuration/profile/:id",
                element: <UserProfileForm />
            },
            {
                path: "inventory/products",
                element: <Products />
            },
            {
                path: "inventory/products/view/:id",
                element: <ProductsView />
            },
            {
                path: "inventory/products/create",
                element: <ProductsForm />
            },
            {
                path: "inventory/products/edit/:id",
                element: <ProductsForm />
            },
            // Companies
            {
                path: "companies",
                element: <Companies />
            },
            {
                path: "companies/view/:id",
                element: <CompaniesView />
            },
            {
                path: "companies/create",
                element: <CompaniesForms />
            },
            {
                path: "companies/edit/:id",
                element: <CompaniesForms />
            },
            // Locations
            {
                path: "companies/locations",
                // element: <Locations />
                element: <NewLocations/>
            },
            {
                path: "companies/locations/create",
                // element: <LocationsForms />
                element: <NewLocationsForm/>
            },
            {
                path: "companies/locations/view/:id",
                element: <NewLocationsView />
                // element: <LocationsView />
            },
            {
                path: "companies/locations/edit/:id",
                element: <NewLocationsForm/>
                // element: <LocationsForms />
            },
            // categories
            {
                path: "inventory/categories",
                element: <Categories />
            },
            {
                path: "inventory/categories/view/:id",
                element: <CategoriesView />
            },
            {
                path: "inventory/categories/create",
                element: <CategoriesForms />
            },
            {
                path: "inventory/categories/edit/:id",
                element: <CategoriesForms />
            },
            // subcategories
            {
                path: "inventory/subcategories",
                element: <Subcategories />
            },
            {
                path: "inventory/subcategories/view/:id",
                element: <SubcategoriesView />
            },
            {
                path: "inventory/subcategories/create",
                element: <SubcategoriesForms />
            },
            {
                path: "inventory/subcategories/edit/:id",
                element: <SubcategoriesForms />
            },
            {
                path: "services/resources",
                element: <Resources />
            },
            {
                path: "services/resources/view/:id",
                element: <ResourcesView />
            },
            {
                path: "services/resources/create",
                element: <ResourcesForm />
            },
            {
                path: "services/resources/edit/:id",
                element: <ResourcesForm />
            },
            //team
            {
                path: "team/usersbarber",
                element: <Barbers />
            },
            {
                path: "team/usersbarber/view/:id",
                element: <BarbersView />
            },
            {
                path: "team/usersbarber/create",
                element: <BarbersForms />
            },
            {
                path: "team/usersbarber/edit/:id",
                element: <BarbersForms />
            },
            {
                path: "/notificaciones/barbers/view/:id",
                element: <NotificacionesBarberView />
            },
            {
                path: "/notificaciones/barbers/:id",
                element: <NotificacionesBarbers />
            },
            {
                path: "/notificaciones/barbers/create",
                element: <NotificacionesBarbers />
            },
            {
                path: "team/barber-level",
                element: <BarberLevels />
            },
            {
                path: "team/barber-level/view/:id",
                element: <BarberLevelsView />
            },
            {
                path: "team/barber-level/create",
                element: <BarberLevelsForm />
            },
            {
                path: "team/barber-level/edit/:id",
                element: <BarberLevelsForm />
            },
            {
                path: "team/usersbarber/permissions/:id",
                element: <BarbersPermissionsForms />
            },
            {
                path: "team/usersbarber/services/:id",
                element: <TeamServicesScreen />
            },
            
            //Products location 2 - PAULA
            {
                path: "configuration/locations/products",
                element: <ProductLocation2 />
            },
            {
                path: "configuration/locations/products/view/:id",
                element: <ProductLocation2View />
            },
            {
                path: "configuration/locations/products/create",
                element: <ProductLocation2Form />
            },
            {
                path: "configuration/locations/products/edit/:id",
                element: <ProductLocation2Form />
            },
            // location products
            // {
            //     path: "configuration/locations/products",
            //     element: <ProductsLocations />
            // },
            // {
            //     path: "configuration/locations/products/view/:id",
            //     element: <ProductsLocationsView />
            // },
            // {
            //     path: "configuration/locations/products/create",
            //     element: <ProductsLocationsForms />
            // },
            // {
            //     path: "configuration/locations/products/edit/:id",
            //     element: <ProductsLocationsForms />
            // },
            //Users Locations
            {
                path: "administration/users/locations",
                element: <UsersLocations />
            },
            {
                path: "administration/users/locations/view/:id",
                element: <UsersLocationsView />
            },
            {
                path: "administration/users/locations/create",
                element: <UsersLocationsForms />
            },
            {
                path: "administration/users/locations/edit/:id",
                element: <UsersLocationsForms />
            },
            //Users
            {
                path: "team/users",
                element: <Users />
            },
            {
                path: "team/users/view/:id",
                element: <UsersView />
            },
            {
                path: "team/users/create",
                element: <UsersForms />
            },
            {
                path: "team/users/edit/:id",
                element: <UsersForms />
            },

            //transactions
            {
                path: "transactions",
                element: <Transactions />
            },
            {
                path: "transactions/view/:id",
                element: <TransactionsView />
            },
            //usersCompanies
            {
                path: "configuration/userscompanies",
                element: <UsersCompanies />
            },
            {
                path: "configuration/userscompanies/view/:id",
                element: <UsersCompaniesView />
            },
            {
                path: "configuration/userscompanies/create",
                element: <UsersCompaniesForm />
            },
            {
                path: "configuration/userscompanies/edit/:id",
                element: <UsersCompaniesForm />
            },
            //taxes
            {
                // path: " ",
                path: "configuration/taxesgroup",
                element: <TaxesGroup />
            },
            {
                path: "configuration/taxesgroup/view/:id",
                element: <TaxesGroupView />
            },
            {
                path: "configuration/taxesgroup/create",
                element: <TaxesGroupForm />
            },
            {
                path: "configuration/taxesgroup/edit/:id",
                element: <TaxesGroupForm />
            },
            //Services
            {
                path: "services",
                element: <Services />
            },
            {
                path: "services/view/:id",
                element: <ServicesView />
            },
            {
                path: "services/create",
                element: <ServicesForm />
            },
            {
                path: "services/edit/:id",
                element: <ServicesForm />
            },
            {
                path: "services/combo",
                element: <ServicesCombo />
            },
            {
                path: "services/combo/create",
                element: <ServicesComboForm />
            },
            //TODO: REVISAR ESTA FALLANDO AL ACCEDER AL FORMULARIO
            {
                path: "services/combo/edit/:id",
                element: <ServicesComboForm />
            },
            {
                path: "services/team/edit/:id",
                element: <ServicesTeamScreen />
            },

            //BRANDS
            {
                path: "inventory/brands",
                element: <Brands />
            },
            {
                path: "inventory/brands/view/:id",
                element: <BrandsView />
            },
            {
                path: "inventory/brands/create",
                element: <BrandsForms />
            },
            {
                path: "inventory/brands/edit/:id",
                element: <BrandsForms />
            },

            //SUPPLIERS
            {
                path: "inventory/suppliers",
                element: <Suppliers />
            },
            {
                path: "inventory/suppliers/view/:id",
                element: <SuppliersView />
            },
            {
                path: "inventory/suppliers/create",
                element: <SuppliersForms />
            },
            {
                path: "inventory/suppliers/edit/:id",
                element: <SuppliersForms />
            },

            //SERVICES CATEGORIES
            {
                path: "services/categories",
                element: <ServicesCategories />
            },
            {
                path: "services/categories/view/:id",
                element: <ServicesCategoriesView />
            },
            {
                path: "services/categories/create",
                element: <ServicesCategoriesForms />
            },
            {
                path: "services/categories/edit/:id",
                element: <ServicesCategoriesForms />
            },

            //CUSTOMERS
            {
                path: "customer",
                element: <NewCustomers/>
                // element: <Customers />
            },
            {
                path: "newcustomer",
                element: <NewCustomers />
            },
            {
                path: "newcustomer/view/:id",
                element: <NewCustomersView />
            },
            {
                path: "newcustomer/create",
                element: <NewCustomersForm />
            },
            {
                path: "customers/view/:id",
                element: <NewCustomersView />
                // element: <CustomersView />
            },
            {
                path: "customer/create",
                element: <CustomersForms />
            },
            {
                path: "customer/edit/:id",
                element: <CustomersForms />
            },
            {
                path: "/notificaciones/customer/view/:id",
                element: <NotificacionesCustomerView />
            },
            {
                path: "/notificaciones/customer/create",
                element: <NotificacionesCustomer />
            },
            {
                path: "/notificaciones/customer/:id",
                element: <NotificacionesCustomer />
            },

            //SERVICES LOCATION
            {
                path: "services/services-locations",
                element: <ServicesLocation />
            },
            {
                path: "services/services-locations/view/:id",
                element: <ServicesLocationView />
            },
            {
                path: "services/services-locations/create",
                element: <ServicesLocationForms />
            },
            {
                path: "services/services-locations/edit/:id",
                element: <ServicesLocationForms />
            },

            //STORAGES
            {
                path: "inventory/storages",
                element: <Storages />
            },
            {
                path: "inventory/storages/view/:id",
                element: <StoragesView />
            },
            {
                path: "inventory/storages/create",
                element: <StoragesForms />
            },
            {
                path: "inventory/storages/edit/:id",
                element: <StoragesForms />
            },
            // Policies
            {
                path: "configuration/policies",
                element: <Policies />
            },

            //SUBSCRIPTIONS
            {
                path: "configuration/subscriptions",
                element: <Subscriptions />
            },
            {
                path: "configuration/subscriptions/view/:id",
                element: <SubscriptionsView />
            },
            {
                path: "configuration/subscriptions/create",
                element: <SubscriptionsForms />
            },
            {
                path: "configuration/subscriptions/edit/:id",
                element: <SubscriptionsForms />
            },



            //SERVICES BARBER
            {
                path: "services/services-barber",
                element: <ServicesBarbers />
            },
            {
                path: "services/services-barber/view/:id",
                element: <ServicesBarbersView />
            },
            {
                path: "services/services-barber/create",
                element: <ServicesBarbersForms />
            },
            {
                path: "services/services-barber/edit/:id",
                element: <ServicesBarbersForms />
            },

            //NOTES
            {
                path: "customer/notes",
                element: <Notes />
            },
            {
                path: "customer/notes/view/:id",
                element: <NotesView />
            },
            {
                path: "customer/notes/create",
                element: <NotesForms />
            },
            {
                path: "customer/notes/edit/:id",
                element: <NotesForms />
            },
             //CUSTOMER REPUTATION
             {
                path: "customer/reputation",
                element: <CustomerReputation />
            },
            {
                path: "customer/reputation/view/:id",
                element: <CustomerReputationView />
            },
            {
                path: "customer/reputation/create",
                element: <CustomerReputationForms />
            },
            {
                path: "customer/reputation/edit/:id",
                element: <CustomerReputationForms />
            },

            //USER SHEDULE
            {
                path: "team/users/shedule/:id",
                element: <UserShedule />
            },

            //CALENDAR
            {
                path: "calendar",
                element: <Calendar />
            },

            //TEAM LINE UP
            {
                path: "team/users/walkin",
                element: <WalkIn />
            },
            {
                path: "team/users/online",
                element: <Online />
            },

            //SERVICES LINE UP
            {
                path: "services/lineup/combo",
                element: <ServicesComboLineUp />
            },
            {
                path: "services/lineup",
                element: <ServicesLineUp />
            },

            
            
            


        ]
    },
])

export default router;
