
import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";

const SuppliersForms = () => {
    const endpoint = "inventory/suppliers";
    const parent = "Services";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('supplier.CreateSupplier');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();

    // parametros
    const [supplierTypeDni, setSupplierTypeDni] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    // values
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");

    // formulario
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        company_id: "",
        supplier_name: "",
        supplier_type_identification: "",
        supplier_identification_number: "",
        supplier_phone: "",
        supplier_ext: "",
        supplier_website: "",
        supplier_address: "",
        supplier_delivery_time: "",
        country_code: "",
        state_code: "",
        city_code: "",
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setCountries(handlerApp.getResultFromObject(response.data, "countries"))
                    setSupplierTypeDni(handlerApp.getResultFromObject(response.data, "supplier_type_identification"))
                    if (id !== undefined) {
                        setTitle('storageComponent.updateStorage')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }, [])

    const handleDependencies = (data) => {
        setCountry(data.country_code);
        setState(data.state_code);
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        console.log(data);
        handlerApp.setLoader(true);
        api.post(endpoint, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
    // dependencias
    // countries
    const handleSelectCountry = (val) => {
        if (val != null) {
            setCountry(val);
            setStates([]);
            setCities([]);
        }
    }
    // states
    const handleSelectState = (val) => {
        setState(val);
        setCities([]);
    }
    // cities
    const handleSelectCity = (val) => {
        setCity(val);
    }

    useEffect(() => {
        if (country != null) {
            getState();
        }
    }, [country])

    useEffect(() => {
        if (state != null) {
            getCities()
        }
    }, [state])

    const getState = () => {
        if (country !== undefined && country !== "") {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getStates",
                country: country
            })
                .then(response => {
                    if (response.success) {
                        setStates(response.data);
                        handlerApp.setLoader(false);
                    } else {
                        handlerApp.setLoader(false);
                        handlerApp.handlerResponse(response);
                    }
                })
                .catch(error => {
                    handlerApp.showError(t(error.message));
                    handlerApp.setLoader(true);
                })
        }
    }

    const getCities = () => {
        if (state !== undefined && state !== "") {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getCities",
                country: country,
                state: state
            })
                .then(response => {
                    if (response.success) {
                        setCities(response.data);
                        handlerApp.setLoader(false);
                    } else {
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                })
                .catch(error => {
                    handlerApp.showError(t(error.message));
                    handlerApp.setLoader(false);
                })
        }
    }

    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <TextField label={t("name")} variant="outlined" size="small"
                                {...register("supplier_name", { required: true, maxLength: 40 })}
                                value={formData.supplier_name}
                                onChange={handleForm}
                                error={errors.supplier_name != null}
                                helperText={
                                    errors.supplier_name?.type === 'required' ? t("errors.required") :
                                        errors.supplier_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('identificationType')}
                                value={formData.supplier_type_identification}
                                name="supplier_type_identification"
                                onChange={handleForm}
                                options={supplierTypeDni}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("identification")} variant="outlined" size="small"
                                {...register("supplier_identification_number", { required: true, maxLength: 40 })}
                                value={formData.supplier_identification_number}
                                onChange={handleForm}
                                error={errors.supplier_identification_number != null}
                                helperText={
                                    errors.supplier_identification_number?.type === 'required' ? t("errors.required") :
                                        errors.supplier_identification_number?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("phone")} variant="outlined" size="small"
                                {...register("supplier_phone", { required: true, maxLength: 10 })}
                                value={formData.supplier_phone}
                                onChange={handleForm}
                                error={errors.supplier_phone != null}
                                helperText={
                                    errors.supplier_phone?.type === 'required' ? t("errors.required") :
                                        errors.supplier_phone?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="supplier_ext" onChange={handleForm} value={formData.supplier_ext} label={t('ext')} variant="outlined" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="supplier_website" onChange={handleForm} value={formData.supplier_website} label={t('website')} variant="outlined" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('country')}
                                value={formData.country_code}
                                name="country_code"
                                onChange={handleForm}
                                dependency={handleSelectCountry}
                                options={countries}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('state')}
                                value={formData.state_code}
                                name="state_code"
                                onChange={handleForm}
                                dependency={handleSelectState}
                                options={states}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('city')}
                                value={formData.city_code}
                                name="city_code"
                                onChange={handleForm}
                                dependency={handleSelectCity}
                                options={cities}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("address")} variant="outlined" size="small"
                                {...register("supplier_address", { required: true, maxLength: 40 })}
                                value={formData.supplier_address}
                                onChange={handleForm}
                                error={errors.supplier_address != null}
                                helperText={
                                    errors.supplier_address?.type === 'required' ? t("errors.required") :
                                        errors.supplier_address?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="supplier_delivery_time" label={t('supplier.delivery')} value={formData.supplier_delivery_time} variant="outlined" size="small" onChange={handleForm} />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SuppliersForms;