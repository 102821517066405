
import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import {Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelect from "../../../utils/BoldSelect";

const Subcategories = () => {
    const endpoint = "inventory/subcategories";
    const parent = "Inventory";
    const [t,i18n] = useTranslation("global");
    const title = t('subcategoryComponent.subcategories');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);

    // filters
    const [filters,setFilters] = useState([]);

    // Permisos
    const [permissions,setPermissions] = useState([]);
    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    // data.
    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                // response.data.forEach(element => {
                //     element.category_id
                // });
                // setFilters({...filters,categories: response.data.fil})
                console.log(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            console.log(error);
            handlerApp.setLoader(false);
        })
    }

    const deleteRow= (id)=>{
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    

    const columns = [
        {
            id: 'subcategory_id',
            name: 'Id',
            selector: row => row.subcategory_id,
            sortable: true,
            width: "60px"
        },
        {
            id: 'subcategory_image',
            name: t('image'),
            cell: (row)=>{return row.subcategory_image != null &&  <img className="bold-image-table" src={row.subcategory_image} alt={row.subcategory_image}/>},
            sortable: false
        },
        {
            id: 'subcategory_name',
            name: t('subcategoryComponent.subcategory'),
            selector: row => row.subcategory_name,
            sortable: true
        },
        {
            id: 'category_name',
            name: t('categoryComponent.category'),
            selector: row => row.category_name,
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions(row,permissions,'subcategory',handlerAction)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];


    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.category_name && item.category_name.toLowerCase().includes(filterText.toLowerCase())
            || item.subcategory_name.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">En esta sección podrás tener todo el control de tus productos.</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions()}
                </div>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>Lista de </h4>
                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title="Location" name="location" onChange={ e => setFilterText(e.target.value)} value="" options=""></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title="Location" name="location" onChange={ e => setFilterText(e.target.value)} value="" options=""></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>

                    
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
            />
        </div>
        // <div className="app container">
        //     <div className="container bold-container-content">
        //         <div className="container-table">
        //             <div className="bold-header-table">
        //                 <h3>{title}</h3>
        //                 <div className="section-events">
        //                     <div className="input-icon input-search">
        //                         <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
        //                         {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
        //                         <span className="material-symbols-outlined">search</span>
        //                     </div>
        //                     { permissions?.indexOf("create") !== -1 && (
        //                         <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
        //                             <span className="material-symbols-outlined">add</span>
        //                         </button>
        //                     ) }
        //                     <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
        //                         <span className="material-symbols-outlined">autorenew</span>
        //                     </button>
        //                 </div>
        //             </div>
        //             <DataTable
        //                 columns={columns}
        //                 data={filteredItems}
        //                 pagination
        //                 paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        //                 subHeader
        //                 selectableRows
        //                 persistTableHead
        //             />
        //         </div>
        //     </div>
        // </div>
    )
}

export default Subcategories;