import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";


const StoragesView = ()=>{
    const endpoint = "inventory/storages";
    const [t] = useTranslation("global");
    const prefix = "storage";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.storage_name);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('storageComponent.storage')}:</dt>
                        <dd>{data.storage_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('storageComponent.storageType')}:</dt>
                        <dd>{data.storage_type}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('storageComponent.location')}:</dt>
                        <dd>{data.location_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('storageComponent.storageDeliveryTime')}:</dt>
                        <dd>{data.storage_delivery_time}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('storageComponent.barber')}:</dt>
                        <dd>{data.barber_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('country')}:</dt>
                        <dd>{data.country_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('state')}:</dt>
                        <dd>{data.state_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('city')}:</dt>
                        <dd>{data.city_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('address')}:</dt>
                        <dd>{data.storage_address}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('zipCode')}:</dt>
                        <dd>{data.storage_zipcode}</dd>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data["created_by"]}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data[prefix+"_created_at"])}</span>
                    </div>
                    <div className="col-md-6">
                        {data["modified_by"] !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data["modified_by"]}</span>}
                    </div>
                    <div className="col-md-6">
                        {data[prefix+"_modified_at"] !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data[prefix+"_modified_at"])}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoragesView;