import { useEffect, useState, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../../../context/LocationContext";

import DragAndDrop from "../../../../utils/DragAndDrop";
import HandlerApp from "../../../../utils/handlerApp";



const ServicesLineUp = () => {
    const endpoint = "services/lineup";
    const [t, i18n] = useTranslation("global");

    const api = useFetch();
    const handlerApp = HandlerApp();
    const [title, setTitle] = useState(t('servicesComponent.services'));
    const nav = useNavigate();

    const [data, setData] = useState([]);

    const { location } = useContext(LocationContext);


    useEffect(() => {
        if (location === "") {
            if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                handlerApp.showAlertLocation();
                setData([]);
            }
        } else {
            sendRequest();
        }
    }, [location])

    const handleCancel = (e) => {
        e.preventDefault();
        nav("/services");
    }

    // TODO: AJUSTAR CUANDO DANIEL TERMINE DE CUSTOMIZAR EL NAV GENERICO

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + '/' + location)
            .then(response => {
                if (response.success) {
                    let d = response.data;
                    setData(d);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = () => {
        handlerApp.setLoader(true);
        api.put(endpoint + '/' + location, data,)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/services/lineup");
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    return (

        <div className="app container">

            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>{t("cancel")}</p>
                </div>
            </div>

            <div className="menu-contenedor ms-2">
                <nav>
                    <ul className="main-nav">
                        <li className="main-item ">
                            <NavLink exact to="#">{t("servicesBarbers.category")}</NavLink>
                        </li>
                        <li className="main-item active">
                            <NavLink exact to="/services/lineup">{t("servicesComponent.services")}</NavLink>
                        </li>
                        <li className="main-item">
                            <NavLink exact to="/services/lineup/combo">{t("servicesComponent.combo")}</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>


            <div>
                <DragAndDrop
                    data={data}
                    setData={setData}
                    keyString="servicelocation_id"
                    name="service_name"
                    description="service_description"
                    image="service_image"
                    keyOrder="servicelocation_order"
                />

                
                <div className="container btn-footer-right">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary-yellow-1" onClick={handleUpdateForm}>
                            {t("save")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesLineUp;