import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";
import { Checkbox, TextField } from '@mui/material';
import "../Customers/Navbar.css"
import NavbarBarbers from "./NavbarBarbers";
import env from "../../../env-local";
import NavbarEditBarbers from "./NavbarEditBarbers";


const NotificacionesBarbers = () => {
    const endpoint = "/team/usersnotifications";
    const [t, i18n] = useTranslation("global");
    const prefix = "category";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState('notifications.notifications');
    const nav = useNavigate();
    const [filters, setFilters] = useState({
        type: []
    });

    const [formData, setFormData] = useState({
        usernotification_rescheduling_email: false,
        usernotification_rescheduling_sms: false,
        usernotification_rescheduling_push : false,
        usernotification_cancel_email: false,
        usernotification_cancel_sms: false,
        usernotification_cancel_push : false,
        usernotification_barber_reminder_email: false,
        usernotification_barber_reminder_sms: false,
        usernotification_barber_reminder_push : false,
        usernotification_barber_reminder: 24,
        usernotification_first_customer_reminder_email: false,
        usernotification_first_customer_reminder_sms: false,
        usernotification_first_customer_reminder_push : false,
        usernotification_first_customer_reminder: 24,
        usernotification_second_customer_reminder_sms : false,
        usernotification_second_customer_reminder_email : false,
        usernotification_second_customer_reminder_push : false,
        usernotification_second_customer_reminder: 24,
        usernotification_arrival_sms : false,
        usernotification_arrival_email : false,
        usernotification_arrival_push : false, 
        usernotification_missed_appoiment_sms : false,
        usernotification_missed_appoiment_email : false,
        usernotification_missed_appoiment_push : false,

        usernotification_stock_sms : false,
        usernotification_stock_email : false,
        usernotification_stock_push : false,

        usernotification_walkin_sms : false,
        usernotification_walkin_email : false,
        usernotification_walkin_push : false
    });

    const { id, user_id } = useParams();
    useEffect(() => {
        sendRequest();
        if(id !== undefined){
            setTitle(t('notifications.notifications'));
        }
    }, [])

    const back = () => {
        nav(-1);
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                console.log(response)
                if (response.success) {
                    let data = response.data[0];
                    // handleDependencies(data);
                    if (data) {
                        handleDependencies(data);
                        setTitle(data.user_name);
                    }
                    handlerApp.setLoader(false);
                    
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    const handleChange = (id) => {
        // setFormData( {
        //     ...formData,
        //     [id]: !formData[id],
        // });
        // console.log(id)
    };

    const handleUpdate = () => {

        console.log(formData)
        handlerApp.setLoader(true);
        api.put( endpoint + "/" + id,  formData  )
        .then(response => {
            console.log(response)
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                window.location.href = `/notificaciones/barbers/${id}`;
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            console.log(error)
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCreate = () => {
    handlerApp.setLoader(true);
        api.post( endpoint,  formData  )
        .then(response => {
            console.log(response)
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                window.location.href = `/notificaciones/barbers/${id}`;
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            console.log(error)
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (

        <div className="app container">
            <div className="d-flex justify-content-between title-barber">
                <section className="section-title-page">
                    <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">{t(title)}</h3>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container bold-container-content">
                <NavbarEditBarbers />
                <div className="row np-row">
                    {/* Columna izquierda */}
                    <div className="col-md-6 mb-2 prueba">
                        <div className="sub-container mb-4">
                            <h4>{t('notifications.rescheduling')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_rescheduling_email }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_rescheduling_email: e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_rescheduling_sms }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_rescheduling_sms: e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_rescheduling_push }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_rescheduling_push: e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.cancellations')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_cancel_email }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_cancel_email: e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_cancel_sms }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_cancel_sms: e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_cancel_push }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_cancel_push: e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                        <h4>{t('notifications.appointmentAvailabilitySuggestions')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={""}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                               "" : e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={""}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                               "" : e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={""}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                               "" : e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.reminderForBarber')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_barber_reminder_email}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_barber_reminder_email : e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_barber_reminder_sms}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_barber_reminder_sms : e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_barber_reminder_push}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_barber_reminder_push : e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_barber_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_barber_reminder} />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.firstClientReminder')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_first_customer_reminder_email}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_first_customer_reminder_email : e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_first_customer_reminder_sms}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_first_customer_reminder_sms : e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_first_customer_reminder_push}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_first_customer_reminder_push : e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                size="small" 
                                type="number" 
                                value={formData.usernotification_first_customer_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" 
                                type="number"
                                value={formData.usernotification_first_customer_reminder} />
                            </div>
                        </div>
                    </div>

                    {/* Columna derecha */}
                    <div className="col-md-6 mb-2 prueba-2">
                        <div className="sub-container mb-4">
                            <h4>{t('notifications.secondClientReminder')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_second_customer_reminder_email}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_second_customer_reminder_email : e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_second_customer_reminder_sms}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_second_customer_reminder_sms : e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_second_customer_reminder_push}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_second_customer_reminder_push : e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_second_customer_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" 
                                type="number" 
                                value={formData.usernotification_second_customer_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_second_customer_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" 
                                type="number"
                                value={formData.usernotification_second_customer_reminder} />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.stockProducts')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_stock_email }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_stock_email: e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_stock_sms }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_stock_sms: e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_stock_push }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_stock_push: e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.missedAppointment')}</h4>
                            <div className="checkbox-group">
                            <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_missed_appoiment_email}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_missed_appoiment_email : e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_missed_appoiment_sms}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_missed_appoiment_sms : e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_missed_appoiment_push}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_missed_appoiment_push : e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.walkIns')}</h4>
                            <div className="checkbox-group">
                            <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_walkin_email}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_walkin_email : e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_walkin_sms}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_walkin_sms : e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.usernotification_walkin_push}
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                usernotification_walkin_push : e.target.checked
                                            })}}
                                    />{t('Push')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-end">
                <div className="d-flex justify-content-end me-5">
                    {id === undefined && <button className="btn btn-primary-yellow-1" onClick={handleCreate} type="submit">{t('create')}</button>}
                    {id && <button className="btn btn-primary-yellow-1" onClick={handleUpdate} type="submit">{t('update')}</button>}
                </div>
            </div>
        </div>
    );
}

export default NotificacionesBarbers;