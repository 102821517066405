import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import notFound from '../../../assets/img/image-not-found.webp';
import { useTranslation } from "react-i18next";
import SwitchObject from "../../atoms/SwitchObject";

const ServicesView = ()=>{
    const endpoint = "services";
    const [t,i18n] = useTranslation("global");
    const prefix = "service";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    const [data,setData] = useState({});

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.service_name);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">{title}</h3>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {t("actions")}
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container bold-container-content">
                <div className="title-section-forms">
                </div>
                <div className="row np-row">
                    <div className="col-md-12">
                        <div className="row">
                        <div className="col-md-12 mb-2">
                                <div className="viewsection-circle-image">
                                    {
                                    (data.service_image !== null && data.service_image !== "") ? 
                                        <img src={data.service_image} />
                                    :
                                        <img src={notFound} alt="Not found"/>
                                    }
                                </div>
                            </div>
                            <div className="col-md-5 col-11 mb-2">
                                <TextField className="text-view" label={t("name")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.service_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-1 col-1 mb-2">
                               <button className="service-color" style={(data.service_color !==null && data.service_color !== "") ? {backgroundColor: data.service_color} : {backgroundColor: "#F0B669"}} type="button" disabled></button>
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesComponent.price')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.service_price}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesComponent.duration')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.service_duration}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesComponent.preparationTime')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.service_preparation_time}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesComponent.processingTime')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.service_processing_time}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('categoryComponent.category')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.servicecategory_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('taxesComponent.title')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.taxgroup_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                                <SwitchObject label={t("servicesComponent.acceptGiftCards")} name="service_accepts_gift_cards" value={data.service_accepts_gift_cards}/>

                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesComponent.description')} variant="outlined" multiline rows={4}
                                    disabled={true}
                                    value={data.service_description}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                            </div>  
                        </div>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default ServicesView;