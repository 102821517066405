import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import logoDark from "../../assets/img/logo_black.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../atoms/Buttons/ButtonPrimary";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ButtonLink from "../atoms/Buttons/ButtonLink";
import BoldInputPhone from "../atoms/Inputs/BoldInputPhone";

const Contact = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();
    const [sendEmail, setSendEmail] = useState(false);
    
    const { register, handleSubmit, formState: { errors, isValid }, setValue } = useForm();

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;
    
    // translate
    const [language,setLanguage] = useState("");
    const [t,i18n] = useTranslation("global");
    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])
    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])

    const nav = useNavigate();

    const handleFormContact = (data) => {
        let body = {
            ...data,
            email: data.email.toLowerCase()
        }
        setLoader(true);
        axios.post(env.urlBackend + '/contact/support', body)
        .then(response => {
            if(response.data.success){
                setSendEmail(true);
                setLoader(false);
            }else{
                setError(t(error.response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
    }

    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            { !sendEmail && (
                <div className="d-flex justify-content-center bg-login">
                    <div className="login-container">
                        <div className="section-login login">
                            <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                            <h1 className="title-login">{t("loginComponent.contactUs")}</h1>
                            <p className="light">{t("loginComponent.subtitleContactUs")}</p>

                            <form action="/" onSubmit={handleSubmit(handleFormContact)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextField label={t('fullname')} variant="outlined" size="small"
                                        {...register("fullname",{ required: true, maxLength: 80 })}
                                        error={errors.fullname != null}
                                        helperText={
                                            errors.fullname?.type === 'required' ? t("errors.required") : 
                                            errors.fullname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                        />  
                                    </div>
                                    <div className="col-md-12">
                                        <TextField label={t('companyComponent.company')} variant="outlined" size="small" 
                                            {...register("company",{ 
                                                required: true
                                            })}
                                            error={errors.company != null}
                                            helperText={
                                                errors.company?.type === 'required' ? t("errors.required"): ""
                                            }
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <TextField label={t('email')} type="email" variant="outlined" size="small" {...register("email",{ 
                                                required: true, 
                                                maxLength: 80, 
                                                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/ 
                                            })}
                                            error={errors.email != null}
                                            helperText={
                                                errors.email?.type === 'required' ? t("errors.required"): 
                                                errors.email?.type === 'pattern' ? t("errors.pattern") : 
                                                errors.email?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <BoldInputPhone
                                            name={"phone"} 
                                            label={t('phone')} 
                                            register={register} 
                                            errors={errors.phone}
                                            onChange={handlePhone}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <TextField label={t('loginComponent.textContact')} multiline rows={4} variant="outlined" size="small" 
                                        {...register("description",{ required: true, minLength: 10, maxLength: 500 })}
                                        error={errors.description != null}
                                        helperText={
                                            errors.description?.type === 'required' ? t("errors.required"): 
                                            errors.description?.type === 'minLength' ? t("errors.minLength"): 
                                            errors.description?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                        />
                                    </div>
                                </div>
                                {error &&
                                    <Alert className="mb-3" severity="error">{error}</Alert>
                                }
                                <center>
                                    <ButtonPrimary label={t('send')} type="submit" />
                                    <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>} onClick={()=> nav("/authenticate")} type="button"/>
                                </center>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            { sendEmail && (
                <div className="d-flex justify-content-center bg-login">
                    <div className="login-container">
                        <div className="section-login login">
                            <img src={logo} alt="logo-brava" className="logo-login"/>
                            <h1 className="title-login">{t("loginComponent.contactUs")}</h1>
                            <p className="light">{t("loginComponent.subtitleContactUsSent")}</p>
                            <center>
                                <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('goToHome')}</>} onClick={()=> nav("/authenticate")} type="button"/><br/><br/>
                            </center>
                        </div>
                    </div>
                </div>
            )}
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default Contact;