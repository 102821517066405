import { useEffect, useState } from "react";
import BoldSelectValidated from "../../utils/BoldSelectValidated";
import BoldSelect from "../../utils/BoldSelect";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem, Select, FormHelperText, InputLabel } from "@mui/material";
// import { useForm } from "react-hook-form";

const InfiniteItemSelectors = ({options, buttonText, itemTitle, name, value, register, errors, onChange, setValue, unregister}) =>{
    const [t,i18n] = useTranslation("global");
    const [inputs, setInputs] = useState(['','']);

    useEffect(()=>{
        if (value !== null && value !== undefined && value.length > 0){
            setInputs(value);
            inputs.forEach((item,index)=>{
                unregister(name+'_'+index);
                setValue(name+'_'+index, item);
            })
        }
    }, [setInputs, value])

    const handleAddInput = () => {
        setInputs([...inputs, '']); // Agregamos un nuevo input vacío al array
    };
    const handleDeleteInput = (index) =>{
        const newInputs = [...inputs];
        newInputs.splice(index, 1); // Eliminamos el input en la posición específica
        setInputs(newInputs);
        onChange(newInputs);
    }

    const handleChange = (e, index)=>{ //modifica los ids segun el elemento seleccionado
        let itemsSelected = [...inputs];
        itemsSelected[index] = e.target.value;
        setInputs(itemsSelected);
        onChange(itemsSelected);
    }

    return(
        <>
            {inputs.map((input, index) => (
                <div className="multipleinputs-container" key={index}>
                    <FormControl className="w-100" variant="outlined" size="small">
                        <InputLabel id={`${itemTitle + "-" +(index+1)}-label`}>{itemTitle + " " + (index+1)}</InputLabel>
                        <Select labelId={`${itemTitle + "-" +(index+1)}-label`} className="bold-select"
                            {...register( name+"_"+index,{ required: true})}
                            value={input}
                            onChange={(e)=>{
                                handleChange(e, index);
                            }} 
                            label={itemTitle + " " +(index+1)} 
                            error={errors?.[name+"_"+index] != null}
                            id={`${itemTitle + "-" + (index)}-select`}
                        >
                            <MenuItem value="">
                                <em>...</em>
                            </MenuItem>
                            {
                                options?.length > 0 &&
                                options.map(item=>(<MenuItem value={item.code} key={item.code}>{item.name}</MenuItem>))
                            }
                        </Select>
                        {errors?.[name+"_"+index]!== null && <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">{
                            errors[name+"_"+index]?.type === 'required' ? t("errors.required") : ""
                            }</FormHelperText>}
                    </FormControl>

                    {(index > 1) &&                
                        <button className="team_options-close-button" onClick={(e)=>{e.preventDefault(); handleDeleteInput(index)}}> <span className="material-symbols-outlined">close</span> </button> 
                    }
                </div>
            ))
                    
            }
            <button className="btn btn-primary-yellow-1 button-icon" onClick={(e)=>{e.preventDefault(); handleAddInput()}}>
                 <span className="material-symbols-outlined">add</span>
                 <span>{buttonText}</span>            
            </button>
        </>
    )
}
export default InfiniteItemSelectors;