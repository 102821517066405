

const ButtonPrimary = ({label, onClick, type="submit", disabled=false})=>{
    return(
        <button 
            className={ (disabled) ? "buttonPrimary disabled" : "buttonPrimary" }
            type={type === "submit" ? "submit" : "button"} 
            onClick={(onClick !== undefined) ? onClick : null}
            disabled={disabled}
        >
            {label}
        </button>
    )
}

export default ButtonPrimary;