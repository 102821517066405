import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import HandlerApp from "../../../utils/handlerApp";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import BoldSelectWithImageValidated from "../../../utils/BoldSelectWithImageValidated";
import moment from "moment/moment";
import Card from '@mui/material/Card';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import iconAdd from "../../../assets/img/icons/actions/add.svg";
import iconChevronRight from "../../../assets/img/icons/actions/chevron-right.svg";
import iconChevronLeft from "../../../assets/img/icons/actions/chevron-left.svg";
import notFound from "../../../assets/img/image-not-found.webp";

import { CalendarForm } from "./CalendarForm";
import { CalendarView } from "./CalendarView";



const Calendar = () => {
    const endpoint = "team/usersbarber";
    const parent = "Services";
    const [t] = useTranslation("global");
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);

    const [optionsVisibleForm, setOptionsVisibleForm] = useState(false);
    const [optionsVisibleView, setOptionsVisibleView] = useState(false);
    const [id, setId] = useState(null);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    // parametros
    const [barbers, setBarbers] = useState([]);
    const [date, setDate] = useState(moment(new Date));
    const [selectedDate, setSelectedDate] = useState(moment()); // Agrega el estado para la fecha seleccionada

    const [photo, setPhoto] = useState({ photo: notFound, name: '' });

    const options = [
        {
            "code": 1,
            "name": t("day")
        },
        {
            "code": 3,
            "name": t("threeDay")
        },
    ]
    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);

    const [columnas, setColumnas] = useState(1);
    const [schedule, setSchedule] = useState([]);
    const [scheduleBarber, setScheduleBarber] = useState([]);
    const [barberId, setBarberId] = useState([]);
    const [diaSemana, setDiasSemana] = useState([]);


    const [services, setServices] = useState([
        { id: 1, name: 'Victor Duran17', type: 'NEW', procesing: "30", date: '2024/02/16', hora: "08:00", pricing: 30, service: "Haircut",booking_datetime : "2024-02-17" },
        { id: 1, name: 'Victor Duran18', type: 'NEW', procesing: "30", date: '2024/02/16', hora: "08:00", pricing: 30, service: "Haircut",booking_datetime : "2024-02-18" },
        { id: 1, name: 'Victor Duran', type: 'NEW', procesing: "30", date: '2024/02/16', hora: "14:00", pricing: 30, service: "Haircut",booking_datetime : "2024-02-17" },
        { id: 1, name: 'Victor Duran', type: 'NEW', procesing: "30", date: '2024/02/16', hora: "15:00", pricing: 30, service: "Haircut",booking_datetime : "2024-02-18" },
        { id: 1, name: 'Victor Duran', type: 'NEW', procesing: "30", date: '2024/02/16', hora: "16:00", pricing: 30, service: "Haircut",booking_datetime : "2024-02-19" },
        { id: 1, name: 'Victor Duran19', type: 'NEW', procesing: "30", date: '2024/02/16', hora: "08:00", pricing: 30, service: "Haircut",booking_datetime : "2024-02-19" },
        // Agrega más servicios según sea necesario
    ]);

    const [servicess, setServicess] = useState([
        { 
            booking_id: 1, 
            customer_id: 25, 
            user_id: 82, 
            location_id: 5,
            booking_datetime: '2024-02-17', 
            booking_observations: "Esto es una observacion", 
            booking_total: 30, 
            booking_total_taxes: 35,
            booking_details:{
                bookingdetail_id :1,
                booking_id : 1,
                service_id : 2,
                product_id : 1,
                user_id : 82,
                customer_id : 25,
                bookingdetail_start_hour : "13:00",
                bookingdetail_end_hour : "13:30",
                bookingdetail_price : 20,
                bookingdetail_quantity :1,
                bookingdetail_created_by : 1,
                bookingdetail_created_at : "2023-06-28 16:47:15.71982+00",
                bookingdetail_modified_by : 1,
                bookingdetail_modified_at : "2023-06-28 16:47:15.71982+00",
            }
        },
        { 
            booking_id: 1, 
            customer_id: 25, 
            user_id: 82, 
            location_id: 5,
            booking_datetime: '2024-02-12 03:20:13.145943+00', 
            booking_observations: "Esto es una observacion", 
            booking_total: 30, 
            booking_total_taxes: 35,
            booking_details:{
                bookingdetail_id :1,
                booking_id : 1,
                service_id : 2,
                product_id : 1,
                user_id : 82,
                customer_id : 25,
                bookingdetail_start_hour : "13:00",
                bookingdetail_end_hour : "13:30",
                bookingdetail_price : 20,
                bookingdetail_quantity :1,
                bookingdetail_created_by : 1,
                bookingdetail_created_at : "2023-06-28 16:47:15.71982+00",
                bookingdetail_modified_by : 1,
                bookingdetail_modified_at : "2023-06-28 16:47:15.71982+00",
            }
        },
        // Agrega más servicios según sea necesario
    ]);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])


    // Actualiza el arreglo de días cuando cambia la fecha o el número de columnas
    useEffect(() => {
        const startDate = date.clone(); // Clona la fecha seleccionada
        const newDaysArray = generateDaysArray(startDate, columnas);
        setDiasSemana(newDaysArray);
    }, [date, columnas]);

    useEffect(() => {
        getParamsUpdate();
    }, [])

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setBarbers(handlerApp.getResultFromObject(response.data, "barbers_with_image"));
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }

    const getParamsSchedule = (id) => {
        handlerApp.setLoader(true);
        api.get('team/users/shedule/' + id,)
            .then(response => {

                if (response.success) {
                    let data = response.data;
                    setScheduleBarber(data);
                    handlerApp.setLoader(false);
                    generateScheduleFromData(data);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            default:
                break;
        }
    }

    // Función para generar el arreglo de días dinámicamente
    const generateDaysArray = (startDate, columnCount) => {
        console.log("startDate");
        console.log(startDate.format('YYYY-MM-DD'));
        const daysOfWeek = [];
        for (let i = 0; i < columnCount; i++) {

            const dayName = startDate.format('dddd'); // Obtén el nombre del día en formato completo (por ejemplo, 'Lunes')
            const dayNumber = handlerApp.DayOfWeekNumber(dayName);
            const date = startDate.format('YYYY-MM-DD');

            daysOfWeek.push({ number: dayNumber, name: dayName, date: date });
            startDate.add(1, 'days'); // Avanza al siguiente día
        }
        return daysOfWeek;
    };

    const generateScheduleFromData = (scheduleBarber) => {

        const schedule = [];

        scheduleBarber.forEach((entry) => {

            const { schedule_day, schedule_start, schedule_end } = entry;

            for (let hour = schedule_start; hour <= schedule_end; hour++) {
                // Ciclos de media hora
                for (let minute = 0; minute < 60; minute += 15) {
                    const timeString = `${hour < 10 ? "0" : ""}${hour}:${minute === 0 ? "00" : minute}`;
                    schedule.push({ day: schedule_day, time: timeString });
                }
            }
        });

        return setSchedule(schedule);
    };

    const scheduleFind = (schedules, scheduleDay) => {
        let scheduleDefinitive = []

        diaSemana.map((week, index) => {
            let weekSchedule = []
            schedules.map((schedule, row) => {
                if (schedule.day == week.number) {
                    weekSchedule.push({ day: schedule.day, time: schedule.time });
                }
            })
            scheduleDefinitive.push({ day: week.name, date: week.date, schedule: weekSchedule });
        })

        let scheduleAny = [{ 'scheduleDay': scheduleDay, schedule: scheduleDefinitive }]

        return scheduleAny;
    }


    const isHourOnTheHour = (time) => {
        // Obtener los últimos dos caracteres de la cadena de tiempo
        const lastTwoCharacters = time.slice(-2);

        // Verificar si los últimos dos caracteres son "00"
        return lastTwoCharacters === "00";
    }

    const horario = (userSchedule) => {
        // Encontrar la hora de inicio mínima y la hora de finalización máxima
        const minStart = Math.min(...userSchedule.map(entry => entry.schedule_start));
        const maxEnd = Math.max(...userSchedule.map(entry => entry.schedule_end));

        // Crear un arreglo con todas las horas entre la hora de inicio mínima y la hora de finalización máxima
        const hoursArray = Array.from({ length: maxEnd - minStart + 1 }, (_, index) => {
            const currentHour = minStart + index;
            return currentHour < 10 ? `0${currentHour}:00` : `${currentHour}:00`;
        });

        return hoursArray
    }


    const ListaColumnas = ({ columnas, horarios }) => {

        let scheduleDay = horario(scheduleBarber);
       
        let scheduleUser = scheduleFind(horarios, scheduleDay);
        console.log(scheduleUser);
        // Utilizamos CSS Grid para organizar los elementos en filas y columnas
        const estiloContenedor = {
            display: 'grid',
            gridTemplateColumns: `repeat(${columnas}, 2fr)`,
            gap: '16px', // Espacio entre elementos
            padding: "16px",
            width: "100%",
            overflowX: "auto",
        };

        


        return (
            <div className="row">
                {scheduleUser?.map((item, index) => (
                    <div key={index}>
                        {item.scheduleDay?.map((scheduleDayItem, scheduleDayIndex) => (
                            <div key={scheduleDayIndex}>
                                {isHourOnTheHour(scheduleDayItem) &&
                                    <p style={{ fontWeight: "bold", position: "absolute", left: -25, marginTop: 10 }}>
                                        {scheduleDayItem}
                                    </p>}
                                <div key={scheduleDayIndex} style={estiloContenedor}>
                                    {item.schedule?.map((scheduleDayli, scheduleDayliIndex) => (
                                        <div key={scheduleDayliIndex}>
                                            {scheduleDayli.schedule.map((schedule, scheduleIndex) => {
                                                // const hasService = services.some(service => service.hora === schedule.time);
                                                const hasService = services.some(service => {
                                                    if (scheduleDayli.date === service.booking_datetime) {
                                                        return service.hora === schedule.time;
                                                    }else{
                                                        return false;
                                                    }
                                                });
                                                return (
                                                    scheduleDayItem.substring(0, 2) === schedule.time.substring(0, 2) && (
                                                        <div style={{ padding: 1, width: "100%", background: "#fff" }}>

                                                            {hasService ? (
                                                                // Renderizar la tarjeta con el evento cuando hay un servicio
                                                                <Card key={scheduleIndex} className="container-card-render">
                                                                    {
                                                                        services
                                                                            .filter(service => service.hora === schedule.time && service.booking_datetime === scheduleDayli.date)
                                                                            .map((filteredService, filteredServiceIndex) => (
                                                                                <div
                                                                                    className="card-calendar-render"
                                                                                    onClick={() => { setOptionsVisibleView(!optionsVisibleView); setId(10); }}
                                                                                >

                                                                                    <div className="row">
                                                                                        <div className="col-md-3 d-flex align-items-center">
                                                                                            {handlerApp.ChipCustomer(filteredService.type)}
                                                                                        </div>

                                                                                        <div className="col-md-3 d-flex align-items-center">
                                                                                            <button className="btn btn-primary-yellow-1 d-flex align-items-center" style={{ borderRadius: "15px", height: "25px" }}>
                                                                                                checkout
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p style={{ color: "#000000", fontWeight: "bold", fontSize: "12px", lineHeight: 2, letterSpacing: 0 }}>
                                                                                        {filteredService.name}
                                                                                    </p>
                                                                                    <p style={{ color: "#000000", fontWeight: "400", fontSize: "10px", lineHeight: "auto", letterSpacing: 0 }}>
                                                                                        {filteredService.service} {handlerApp.formatCurrency(filteredService.pricing)}
                                                                                    </p>
                                                                                </div>
                                                                            ))
                                                                    }
                                                                </Card>


                                                            ) : (
                                                                // Renderizar el dropdown solo si no hay servicios
                                                                <Card key={scheduleIndex} className="card-calendar" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <ul className="dropdown-menu">
                                                                        {
                                                                            (
                                                                                <>
                                                                                    <li>
                                                                                        <button className="btn" onClick={() => { setOptionsVisibleForm(!optionsVisibleForm) }} title="crear">
                                                                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M7.5 8.375C7.5 8.58594 7.3125 8.75 7.125 8.75H5.625V10.25C5.625 10.4609 5.4375 10.625 5.25 10.625C5.03906 10.625 4.875 10.4609 4.875 10.25V8.75H3.375C3.16406 8.75 3 8.58594 3 8.375C3 8.1875 3.16406 8 3.375 8H4.875V6.5C4.875 6.3125 5.03906 6.125 5.25 6.125C5.4375 6.125 5.625 6.3125 5.625 6.5V8H7.125C7.3125 8 7.5 8.1875 7.5 8.375ZM2.625 0.5C2.8125 0.5 3 0.6875 3 0.875V2H7.5V0.875C7.5 0.6875 7.66406 0.5 7.875 0.5C8.0625 0.5 8.25 0.6875 8.25 0.875V2H9C9.82031 2 10.5 2.67969 10.5 3.5V11C10.5 11.8438 9.82031 12.5 9 12.5H1.5C0.65625 12.5 0 11.8438 0 11V3.5C0 2.67969 0.65625 2 1.5 2H2.25V0.875C2.25 0.6875 2.41406 0.5 2.625 0.5ZM9.75 5H0.75V11C0.75 11.4219 1.07812 11.75 1.5 11.75H9C9.39844 11.75 9.75 11.4219 9.75 11V5ZM9 2.75H1.5C1.07812 2.75 0.75 3.10156 0.75 3.5V4.25H9.75V3.5C9.75 3.10156 9.39844 2.75 9 2.75Z" fill="#37465A" />
                                                                                            </svg>
                                                                                            Create appoiment
                                                                                        </button>
                                                                                    </li>
                                                                                    <li>
                                                                                        <button className="btn" onClick={() => { handlerAction(null, 'create') }} title="crear">
                                                                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M0.875 1.1875C0.875 0.882812 1.10938 0.625 1.4375 0.625H2.5625C2.86719 0.625 3.125 0.882812 3.125 1.1875V2.3125C3.125 2.64062 2.86719 2.875 2.5625 2.875H1.4375C1.10938 2.875 0.875 2.64062 0.875 2.3125V1.1875ZM2.375 2.125V1.375H1.625V2.125H2.375ZM12.125 1.375C12.3125 1.375 12.5 1.5625 12.5 1.75C12.5 1.96094 12.3125 2.125 12.125 2.125H4.625C4.41406 2.125 4.25 1.96094 4.25 1.75C4.25 1.5625 4.41406 1.375 4.625 1.375H12.125ZM12.125 5.125C12.3125 5.125 12.5 5.3125 12.5 5.5C12.5 5.71094 12.3125 5.875 12.125 5.875H4.625C4.41406 5.875 4.25 5.71094 4.25 5.5C4.25 5.3125 4.41406 5.125 4.625 5.125H12.125ZM12.125 8.875C12.3125 8.875 12.5 9.0625 12.5 9.25C12.5 9.46094 12.3125 9.625 12.125 9.625H4.625C4.41406 9.625 4.25 9.46094 4.25 9.25C4.25 9.0625 4.41406 8.875 4.625 8.875H12.125ZM2.5625 4.375C2.86719 4.375 3.125 4.63281 3.125 4.9375V6.0625C3.125 6.39062 2.86719 6.625 2.5625 6.625H1.4375C1.10938 6.625 0.875 6.39062 0.875 6.0625V4.9375C0.875 4.63281 1.10938 4.375 1.4375 4.375H2.5625ZM1.625 5.125V5.875H2.375V5.125H1.625ZM0.875 8.6875C0.875 8.38281 1.10938 8.125 1.4375 8.125H2.5625C2.86719 8.125 3.125 8.38281 3.125 8.6875V9.8125C3.125 10.1406 2.86719 10.375 2.5625 10.375H1.4375C1.10938 10.375 0.875 10.1406 0.875 9.8125V8.6875ZM2.375 9.625V8.875H1.625V9.625H2.375Z" fill="#37465A" />
                                                                                            </svg>
                                                                                            Add to wailist
                                                                                        </button>
                                                                                    </li>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </Card>
                                                            )}
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };
    const [selectedBarber, setSelectedBarber] = useState(null);

    const getPhotoBarber = (e) => {
        console.log(e);
        barbers.forEach((barber) => {
            if (barber.code === e) {
                barber.photo != null ? setPhoto({ photo: barber.photo, name: barber.name }) : setPhoto({ photo: notFound, name: barber.name });
                setSelectedBarber(barber); // Set selected barber
            }
        });
    }

    console.log(photo);
    return (
        <div className="app container">
            {/* <!-- titulo --> */}


            <div className="row">
                <div className="col-md-6 mb-2">
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <BoldSelectWithImageValidated
                                value={barberId}
                                name="user_id"
                                onChange={(e) => {
                                    setId(e.target.value);
                                    setBarberId(e.target.value);
                                    getParamsSchedule(e.target.value);
                                    getPhotoBarber(e.target.value);
                                }}
                                options={barbers}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>

                        <div className="col-md-1 mb-2">
                            <button className="btn btn-primary-yellow-1 btn-rounde" type="button" onClick={() => handlerApp.backDay(date, setDate)}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={iconChevronLeft} />
                                </div>
                            </button>
                        </div>

                        <div className="col-md-1 mb-2">
                            <button className="btn btn-primary-yellow-1 btn-rounde" type="button" onClick={() => handlerApp.nextDay(date, setDate)}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={iconChevronRight} />
                                </div>
                            </button>
                        </div>

                        <div className="col-md-4 mb-2">
                            <DatePicker
                                className="text-view"
                                format="YYYY/MM/DD"
                                name="barber_date"
                                variant="outlined"
                                onChange={(val) => { setDate(val) }}
                                value={date}
                                slotProps={{ textField: { size: 'small', variant: "outlined" } }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-2">
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                value={columnas}
                                name="agenda_type"
                                onChange={(e) => setColumnas(e.target.value)}
                                options={options}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>

                        <div className="col-md-6 mb-2 dropdown" >

                            <button className="btn btn-primary-yellow-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <span className="dropdown-item-icon me-2">
                                        <img src={iconAdd} />
                                    </span>
                                    {t("create")}
                                </div>
                            </button>

                            <ul className="dropdown-menu">
                                {
                                    permissions?.indexOf("create") !== -1 &&
                                    (
                                        <>
                                            <li>
                                                <button className="btn" onClick={() => { setOptionsVisibleForm(!optionsVisibleForm) }} title="crear">
                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.5 8.375C7.5 8.58594 7.3125 8.75 7.125 8.75H5.625V10.25C5.625 10.4609 5.4375 10.625 5.25 10.625C5.03906 10.625 4.875 10.4609 4.875 10.25V8.75H3.375C3.16406 8.75 3 8.58594 3 8.375C3 8.1875 3.16406 8 3.375 8H4.875V6.5C4.875 6.3125 5.03906 6.125 5.25 6.125C5.4375 6.125 5.625 6.3125 5.625 6.5V8H7.125C7.3125 8 7.5 8.1875 7.5 8.375ZM2.625 0.5C2.8125 0.5 3 0.6875 3 0.875V2H7.5V0.875C7.5 0.6875 7.66406 0.5 7.875 0.5C8.0625 0.5 8.25 0.6875 8.25 0.875V2H9C9.82031 2 10.5 2.67969 10.5 3.5V11C10.5 11.8438 9.82031 12.5 9 12.5H1.5C0.65625 12.5 0 11.8438 0 11V3.5C0 2.67969 0.65625 2 1.5 2H2.25V0.875C2.25 0.6875 2.41406 0.5 2.625 0.5ZM9.75 5H0.75V11C0.75 11.4219 1.07812 11.75 1.5 11.75H9C9.39844 11.75 9.75 11.4219 9.75 11V5ZM9 2.75H1.5C1.07812 2.75 0.75 3.10156 0.75 3.5V4.25H9.75V3.5C9.75 3.10156 9.39844 2.75 9 2.75Z" fill="#37465A" />
                                                    </svg>
                                                    {t("calendar.createAppoiment")}
                                                </button>
                                            </li>
                                            <li>
                                                <button className="btn" title="crear">
                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.875 1.1875C0.875 0.882812 1.10938 0.625 1.4375 0.625H2.5625C2.86719 0.625 3.125 0.882812 3.125 1.1875V2.3125C3.125 2.64062 2.86719 2.875 2.5625 2.875H1.4375C1.10938 2.875 0.875 2.64062 0.875 2.3125V1.1875ZM2.375 2.125V1.375H1.625V2.125H2.375ZM12.125 1.375C12.3125 1.375 12.5 1.5625 12.5 1.75C12.5 1.96094 12.3125 2.125 12.125 2.125H4.625C4.41406 2.125 4.25 1.96094 4.25 1.75C4.25 1.5625 4.41406 1.375 4.625 1.375H12.125ZM12.125 5.125C12.3125 5.125 12.5 5.3125 12.5 5.5C12.5 5.71094 12.3125 5.875 12.125 5.875H4.625C4.41406 5.875 4.25 5.71094 4.25 5.5C4.25 5.3125 4.41406 5.125 4.625 5.125H12.125ZM12.125 8.875C12.3125 8.875 12.5 9.0625 12.5 9.25C12.5 9.46094 12.3125 9.625 12.125 9.625H4.625C4.41406 9.625 4.25 9.46094 4.25 9.25C4.25 9.0625 4.41406 8.875 4.625 8.875H12.125ZM2.5625 4.375C2.86719 4.375 3.125 4.63281 3.125 4.9375V6.0625C3.125 6.39062 2.86719 6.625 2.5625 6.625H1.4375C1.10938 6.625 0.875 6.39062 0.875 6.0625V4.9375C0.875 4.63281 1.10938 4.375 1.4375 4.375H2.5625ZM1.625 5.125V5.875H2.375V5.125H1.625ZM0.875 8.6875C0.875 8.38281 1.10938 8.125 1.4375 8.125H2.5625C2.86719 8.125 3.125 8.38281 3.125 8.6875V9.8125C3.125 10.1406 2.86719 10.375 2.5625 10.375H1.4375C1.10938 10.375 0.875 10.1406 0.875 9.8125V8.6875ZM2.375 9.625V8.875H1.625V9.625H2.375Z" fill="#37465A" />
                                                    </svg>
                                                    {t("calendar.AddtoWailist")}
                                                </button>
                                            </li>
                                        </>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className={`col-md-12 mb-2 ${selectedBarber ? 'calendar-photo-render' : 'calendar-photo'}`}>
                    <img className="bold-avatar-calendar" src={photo.photo} alt={photo.photo} />
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: 18, marginLeft: -9 }} data-bs-toggle="dropdown" aria-expanded="false" >
                        <path d="M5 5.375C4.82422 5.375 4.66797 5.31641 4.55078 5.19922L0.800781 1.44922C0.546875 1.21484 0.546875 0.804688 0.800781 0.570312C1.03516 0.316406 1.44531 0.316406 1.67969 0.570312L5 3.87109L8.30078 0.570312C8.53516 0.316406 8.94531 0.316406 9.17969 0.570312C9.43359 0.804688 9.43359 1.21484 9.17969 1.44922L5.42969 5.19922C5.3125 5.31641 5.15625 5.375 5 5.375Z" fill="#3165CC" />
                    </svg>
                    <ul className="dropdown-menu">
                        <>
                            <li>
                                <Link to={`/team/usersbarber/view/${id}`} className="btn" title="Ver barbero">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.25 5.5C10.25 7.16406 8.89062 8.5 7.25 8.5C5.58594 8.5 4.25 7.16406 4.25 5.5C4.25 3.85938 5.58594 2.5 7.25 2.5C8.89062 2.5 10.25 3.85938 10.25 5.5ZM7.25 3.25C5.98438 3.25 5 4.25781 5 5.5C5 6.74219 5.98438 7.75 7.25 7.75C8.49219 7.75 9.5 6.74219 9.5 5.5C9.5 4.25781 8.49219 3.25 7.25 3.25ZM11.75 2.14844C12.8516 3.15625 13.5781 4.375 13.9297 5.21875C14 5.40625 14 5.61719 13.9297 5.80469C13.5781 6.625 12.8516 7.84375 11.75 8.875C10.6484 9.90625 9.125 10.75 7.25 10.75C5.35156 10.75 3.82812 9.90625 2.72656 8.875C1.625 7.84375 0.898438 6.625 0.546875 5.80469C0.476562 5.61719 0.476562 5.40625 0.546875 5.21875C0.898438 4.375 1.625 3.15625 2.72656 2.14844C3.82812 1.11719 5.35156 0.25 7.25 0.25C9.125 0.25 10.6484 1.11719 11.75 2.14844ZM1.25 5.5C1.55469 6.25 2.23438 7.375 3.24219 8.3125C4.25 9.25 5.58594 10 7.25 10C8.89062 10 10.2266 9.25 11.2344 8.3125C12.2422 7.375 12.9219 6.25 13.25 5.5C12.9219 4.75 12.2422 3.625 11.2344 2.6875C10.2266 1.75 8.89062 1 7.25 1C5.58594 1 4.25 1.75 3.24219 2.6875C2.23438 3.625 1.55469 4.75 1.25 5.5Z" fill="#37465A" />
                                    </svg>
                                    {t("userBarbersComponent.viewBarber")}
                                </Link>
                            </li>
                            <li>
                            <Link to={`/team/usersbarber/edit/${id}`} className="btn" title="Actualizar">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.1016 1.90625C12.5938 2.42188 12.5938 3.24219 12.1016 3.75781L4.76562 11.0938C4.48438 11.3516 4.15625 11.5625 3.80469 11.6562L0.96875 12.5C0.828125 12.5234 0.6875 12.5 0.59375 12.4062C0.5 12.3125 0.453125 12.1719 0.5 12.0312L1.34375 9.19531C1.4375 8.84375 1.64844 8.51562 1.90625 8.23438L9.24219 0.898438C9.75781 0.40625 10.5781 0.40625 11.0938 0.898438L12.1016 1.90625ZM8.49219 2.72656L10.2734 4.50781L11.5625 3.24219C11.7734 3.00781 11.7734 2.65625 11.5625 2.44531L10.5547 1.4375C10.3438 1.22656 9.99219 1.22656 9.75781 1.4375L8.49219 2.72656ZM7.95312 3.24219L2.44531 8.77344C2.25781 8.9375 2.11719 9.17188 2.04688 9.40625L1.41406 11.5859L3.59375 10.9531C3.82812 10.8828 4.0625 10.7422 4.22656 10.5547L9.73438 5.04688L7.95312 3.24219Z" fill="#37465A" />
                                    </svg>
                                    {t("userBarbersComponent.update")}
                                </Link>
                            </li>
                        </>
                    </ul>
                    <p className="bold-name-calendar">{photo.name}</p>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12">
                    <ListaColumnas columnas={columnas} horarios={schedule} />
                </div>
            </div>

            <CalendarForm endpoint={"team/users"} visible={optionsVisibleForm} setOptionsVisibleForm={setOptionsVisibleForm} id={id} />
            <CalendarView endpoint={"team/users"} visible={optionsVisibleView} setOptionsVisibleView={setOptionsVisibleView} setOptionsVisibleForm={setOptionsVisibleForm} visibleForm={optionsVisibleForm} />

        </div>
    );
};

export default Calendar;


