
import { TextField, Alert } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm } from "react-hook-form";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import moment from "moment/moment";

const NotesForms = () => {
    const endpoint = "customer/notes";
    const parent = "Customers";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('notes.create');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    // parametros
    const [customers, setCustomer] = useState([]);
    const [companies, setCompanies] = useState([]);

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);

    // formulario
    const [formData, setFormData] = useState({
        company_id: "",
        customer_id: "",
        customernote_text: "",
    });

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    console.log(response.data);
                    setCustomer(handlerApp.getResultFromObject(response.data, 'customers'))
                    setCompanies(handlerApp.getResultFromObject(response.data, "companies"))

                    if (id !== undefined) {
                        setTitle('notes.update')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }, [])

    const handleDependencies = (data) => {
        console.log(data);
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        console.log(data);
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }


    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('customers.customer')}
                                value={formData.customer_id}
                                name="customer_id"
                                onChange={handleForm}
                                options={customers}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('companyComponent.company')}
                                value={formData.company_id}
                                name="company_id"
                                onChange={handleForm}
                                options={companies}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <TextField className="bold-text-field-material" name="customernote_text" value={formData.customernote_text} onChange={handleForm} multiline rows={3} label={t('notes.title')} size="small" />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NotesForms;