import React, { useEffect, useState } from 'react';
import { NavLink, useParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';


const NavbarEdit = (props) => {
    const [t] = useTranslation('global');
    const { id } = useParams(); 

    const [data, setData] = useState({
        id:         id, 
        customer_id:    props?.customer_id
    });
    
    useEffect(()=>{
        let customer_id = null;
        if(props.customer_id !== undefined && props.customer_id !== ''){
            localStorage.setItem('navbar-customer_id',props.customer_id);
            customer_id = props.customer_id
        }else{
            customer_id = localStorage.getItem('navbar-customer_id');
        }

        setData({
            ...data, 
            customer_id: customer_id
        });
    },[props])

    return (
        <nav className="child-menu">
            <NavLink to={`/customer/edit/${data.id}`} activeclassname="active" end>Perfil</NavLink>
            <NavLink to={`/notificaciones/customer/${data.id}`} activeclassname="active" end>{t('notifications.notifications')}</NavLink>
        </nav>
    );
};


export default NavbarEdit;