import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import logoDark from "../../assets/img/logo_black.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BoldInputPhone from "../atoms/Inputs/BoldInputPhone";
import ButtonPrimary from "../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../atoms/Buttons/ButtonOutline";
import ButtonLinkInline from "../atoms/Buttons/ButtonLinkInline";

const Login = () => {
    const [loginForm, setLoginForm] = useState({
        fuser: ""
    });
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();

    const {register, handleSubmit, formState : {errors}, setValue} = useForm();

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;
    
    const [t,i18n] = useTranslation("global");
    const [language,setLanguage] = useState("");
    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])


    const handleLogin = (e) => {
        let key = e.target.name;
        setLoginForm({
            ...loginForm,
            [key]: e.target.value
        });
        setError();
    }

    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    const nav = useNavigate();

    const handleFormLogin = (data) => {
        setLoader(true);
        axios.post(env.urlBackend + '/login', data)
        .then(response => {
            if(response.data.success){
                localStorage.setItem('phone',data.fuser);
                nav('/authenticate/verifyotp');
            }else{
                
                setError(t(response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            if(error.response.status !== 500){
                if(error.response.data.message === "0005"){
                    nav("blocked");
                }else{
                    setError(t(error.response.data.message));
                }
            }else{
                setError(t(error.response.data.message));
            }
        })
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                        <h1 className="title-login">{t("loginComponent.titleLogin")}</h1>
                        <form action="/" onSubmit={handleSubmit(handleFormLogin)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <BoldInputPhone 
                                        name={"fuser"} 
                                        label={t('phone')} 
                                        register={register} 
                                        errors={errors.fuser}
                                        onChange={handlePhone}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mb-3">
                                <ButtonLinkInline label={t("loginComponent.forgotNumber")+"?"} onClick={()=>nav("/authenticate/forgot-number")}/>
                            </div>
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <center>
                                <ButtonPrimary type="submit" label="login" />
                                <span className="small-text regular">{t("loginComponent.dontHaveAccount")}?</span>
                                <ButtonOutline label={t("loginComponent.startNow")} onClick={()=>nav("/authenticate/signup")} type="button"/><br/><br/>
                                <div className='footer-login'>
                                <p className='small-text'>{t("loginComponent.byLoggingYourAccept")}</p>
                                <Link to={"/terms/terms-and-conditions"} className="terms-link">{t("loginComponent.privacyPolicyAndTerms")}</Link> 
                                </div>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('contact')}}>
                <span className="material-symbols-outlined">help</span>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default Login;