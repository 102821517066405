import { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import { CKEditor } from "ckeditor4-react";

const Policies = () => {
    const endpoint = "configuration/policies";
    const parent = "Configuration";
    const [t] = useTranslation("global");
    const title = t('policiesComponent.policiesAndTermsOfPrivacy');
    const prefix = "policy";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        location: [],
        type: []
    });
    
    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])


    const [editorContent, setEditorContent] = useState('');
    const [editorContentSpanish, setEditorContentSpanish] = useState('');
    const [editorContentPrivacy, setEditorContentPrivacy] = useState('');
    const [editorContentPrivacySpanish, setEditorContentPrivacySpanish] = useState('');
    const [CKEditorLoad, setCKEditorLoad] = useState(false);

    const handleEditorChange = (event, editor) => {
        const data = event.editor.getData();
        setEditorContent(data);
        console.log(data);
    };

    const handleEditorSpanishChange = (event, editor)=>{
        const data = event.editor.getData();
        console.log(data);
        setEditorContentSpanish(data);
        
    };

    const handleEditorChangePrivacy = (event,editor)=>{
        const data = event.editor.getData();
        setEditorContentPrivacy(data);
    };

    const handleEditorChangePrivacySpanish = (event,editor)=>{
        const data = event.editor.getData();
        setEditorContentPrivacySpanish(data);
    };

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id,action)=>{
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint,{
            action: 'getCurrenciesPolicies'
        })
        .then(response => {
            if(response.success){
                response.data.forEach(element => {
                    if(element.policy_type == 'Terms and Conditions'){
                        setEditorContent(element.policy_content);
                        setEditorContentSpanish(element.policy_content_spanish);
                    }
                    if(element.policy_type == 'Privacy Policies'){
                        setEditorContentPrivacy(element.policy_content);
                        setEditorContentPrivacySpanish(element.policy_content_spanish);
                    }
                });
                setCKEditorLoad(true); // ya se pueden mostrar los editores con sus valores iniciales.
                // setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCancel = (e)=>{
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitTermsConditions = ()=>{
        handlerApp.setLoader(true);
        api.post(endpoint,{
            policy_type: "Terms and Conditions",
            policy_content: editorContent,
            policy_content_spanish: editorContentSpanish,
            policy_active: 1
        })
        .then(response =>{
            if(response.success){
                // console.log(response);
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleSubmitPrivacyPolicies = ()=>{
        handlerApp.setLoader(true);
        api.post(endpoint,{
            policy_type: "Privacy Policies",
            policy_content: editorContentPrivacy,
            policy_content_spanish: editorContentPrivacySpanish,
            policy_active: 1
        })
        .then(response =>{
            if(response.success){
                console.log(response);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }


    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">En esta sección podrás tener todo el control de tus {title}.</p>
                </section>
                {/* <div className="dropdown">
                    {handlerApp.returnDropDownActions(permissions, handlerAction)}
                </div> */}
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            { CKEditorLoad && (
                <>
                    <h4 className="mt-4">Terms and Conditions english</h4>
                    <CKEditor
                        initData={editorContent} // Inicializa el contenido del editor
                        onChange={handleEditorChange}
                        // content={editorContent}
                    />
                    <h4 className="mt-4">Terms and Conditions spanish</h4>
                    <CKEditor
                        initData={editorContentSpanish} // Inicializa el contenido del editor
                        onChange={handleEditorSpanishChange}
                    />
                    <div className="text-center">
                        <button className="bold-btn bold-btn-dark" onClick={handleSubmitTermsConditions}>{t('submit')}</button>
                    </div>

                    <h4 className="mt-4">Politicas de privacidad</h4>
                    <CKEditor
                        initData={editorContentPrivacy} // Inicializa el contenido del editor
                        onChange={handleEditorChangePrivacy}
                    />
                    <h4 className="mt-4">Politicas de privacidad spanish</h4>
                    <CKEditor
                        initData={editorContentPrivacySpanish} // Inicializa el contenido del editor
                        onChange={handleEditorChangePrivacySpanish}
                    />
                    <div className="text-center">
                        <button className="bold-btn bold-btn-dark" onClick={handleSubmitPrivacyPolicies}>{t('submit')}</button>
                    </div>
                </>
            )}
        </div>
    )
}

export default Policies;