import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


const ServicesLocationView = () => {
    const endpoint = "services/services-locations";
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const { id } = useParams();
    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let data = response.data[0];
                    setTitle(data.category_name);
                    setData(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }, [])

    const back = () => {
        nav(-1);
    }

    return (
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('services')}:</dt>
                        <dd>{data.service_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.location')}</dt>
                        <dd>{data.location_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('servicesLocation.price')}:</dt>
                        <dd> $ {data.service_price}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('productComponent.discount')}:</dt>
                        <dd>{data.servicelocation_discount}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('productLocationComponent.discount_start')}:</dt>
                        <dd>{data.servicelocation_discount_start}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('productLocationComponent.discount_end')}:</dt>
                        <dd>{data.servicelocation_discount_end}</dd>
                    </div>

                    <div className="col-md-6 mb-2">
                        <dt>{t('servicesLocation.visibility')}:</dt>
                        <dd>{
                            data.servicelocation_visibility = 0 ? 'Visible' : t("servicesLocation.isNotVisible")
                        }
                        </dd>
                    </div>

                    <div className="col-md-12 mb-2">
                        <dt>{t('taxesComponent.title')}:</dt>
                        <dd>
                           {data.taxes}
                        </dd>
                    </div>


                </div>

                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ServicesLocationView;