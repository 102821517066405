import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import { useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import NavbarEditBarbers from "./NavbarEditBarbers";
import moment from "moment/moment";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import HandlerApp from "../../../utils/handlerApp";
import BarberlyticsFilesUpload from "../../../utils/BarberlyticsFilesUpload";
import SwitchObject from "../../atoms/SwitchObject";

const BarbersForms = () => {
    const endpoint = "team/usersbarber";
    const parent = "Team";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('userBarbersComponent.create');
    const api = useFetch();
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const [data, setData] = useState([]);
    const { app } = useContext(AppContext);
    const { id, profile_id } = useParams();
    const params = useLocation();

    const [images, setImages] = useState([]); 
    const handleImages = (e) => {
        setImages(e)
    }

    // parametros
    const [levels, setLevels] = useState([]);
    const [locations, setLocations] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (startDate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, userbarber_start_date: startDate?.format('YYYY-MM-DD') });
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, userbarber_end_date: endDate?.format('YYYY-MM-DD') });
        }
    }, [endDate]);

    // formulario
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        "user_username" : "",
        "user_photo": "",
        "location_id" : "",
        "company_id" :  "",
        "barberlevel_id" : "",
        "person_firstname" : "",
        "person_secondname" : "",
        "person_surname" : "",
        "person_secondsurname" : "",
        "person_email" : "",
        "person_birthdate" : "",
        "userbarber_start_date" : "",
        "userbarber_end_date" : "",
        "userbarber_post_booking" : "",
        "userbarber_booking_visibility" : 0,
        "userbarber_tip" : 0,
        "userbarber_interval_schedule" : "",
        "userbarber_walkin_restriction" : 0
    });

    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        getParamsUpdate();
        if(params.state?.user_id !== undefined){
            setFormData(
                {
                    ...formData, user_id: params.state.user_id
                }
            )
        }
    }, [])

    const getParamsUpdate = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
        .then(response => {
            if (response.success) {
                setLevels(handlerApp.getResultFromObject(response.data, "barberlevel"));
                setLocations(handlerApp.getResultFromObject(response.data, "locations"));
                if (id !== undefined) {
                    setTitle(t('userBarbersComponent.update'));
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
       
        if (d.userbarber_start_date != null) {
            //setStartDate(moment(d.userbarber_start_date));
        }

        if (d.userbarber_end_date != null) {
            //setEndDate(moment(d.userbarber_end_date));
        }
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let d = response.data[0];
                setData(d);
                handleDependencies(d);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }
    //TODO: Complementar componente "libreria" para boton tipo switch
    const handleCheck = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        let body= {
            ...data,
            profile_id: formData.profile_id,
            userbarber_start_date: startDate,
            userbarber_end_date: endDate, 
            user_photo: images,
        }
        console.log("el body es: ",body);
        handlerApp.setLoader(true);
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        let body ={
            ...data,
            userbarber_start_date: startDate,
            userbarber_end_date: endDate,
            user_photo: images,
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    // dependencias

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>{t("cancel")}</p>
                </div>
            </div>
            <NavbarEditBarbers id={data.userbarber_id} user_id={data.user_id}/>
            <div className="section-form">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="circle-image">
                                <BarberlyticsFilesUpload  defaultIcon={''} returnImages={handleImages} multiple={false} value={formData.user_photo}/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("personComponent.firstName")} variant="outlined" size="small"  className="barberlytics_text-field"
                                {...register("person_firstname", { required: true, min: 1 })}
                                value={formData.person_firstname}
                                onChange={handleForm}
                                error={errors.person_firstname != null}
                                helperText={
                                    errors.person_firstname?.type === 'required' ? t("errors.required") :
                                    errors.person_firstname?.type === 'min' ? t("errors.min") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("personComponent.surName")} variant="outlined" size="small"  className="barberlytics_text-field"
                                {...register("person_surname", { required: true, min: 1 })}
                                value={formData.person_surname}
                                onChange={handleForm}
                                error={errors.person_surname != null}
                                helperText={
                                    errors.person_person_surname?.type === 'required' ? t("errors.required") :
                                    errors.person_surname?.type === 'min' ? t("errors.min") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("phone")} variant="outlined" size="small" type="phone" className="barberlytics_text-field"
                                {...register("user_username", { required: true, min: 1 })}
                                value={formData.user_username}
                                onChange={handleForm}
                                error={errors.user_username != null}
                                helperText={
                                    errors.user_username?.type === 'required' ? t("errors.required") :
                                    errors.user_username?.type === 'min' ? t("errors.min") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("email")} variant="outlined" size="small" type="email" className="barberlytics_text-field"
                                {...register("person_email", { required: true, min: 1 })}
                                value={formData.person_email}
                                onChange={handleForm}
                                error={errors.person_email != null}
                                helperText={
                                    errors.person_email?.type === 'required' ? t("errors.required") :
                                    errors.person_email?.type === 'min' ? t("errors.min") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('levelComponent.level')}
                                value={formData.barberlevel_id}
                                name="barberlevel_id"
                                onChange={handleForm}
                                options={levels}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('locationComponent.location')}
                                value={formData.location_id}
                                name="location_id"
                                onChange={handleForm}
                                options={locations}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        {/* <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('userBarbersComponent.startDate')}
                                format="YYYY/MM/DD"
                                name="userbarber_start_date"
                                variant="outlined"
                                onChange={(val) => { setStartDate(val);}}
                                value={startDate}
                                slotProps={{ textField: { size: 'small', variant: "outlined" } }}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('userBarbersComponent.endDate')}
                                format="YYYY/MM/DD"
                                name="userbarber_end_date"
                                variant="outlined"
                                onChange={(val) => { setEndDate(val); }}
                                value={endDate}
                                slotProps={{ textField: { size: 'small', variant: "outlined" } }}
                            />
                        </div> */}
                        <div className="col-md-6 mb-2">
                            <TextField
                                className="barberlytics_text-field"
                                label={t("userBarbersComponent.postBookingDays")} variant="outlined" size="small" type="number"
                                {...register("userbarber_post_booking", { required: true, min: 1 })}
                                value={formData.userbarber_post_booking}
                                onChange={handleForm}
                                error={errors.userbarber_post_booking != null}
                                helperText={
                                    errors.userbarber_post_booking?.type === 'required' ? t("errors.required") :
                                        errors.userbarber_post_booking?.type === 'min' ? t("errors.min") : ""}
                            />
                        </div>
                        <div className="col-6 col-md-6">
                            <SwitchObject label={t("userBarbersComponent.tip")} id={"option-tip"} value={formData.userbarber_tip} name={"userbarber_tip"} onChange={handleCheck}/>
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField
                                className="barberlytics_text-field"
                                label={t("userBarbersComponent.intervalSchedule")} variant="outlined" size="small" type="number"
                                {...register("userbarber_interval_schedule", { required: true, min: 0 })}
                                value={formData.userbarber_interval_schedule}
                                onChange={handleForm}
                                error={errors.userbarber_interval_schedule != null}
                                helperText={
                                    errors.userbarber_interval_schedule?.type === 'required' ? t("errors.required") :
                                        errors.userbarber_interval_schedule?.type === 'min' ? t("errors.min") : ""}
                            />
                        </div>
                        <div className="col-6 col-md-6">
                            <SwitchObject label={t("userBarbersComponent.walkingRestriction")} id={"option-walking"} value={formData.userbarber_walkin_restriction} name={"userbarber_walkin_restriction"} onChange={handleCheck}/>
                        </div>
                        <div className="col-6 col-md-6">
                            <SwitchObject label={t("userBarbersComponent.schedulingVisibility")} id={"option-schedule"} value={formData.userbarber_booking_visibility} name={"userbarber_booking_visibility"} onChange={handleCheck}/>
                        </div>                        
                    </div>
                    
                    <div className="row d-flex justify-content-end">
                        <div className="d-flex justify-content-end me-5">
                            {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('submit')}</button>}
                            {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                        </div>
                    </div>
                </form>
            </div>
            
        </div>
    )
}

export default BarbersForms;