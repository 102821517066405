import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";
// import { Checkbox, TextField } from '@mui/material';
import { useForm } from "react-hook-form";


import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { InputLabel, MenuItem, Select } from "@mui/material";
import Header from "../../molecules/Header";
import NavbarEditBarbers from "../Barbers (users)/NavbarEditBarbers";
import { Checkbox, TextField } from '@mui/material';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const UserShedule = () => {
    const endpoint = "team/users/shedule";
    const [t, i18n] = useTranslation("global");
    const description = t('usersComponent.teamDescription');

    const prefix = "category";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const { id } = useParams();

    const [checked] = useState({});

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    // Crear un arreglo de 24 horas con nombres divididos en AM y PM
    const hoursArray = Array.from({ length: 24 }, (_, i) => ({
        code: i,
        name: `${(i % 12 === 0 ? 12 : i % 12)} ${i < 12 ? 'AM' : 'PM'}`,
    }));

    const [formData, setFormData] = useState([
        {
            schedule_day: 1,
            schedule_start: 8,
            schedule_end: 17,
            schedule_active: 0
        },
        {
            schedule_day: 2,
            schedule_start: 8,
            schedule_end: 17,
            schedule_active: 0
        },
        {
            schedule_day: 3,
            schedule_start: 8,
            schedule_end: 17,
            schedule_active: 0
        },
        {
            schedule_day: 4,
            schedule_start: 8,
            schedule_end: 17,
            schedule_active: 0
        },
        {
            schedule_day: 3,
            schedule_start: 8,
            schedule_end: 17,
            schedule_active: 0
        },
        {
            schedule_day: 6,
            schedule_start: 8,
            schedule_end: 17,
            schedule_active: 0
        },
        {
            schedule_day: 7,
            schedule_start: 8,
            schedule_end: 17,
            schedule_active: 0
        }
    ]
    );

    useEffect(() => {
        sendRequest();
    }, [])

    // TODO: AJUSTAR CUANDO DANIEL TERMINE DE CUSTOMIZAR EL NAV GENERICO

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    console.log(response);
                    let data = response.data;
                    if(data.length > 0){
                        handleDependencies(data)
                    };
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleDependencies = (data) => {
        const initialFormData = data.map(item => ({
            schedule_day: item.schedule_day,
            schedule_start: item.schedule_start,
            schedule_end: item.schedule_end,
            schedule_active: item.schedule_active,
        }));

        setFormData(initialFormData);
    }

    const handleInputChange = (index, name, value, type) => {
        if (name === 'schedule_start' || name === 'schedule_end') {
            setFormData((prevFormData) => {
                const updatedFormData = [...prevFormData];
                updatedFormData[index][name] = value;
                return updatedFormData;
            });
        }
    };


    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        data.preventDefault();

        console.log(formData);

        api.post(endpoint, formData)
            .then(response => {
                console.log(response.success);
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint+ id);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        data.preventDefault();
        api.put(endpoint + "/" + id, formData)
        .then(response => {
            if (response.success) {
                handlerApp.setLoader(false);
                handlerApp.showOk(response);
                nav("/" + endpoint+ "/" + id);
            } else {
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleChange = (id, index, value) => {
        setFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
           
            const updatedItem = { ...updatedFormData[index], "schedule_active": value == true ? 1 : 0};
            updatedFormData[index] = updatedItem;
            return updatedFormData;
        });
    };

    const handleCancel = (e) => {
        e.preventDefault();
        nav('/team/usersbarber');
    }

    return (

        <div className="app container">
              <div className="d-flex justify-content-end">
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>{t("cancel")}</p>
                </div>
            </div>
            <NavbarEditBarbers />


            <div className="container bold-container-content">
                <div className="section-forms">
                    <form onSubmit={handleUpdate}>
                        {formData.map((item, index) => (

                            <div className="row">
                                <div className="col-md-2 mb-2">
                                    <label>
                                        <p className="opciones"><Checkbox
                                            status={item.schedule_active ? 'checked' : 'unchecked'}
                                            checked={item.schedule_active}
                                            onChange={() => handleChange('schedule_active', index, !item.schedule_active)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />{handlerApp.DayOfWeek(item.schedule_day)}</p>
                                    </label>
                                </div>

                                <div className="col-md-3 mb-2">
                                    <BoldSelectValidated
                                        title={t('schedule.startime')}
                                        value={item.schedule_start}
                                        name="schedule_start"
                                        onChange={(e) => handleInputChange(index, e.target.name, e.target.value, e.target.type)}
                                        options={hoursArray}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                    />
                                </div>

                                <div className="col-md-3 mb-2">
                                    <BoldSelectValidated
                                        title={t('schedule.closingtime')}
                                        value={item.schedule_end}
                                        name="schedule_end"
                                        onChange={(e) => handleInputChange(index, e.target.name, e.target.value, e.target.type)}
                                        options={hoursArray}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                    />
                                </div>

                            </div>


                        ))}

                        <div className="row d-flex justify-content-end">
                            <div className="d-flex justify-content-end">
                                <div className="col-md-2 mb-2">
                                    <button className="btn btn-primary-yellow-1" style={{ width: '100%' }} type="submit" >
                                        {t("save")}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div >
            </div >
        </div >
    );
}

export default UserShedule;