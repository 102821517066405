
import { useState, useEffect } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

import imageNotFound from "../assets/img/image-not-found.webp";


const DragAndDrop = ({ data, setData, keyString, name, description, image, status, keyOrder }) => {



  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active.id !== over.id) {
      setData((item) => {
        const oldIndex = item.findIndex((row) => row.id === active.id);
        const newIndex = item.findIndex((row) => row.id === over.id);
        reOrderData(arrayMove(item, oldIndex, newIndex));
        return arrayMove(item, oldIndex, newIndex);
      });
    }
  };

  const reOrderData = (value) => {
    const updatedData = value.map((item, index) => {
      const updatedStatus = item[keyOrder] = index + 1;
      return { ...item, [keyOrder]: updatedStatus };
    });
    setData(updatedData);
  }

  const handleChange = (id) => {
    const updatedData = data.map(item => {
      if (item[keyString] === id) {
        const updatedStatus = item[status] === 1 ? 0 : 1;
        return { ...item, [status]: updatedStatus };
      }
      return item;
    });

    setData(updatedData);
  };

  function DragComponent({ item }) {

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item[keyString] });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
          <div style={style} className="p-2 rounded-md shadow-md text-slate-950">
            <Card sx={{ minWidth: 275 }}>
                <div className="row">

                  <div className="col-md-10 ">
                    <Stack direction="row" spacing={4} style={{ alignItems: "center",padding:10 }} ref={setNodeRef} {...attributes} {...listeners}>
                      <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M0.875 1.15625C0.875 0.800781 1.14844 0.5 1.53125 0.5H12.4688C12.8242 0.5 13.125 0.800781 13.125 1.15625C13.125 1.53906 12.8242 1.8125 12.4688 1.8125H1.53125C1.14844 1.8125 0.875 1.53906 0.875 1.15625ZM0.875 5.53125C0.875 5.17578 1.14844 4.875 1.53125 4.875H12.4688C12.8242 4.875 13.125 5.17578 13.125 5.53125C13.125 5.91406 12.8242 6.1875 12.4688 6.1875H1.53125C1.14844 6.1875 0.875 5.91406 0.875 5.53125ZM12.4688 10.5625H1.53125C1.14844 10.5625 0.875 10.2891 0.875 9.90625C0.875 9.55078 1.14844 9.25 1.53125 9.25H12.4688C12.8242 9.25 13.125 9.55078 13.125 9.90625C13.125 10.2891 12.8242 10.5625 12.4688 10.5625Z" fill="#37465A" />
                      </svg>

                      {item[image] === null ?
                        <img className="bold-image-lineup" src={imageNotFound} alt="Not Found" /> :
                        <img className="bold-image-lineup" src={item[image]} alt={item[name]} />
                      }

                      <p style={{ fontSize: 14, fontWeight: 600 }}>
                        {item[name]}
                      </p>
                    </Stack>
                  </div>
                  {
                    status ? <div className="col-md-2 mb-2">
                      <Switch
                        checked={item[status] === 1}
                        onChange={(e) => handleChange(item[keyString])}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div> : null
                  }
                </div>
            </Card>
          </div>

    );
  }

  return (
    <div className="flex justify-center items-center">
      <div className="w-4/4">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={data}
            strategy={verticalListSortingStrategy}
          >
            {data.map((item) => (
              <DragComponent key={item.id} item={item} />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}


export default DragAndDrop;
