import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Navbar = (props) => {
    const [t] = useTranslation('global');
    const { id } = useParams(); 

    const [data, setData] = useState({
        id:         id, 
        customer_id:    props?.customer_id
    });
    
    useEffect(()=>{
        let customer_id = null;
        if(props.customer_id !== undefined && props.customer_id !== ''){
            localStorage.setItem('navbar-customer_id',props.customer_id);
            customer_id = props.customer_id
        }else{
            customer_id = localStorage.getItem('navbar-customer_id');
        }

        setData({
            ...data, 
            customer_id: customer_id
        });
    },[props])

    return (
        
        <div className="menu-contenedor">
            <nav>
                <ul className="main-nav">
                    {/* <li className="main-item">
                        <NavLink exact to="/perspectivas">Perspectivas</NavLink>
                    </li> */}
                    <li className="main-item active">
                        <NavLink exact to="/customer/view/">{t("profile")}</NavLink>
                    </li>
                    <li className="main-item">
                        <NavLink exact to={`/notificaciones/view/${data.user_id}`}>{t("notifications.notifications")}</NavLink>
                    </li>
                    {/*<li className="main-item">
                        <NavLink exact to="/mensajes">Mensajes</NavLink>
                    </li>
                    <li className="main-item">
                        <NavLink exact to="/metodo-de-pago">Método de Pago</NavLink>
                    </li>
                    
                    <li className="main-item">
                        <NavLink exact to="/productos">Productos</NavLink>
                    </li> */}
                </ul>
            </nav>
        </div>
    );
};



export default Navbar;