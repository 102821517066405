import React, { useEffect, useState } from 'react';
import { NavLink} from "react-router-dom";
import { useTranslation } from 'react-i18next';


const NavbarEditBarbers = (props) => {
    const [t] = useTranslation('global');

    const [data, setData] = useState({
        id:         props?.id,
        user_id:    props?.user_id
    });
    
    useEffect(()=>{
        let id = null;
        let user_id = null;
        if(props.id !== undefined && props.id !== ''){
            localStorage.setItem('navbar-id',props.id);
            id = props.id;
        }else{
            id = localStorage.getItem('navbar-id');
        }

        if(props.user_id !== undefined && props.user_id !== ''){
            localStorage.setItem('navbar-user_id',props.user_id);
            user_id = props.user_id
        }else{
            user_id = localStorage.getItem('navbar-user_id');
        }

        setData({
            ...data, 
            id: id, 
            user_id: user_id
        });
    },[props])

    return (
        <nav className="child-menu">
            <NavLink to={`/team/usersbarber/edit/${data.id}`} activeclassname="active" end>{t('profile')}</NavLink>
            <NavLink to={`/notificaciones/barbers/${data.user_id}`} activeclassname="active" end>{t('notifications.notifications')}</NavLink>
            <NavLink to={`/team/usersbarber/permissions/${data.user_id}`} activeclassname="active" end>{t('permissions.permissions')}</NavLink>
            <NavLink to={`/team/users/shedule/${data.user_id}`} activeclassname="active" end>{t('schedule.schedule')}</NavLink>
        </nav>
    );
};



export default NavbarEditBarbers;