
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import NavbarEditBarbers from "../Barbers (users)/NavbarEditBarbers";
import { Checkbox, FormControlLabel } from "@mui/material";
import { AppContext } from "../../../context/AppContext";

const BarbersPermissionsForms = () => {
    const endpoint = "configuration/permissions";
    const parent = "userbarber";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('permissions.permissions');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();

    // formulario
    const [formData, setFormData] = useState({
        companies:{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "companies/locations":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "companies/subscription-companies": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        calendar: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        booking: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        customer: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "customer/notes":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "customer/reputation":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        // barber comissions
        "team/usersbarber": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        // barber rent
        "team/usersbarber": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "team/barber-level": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "team/users":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "team/users/shedule":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        lineUp: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        dashboard:{
            view: false
        },
        "inventory/products": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        productsCombo: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "inventory/categories": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "inventory/subcategories":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "inventory/storages":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "inventory/suppliers":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "inventory/brands":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        giftCards: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        services: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "services/combo": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "services/categories": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "services/resources": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "services/services-locations": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "services/services-barber": {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        pos: {
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        closingDate:{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "configuration/permissions":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "configuration/userscompanies":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "configuration/policies":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "configuration/taxesgroup":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "configuration/subscriptions":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        },
        "configuration/users/notifications":{
            view: false,
            update: false,
            create: false,
            delete: false,
            inactive: false
        }
    });

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    // data
    useEffect(()=>{
        let endData = formData;
        if(data.length > 0){
            for(let item of data){
                switch(item.menu_children_action){
                    case "calendar":
                        endData = {
                            ...endData,
                            "calendar": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "booking":
                        endData = {
                            ...endData,
                            "booking": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "companies":
                        endData = {
                            ...endData,
                            "companies": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "companies/locations":
                        endData = {
                            ...endData,
                            "companies/locations": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "companies/subscription-companies":
                        endData = {
                            ...endData,
                            "companies/subscription-companies": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "team/usersbarber":
                        endData = {
                            ...endData,
                            "team/usersbarber": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "team/barber-level":
                        endData = {
                            ...endData,
                            "team/barber-level": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "team/users":
                        endData = {
                            ...endData,
                            "team/users": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "team/users/shedule":
                        endData = {
                            ...endData,
                            "team/users/shedule": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "customer":
                        endData = {
                            ...endData,
                            "customer": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "customer/notes":
                        endData = {
                            ...endData,
                            "customer/notes": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "customer/reputation":
                        endData = {
                            ...endData,
                            "customer/reputation": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "services":
                        endData = {
                            ...endData,
                            "services": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "services/combo":
                        endData = {
                            ...endData,
                            "services/combo": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "services/categories":
                        endData = {
                            ...endData,
                            "services/categories": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "services/resources":
                        endData = {
                            ...endData,
                            "services/resources": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "services/services-locations":
                        endData = {
                            ...endData,
                            "services/services-locations": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "services/services-barber":
                        endData = {
                            ...endData,
                            "services/services-barber": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "inventory/products":
                        endData = {
                            ...endData,
                            "inventory/products": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            },
                            "inventory/products": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "inventory/categories":
                        endData = {
                            ...endData,
                            "inventory/categories": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "inventory/subcategories":
                        endData = {
                            ...endData,
                            "inventory/subcategories": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "inventory/storages":
                        endData = {
                            ...endData,
                            "inventory/storages": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "inventory/suppliers":
                        endData = {
                            ...endData,
                            "inventory/suppliers": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "inventory/brands":
                        endData = {
                            ...endData,
                            "inventory/brands": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "configuration/policies":
                        endData = {
                            ...endData,
                            "configuration/policies": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "configuration/userscompanies":
                        endData = {
                            ...endData,
                            "configuration/userscompanies": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "configuration/permissions":
                        endData = {
                            ...endData,
                            "configuration/permissions": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "configuration/taxesgroup":
                        endData = {
                            ...endData,
                            "configuration/taxesgroup": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "configuration/subscriptions":
                        endData = {
                            ...endData,
                            "configuration/subscriptions": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                    case "configuration/users/notifications":
                        endData = {
                            ...endData,
                            "configuration/users/notifications": {
                                view: item.permissions.includes("view"),
                                update: item.permissions.includes("update"),
                                create: item.permissions.includes("create"),
                                delete: item.permissions.includes("delete"),
                                inactive: item.permissions.includes("inactive")
                            }
                        };
                    break;
                }
                setFormData(endData);
            }
        }
    },[data])

    const handleCancel = (e) => {
        e.preventDefault();
        nav('/team/usersbarber');
    }

    // save data
    const handleSubmitForm = () => {
        handlerApp.setLoader(true);
        api.post(endpoint, formData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = () => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, formData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>{t("cancel")}</p>
                </div>
            </div>
            <NavbarEditBarbers/>
            <div className="section-forms-edit">
                <form>
                    <div className="row">
                        {/* Calendarios */}
                        <div className="col-md-12 mb-4">
                            <div className="content-table-permissions">
                                <table className="table-permissions">
                                    <thead className="table-header-permissions">
                                        <tr>
                                            <th width="220">{t("permissions.titleBooking")}</th>
                                            <th align="center">{t("permissions.view")}</th>
                                            <th align="center">{t("permissions.create")}</th>
                                            <th align="center">{t("permissions.edit")}</th>
                                            <th align="center">{t("permissions.delete")}</th>
                                            <th width="140" align="center">{t("permissions.inactive")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* calendarios */}
                                        <tr>
                                            <td>{t("permissions.calendars")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.calendar.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        calendar: {
                                                            ...formData.calendar,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.calendar.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        calendar: {
                                                            ...formData.calendar,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.calendar.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        calendar: {
                                                            ...formData.calendar,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.calendar.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        calendar: {
                                                            ...formData.calendar,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.calendar.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        calendar: {
                                                            ...formData.calendar,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* agendamientos */}
                                        <tr>
                                            <td>{t("permissions.booking")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.booking.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        booking: {
                                                            ...formData.booking,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.booking.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        booking: {
                                                            ...formData.booking,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.booking.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        booking: {
                                                            ...formData.booking,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.booking.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        booking: {
                                                            ...formData.booking,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.booking.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        booking: {
                                                            ...formData.booking,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* clientes */}
                                        <tr>
                                            <td>{t("permissions.customers")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.customer.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        customer: {
                                                            ...formData.customer,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.customer.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        customer: {
                                                            ...formData.customer,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.customer.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        customer: {
                                                            ...formData.customer,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.customer.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        customer: {
                                                            ...formData.customer,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.customer.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        customer: {
                                                            ...formData.customer,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>

                        {/* TEAM */}
                        <div className="col-md-12 mb-4">
                            <div className="content-table-permissions">
                                <table className="table-permissions">
                                    <thead className="table-header-permissions">
                                        <tr>
                                            <th width="220">{t("team.team")}</th>
                                            <th align="center">{t("permissions.view")}</th>
                                            <th align="center">{t("permissions.create")}</th>
                                            <th align="center">{t("permissions.edit")}</th>
                                            <th align="center">{t("permissions.delete")}</th>
                                            <th width="140" align="center">{t("permissions.inactive")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* barbers Comission */}
                                        <tr>
                                            <td>{t("permissions.barbersComission")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* barbers Rent */}
                                        <tr>
                                            <td>{t("permissions.barbersRent")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["team/usersbarber"].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "team/usersbarber": {
                                                            ...formData["team/usersbarber"],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* levels */}
                                        <tr>
                                            <td>{t("permissions.levels")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['team/barber-level'].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'team/barber-level': {
                                                            ...formData['team/barber-level'],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['team/barber-level'].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'team/barber-level': {
                                                            ...formData['team/barber-level'],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['team/barber-level'].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'team/barber-level': {
                                                            ...formData['team/barber-level'],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['team/barber-level'].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'team/barber-level': {
                                                            ...formData['team/barber-level'],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['team/barber-level'].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'team/barber-level': {
                                                            ...formData['team/barber-level'],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* Line up */}
                                        <tr>
                                            <td>{t("permissions.lineUp")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.lineUp.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        lineUp: {
                                                            ...formData.lineUp,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.lineUp.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        lineUp: {
                                                            ...formData.lineUp,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.lineUp.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        lineUp: {
                                                            ...formData.lineUp,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.lineUp.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        lineUp: {
                                                            ...formData.lineUp,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.lineUp.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        lineUp: {
                                                            ...formData.lineUp,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>

                        {/* Dashboard */}
                        <div className="col-md-12 mb-4">
                            <div className="content-table-permissions">
                                <table className="table-permissions">
                                    <thead className="table-header-permissions">
                                        <tr>
                                            <th width="220">{t("KPIs")}</th>
                                            <th align="center">{t("permissions.view")}</th>
                                            <th align="center">{t("permissions.create")}</th>
                                            <th align="center">{t("permissions.edit")}</th>
                                            <th align="center">{t("permissions.delete")}</th>
                                            <th width="140" align="center">{t("permissions.inactive")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* KPIS */}
                                        <tr>
                                            <td>{t("permissions.dashboard")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.dashboard.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        dashboard: {
                                                            ...formData.dashboard,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.dashboard.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        dashboard: {
                                                            ...formData.dashboard,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.dashboard.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        dashboard: {
                                                            ...formData.dashboard,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.dashboard.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        dashboard: {
                                                            ...formData.dashboard,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.dashboard.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        dashboard: {
                                                            ...formData.dashboard,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>

                        {/* inventory */}
                        <div className="col-md-12 mb-4">
                            <div className="content-table-permissions">
                                <table className="table-permissions">
                                    <thead className="table-header-permissions">
                                        <tr>
                                            <th width="220">{t("inventory.inventory")}</th>
                                            <th align="center">{t("permissions.view")}</th>
                                            <th align="center">{t("permissions.create")}</th>
                                            <th align="center">{t("permissions.edit")}</th>
                                            <th align="center">{t("permissions.delete")}</th>
                                            <th width="140" align="center">{t("permissions.inactive")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Products */}
                                        <tr>
                                            <td>{t("permissions.products")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/products'].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/products': {
                                                            ...formData['inventory/products'],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/products'].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/products': {
                                                            ...formData['inventory/products'],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/products'].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/products': {
                                                            ...formData['inventory/products'],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/products'].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/products': {
                                                            ...formData['inventory/products'],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/products'].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/products': {
                                                            ...formData['inventory/products'],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* Products Combos */}
                                        <tr>
                                            <td>{t("permissions.productsCombo")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.productsCombo.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        productsCombo: {
                                                            ...formData.productsCombo,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.productsCombo.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        productsCombo: {
                                                            ...formData.productsCombo,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.productsCombo.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        productsCombo: {
                                                            ...formData.productsCombo,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.productsCombo.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        productsCombo: {
                                                            ...formData.productsCombo,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.productsCombo.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        productsCombo: {
                                                            ...formData.productsCombo,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* Categories */}
                                        <tr>
                                            <td>{t("permissions.categories")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/categories'].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/categories': {
                                                            ...formData['inventory/categories'],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/categories'].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/categories': {
                                                            ...formData['inventory/categories'],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/categories'].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/categories': {
                                                            ...formData['inventory/categories'],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/categories'].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/categories': {
                                                            ...formData['inventory/categories'],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/categories'].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        'inventory/categories': {
                                                            ...formData['inventory/categories'],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* Subcategories */}
                                        <tr>
                                            <td>{t("permissions.subcategories")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/subcategories'].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "inventory/subcategories": {
                                                            ...formData['inventory/subcategories'],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/subcategories'].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "inventory/subcategories": {
                                                            ...formData['inventory/subcategories'],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/subcategories'].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "inventory/subcategories": {
                                                            ...formData['inventory/subcategories'],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/subcategories'].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "inventory/subcategories": {
                                                            ...formData['inventory/subcategories'],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData['inventory/subcategories'].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "inventory/subcategories": {
                                                            ...formData['inventory/subcategories'],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* Gift Cards */}
                                        <tr>
                                            <td>{t("permissions.giftCards")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.giftCards.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        giftCards: {
                                                            ...formData.giftCards,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.giftCards.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        giftCards: {
                                                            ...formData.giftCards,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.giftCards.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        giftCards: {
                                                            ...formData.giftCards,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.giftCards.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        giftCards: {
                                                            ...formData.giftCards,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.giftCards.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        giftCards: {
                                                            ...formData.giftCards,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>

                        {/* Services */}
                        <div className="col-md-12 mb-4">
                            <div className="content-table-permissions">
                                <table className="table-permissions">
                                    <thead className="table-header-permissions">
                                        <tr>
                                            <th width="220">{t("permissions.services")}</th>
                                            <th align="center">{t("permissions.view")}</th>
                                            <th align="center">{t("permissions.create")}</th>
                                            <th align="center">{t("permissions.edit")}</th>
                                            <th align="center">{t("permissions.delete")}</th>
                                            <th width="140" align="center">{t("permissions.inactive")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Services */}
                                        <tr>
                                            <td>{t("permissions.services")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.services.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        services: {
                                                            ...formData.services,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.services.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        services: {
                                                            ...formData.services,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.services.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        services: {
                                                            ...formData.services,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.services.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        services: {
                                                            ...formData.services,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.services.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        services: {
                                                            ...formData.services,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* Services combos */}
                                        <tr>
                                            <td>{t("permissions.servicesCombo")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["services/combo"].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "services/combo": {
                                                            ...formData["services/combo"],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["services/combo"].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "services/combo": {
                                                            ...formData["services/combo"],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["services/combo"].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "services/combo": {
                                                            ...formData["services/combo"],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["services/combo"].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "services/combo": {
                                                            ...formData["services/combo"],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["services/combo"].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "services/combo": {
                                                            ...formData["services/combo"],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                        
                        {/* POS */}
                        <div className="col-md-12 mb-4">
                            <div className="content-table-permissions">
                                <table className="table-permissions">
                                    <thead className="table-header-permissions">
                                        <tr>
                                            <th width="220">{t("permissions.pos")}</th>
                                            <th align="center">{t("permissions.view")}</th>
                                            <th align="center">{t("permissions.create")}</th>
                                            <th align="center">{t("permissions.edit")}</th>
                                            <th align="center">{t("permissions.delete")}</th>
                                            <th width="140" align="center">{t("permissions.inactive")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* pos */}
                                        <tr>
                                            <td>{t("permissions.pos")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.pos.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        pos: {
                                                            ...formData.pos,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.pos.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        pos: {
                                                            ...formData.pos,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.pos.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        pos: {
                                                            ...formData.pos,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.pos.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        pos: {
                                                            ...formData.pos,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.pos.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        pos: {
                                                            ...formData.pos,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                        {/* closingDate */}
                                        <tr>
                                            <td>{t("permissions.closingDate")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.closingDate.view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        closingDate: {
                                                            ...formData.closingDate,
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.closingDate.create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        closingDate: {
                                                            ...formData.closingDate,
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.closingDate.update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        closingDate: {
                                                            ...formData.closingDate,
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.closingDate.delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        closingDate: {
                                                            ...formData.closingDate,
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData.closingDate.inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        closingDate: {
                                                            ...formData.closingDate,
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>

                        {/* PERMISSIONS */}
                        <div className="col-md-12 mb-4">
                            <div className="content-table-permissions">
                                <table className="table-permissions">
                                    <thead className="table-header-permissions">
                                        <tr>
                                            <th width="220">{t("permissions.permissions")}</th>
                                            <th align="center">{t("permissions.view")}</th>
                                            <th align="center">{t("permissions.create")}</th>
                                            <th align="center">{t("permissions.edit")}</th>
                                            <th align="center">{t("permissions.delete")}</th>
                                            <th width="140" align="center">{t("permissions.inactive")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* permissions */}
                                        <tr>
                                            <td>{t("permissions.permissions")}</td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["configuration/permissions"].view} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "configuration/permissions": {
                                                            ...formData["configuration/permissions"],
                                                            view: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["configuration/permissions"].create} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "configuration/permissions": {
                                                            ...formData["configuration/permissions"],
                                                            create: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["configuration/permissions"].update} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "configuration/permissions": {
                                                            ...formData["configuration/permissions"],
                                                            update: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["configuration/permissions"].delete} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "configuration/permissions": {
                                                            ...formData["configuration/permissions"],
                                                            delete: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                            <td align="center" width="80">
                                                <Checkbox size="small" checked={formData["configuration/permissions"].inactive} onChange={(e)=>{
                                                    setFormData({
                                                        ...formData,
                                                        "configuration/permissions": {
                                                            ...formData["configuration/permissions"],
                                                            inactive: e.target.checked
                                                        }
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                    </div>
                </form>
            </div>
            <div className="row d-flex justify-content-end">
                <div className="d-flex justify-content-end me-5">
                    {id === undefined && <button className="btn btn-primary-yellow-1" type="submit" onClick={handleSubmitForm}>{t('submit')}</button>}
                    {id && <button className="btn btn-primary-yellow-1" type="submit" onClick={handleUpdateForm}>{t('update')}</button>}
                </div>
            </div>
        </div>
    )
}

export default BarbersPermissionsForms;