import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandlerApp from "../../../utils/handlerApp";

const TransactionsView = () =>{
    const endpoint = 'transactions'
    const {id} = useParams();
    const [t,i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();
    
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint + '/'+ id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let record = response.data[0]
                setData(record)
                setTitle('Transaction: '+ record.tx_code)

            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }  
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }, []);

    const back = ()=>{
        nav(-1);
    }

    return (
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>ID: </dt>
                        <dd>{data.tx_id}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>Code: </dt>
                        <dd>{data.tx_code}</dd>
                    </div>
                    
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.name_created}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.tx_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.tx_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.name_modified}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.tx_modified_at !== null && data.tx_modified_at !== undefined && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.tx_modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TransactionsView;