import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const BoldInputPhone = ({name, label, register, errors, onChange})=>{
    const [t,i18n] = useTranslation("global");

    const [openOptions,setOpenOptions] = useState(false);

    const [value, setValue] = useState("");
    const [country, setCountry] = useState("+57");
    const [image, setImage] = useState();

    useEffect(()=>{
        if(country === "+1"){
            setValue("+1"+value.substring(3));
        }else{
            setValue("+57"+value.substring(2));
        }
    },[country])


    useEffect(()=>{
        let img = options.filter(e=> e.value == country)
        setImage(img[0].image);
    }, [country])

    const options = [
        {
            value: "+57",
            label: "Colombia",
            image: "/assets/countries/Colombia.png"
        },
        {
            value: "+1",
            label: "Estados Unidos",
            image: "/assets/countries/Estados-Unidos.png"
        },
    ]

    return(
        <div>
            <div className="container-bold-selector">
                <div className="bold-selector">
                    <div className="bold-select-selected" onClick={()=>{setOpenOptions(!openOptions)}}>
                        <img src={image}/>
                        <div style={{paddingTop: 5}}>
                            { (!openOptions) ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                        </div>
                    </div>
                    <div className={ (openOptions) ? "bold-selector-options show" : "bold-selector-options"}>
                        {options.map( (e,index) =>{
                            return <div className="bold-selector-option" key={index} onClick={()=>{
                                setCountry(e.value);
                                setOpenOptions(!openOptions);
                            }}>
                                <img src={e.image}/>
                                <label>{e.label}</label>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <TextField label={label} variant="outlined" size="small" className="bold-input-phone"
                {...register(name,{ required: true, maxLength: 13, minLength: 12})}
                onChange={(e)=>{
                    // onChange(e.target.value, name);
                    if(e.target.value.length >= country.length){
                        setValue(e.target.value);
                    }
                }}
                error={errors!= null}
                helperText = {
                    errors?.type === 'required' ? t("errors.required") : 
                    errors?.type === 'maxLength' ? t("errors.maxLength") :
                    errors?.type === 'minLength' ? t("errors.minLength") : ""
                }
                value={value}
                />
        </div>
    )
}

export default BoldInputPhone