import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css"
import logo from "../../assets/img/logo.png";
import logoDark from "../../assets/img/logo_black.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Logout } from "../../utils/BoldLogin";
import ButtonPrimary from "../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../atoms/Buttons/ButtonOutline";
import ButtonLink from "../atoms/Buttons/ButtonLink";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const VerifyOTP = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();
    const nav = useNavigate();

    const {register, handleSubmit, formState:{errors} } = useForm();
    const [showPoliciesTerms,setShowPoliciesTerms] = useState(false);
    const [showPoliciesPrivacy,setShowPoliciesPrivacy] = useState(false);
    const [TyC,setTyC] = useState("");
    const [privacy,setPrivacy] = useState("");
    const logout = Logout();
    const [phone,setPhone] = useState("");

    // const {sendCode} = useParams();
    const location = useLocation();
    const sendCode = location.state?.sendCode;

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;

    useEffect(()=>{
        let fuser = localStorage.getItem('phone');
        setPhone(fuser);
        // localStorage.removeItem('phone');
    },[])

    const [t,i18n] = useTranslation("global");
    const [language,setLanguage] = useState("");
    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang === "" || lang === null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])

    const handleFormLogin = (data) => {
        setLoader(true);
        let body = data;
        body.fuser = phone;
        console.log(body);
        axios.post(env.urlBackend + '/verifylogin', body)
        .then(response => {
            if(response.data.success){
                localStorage.setItem('user', response.data.user);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("currentLocation","");
                localStorage.setItem("locations",JSON.stringify(response.data.locations));
                localStorage.setItem("numberInformation",btoa(btoa(response.data.numberInformation)));
                localStorage.setItem("profile", response.data.profile);
                localStorage.setItem("profiles", JSON.stringify(response.data.profiles));
                localStorage.setItem("company", response.data.company);
                localStorage.setItem("location", response.data.location);
                localStorage.setItem("showUpload", response.data.showUploadClients);

                if(response.data.userPhoto != null){
                    localStorage.setItem("userPhoto", response.data.userPhoto)
                }
                
                if(response.data.action === "ACCEPT_POLICIES"){
                    setTyC(response.data.tyc);
                    setPrivacy(response.data.privacy);
                    setShowPoliciesTerms(true);
                    setLoader(false);
                }else{
                    if (localStorage.getItem('user')){
                        let screen = ( response.data.profile <= 3 ) ? "/" : ( response.data.showUploadClients === true ) ? "/authenticate/import-customer" : "/calendar";
                        nav(screen);
                    }else{
                        nav("/authenticate");
                    }
                }
            }else{
                setLoader(false);
                setError(t(response.data.message));
                // nav("/authenticate");
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })

    }

    const resendCode = (e)=>{
        e.preventDefault();
        setLoader(true);
        let body = {
            fuser: phone
        }
        axios.post(env.urlBackend + '/login', body)
        .then(response => {
            if(response.data.success){
                setLoader(false);
            }else{
                setError(t(response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
    }

    const sendInformation = ()=>{
        setLoader(true);
        let user = atob((atob(localStorage.getItem('numberInformation'))));
        let body = {
            user,
            user_tyc_acceptance: 1,
            user_policy_acceptance: 1
        }
        axios.post(env.urlBackend+'/configuration/policy-acceptance',body)
        .then(response=>{
            if(response.data.success){
                setLoader(false);
                nav("/")
            }else{
                setError(t(response.data.message));
                setLoader(false);
                logout();
            }
        })
        .catch(error=>{
            setLoader(false);
            setError(t(error.response.data.message));
        })
        
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                        { !showPoliciesTerms && !showPoliciesPrivacy && <div>
                            <h1 className="title-login">{t("loginComponent.confirmNumber")}</h1>
                            <p className="light">{t("loginComponent.messageSentCodeVerify")}</p>
                            { sendCode && <Alert onClose={() => {}} className="mb-3" severity="success">{t("loginComponent.forwardedCode")}</Alert>}
                            <form action="/" onSubmit={handleSubmit(handleFormLogin)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextField label={t("code")} variant="outlined" size="small" autoComplete="off"
                                            {...register("fotp",{ required: true, maxLength: 6, minLength: 6})}
                                            error={errors.fuser!= null}
                                            helperText = {
                                                errors.fotp?.type === 'required' ? t("errors.required") : 
                                                errors.fotp?.type === 'maxLength' ? t("errors.maxLength") :
                                                errors.fotp?.type === 'minLength' ? t("errors.minLength") : ""}
                                            />
                                    </div>
                                </div>
                                {error &&
                                    <Alert className="mb-3" severity="error">{error}</Alert>
                                }
                                <center>
                                    <ButtonPrimary label={t("confirm")} type="submit"/>
                                    <ButtonOutline label={t("resendCode")} type="button" onClick={resendCode}/><br/><br/>
                                    <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>} onClick={()=> nav("/authenticate")} type="button"/>
                                </center>
                            </form>
                        </div> }
                        { showPoliciesTerms && !showPoliciesPrivacy &&
                            <>
                                <h1 className="title-login">{t("policiesComponent.termsAndConditions")}</h1>
                                <div className="text-box-policies">
                                    <div dangerouslySetInnerHTML={{ __html: TyC }}>
                                    </div>
                                </div>
                                <center>
                                    <ButtonPrimary label={t("accept")} type="button" onClick={()=>{setShowPoliciesPrivacy(true)}} />
                                    <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>} onClick={()=> nav("/authenticate")} type="button"/>
                                </center>
                            </>
                        }
                        { showPoliciesPrivacy && 
                            <>
                                <h1 className="title-login">{t("policiesComponent.privacyPolicies")}</h1>
                                <div className="text-box-policies">
                                    <div dangerouslySetInnerHTML={{ __html: privacy }}>
                                    </div>
                                </div>
                                <center>
                                    <ButtonPrimary label={t("accept")} type="button" onClick={()=>{sendInformation()}} />
                                    <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>} onClick={()=>{
                                        setShowPoliciesPrivacy(false);
                                        setShowPoliciesTerms(true);
                                    }} type="button"/>
                                </center>
                            </>
                        }
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('/authenticate/contact')}}>
                <span className="material-symbols-outlined">help</span>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default VerifyOTP;