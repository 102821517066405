import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";
import { TextField } from '@mui/material';
import Navbar from './Navbar';
import "./Navbar.css"


import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Notes = ({ id, notes }) => {
    const endpoint = "customer/notes";
    const [t, i18n] = useTranslation("global");

    const api = useFetch();
    const handlerApp = HandlerApp();

    const nav = useNavigate();



    const [value, setValues] = React.useState(0);
    const [data, setData] = useState([]);

    const handleChange = (event, newValue) => {
        setValues(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({
        customer_id: 5,
        customernote_text: ""
    });

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const back = () => {
        nav(-1);
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    console.log(notes);

    // save data
    const handleSubmitForm = (data) => {

        data.customer_id = id
        console.log(data);
        handleClose();
        handlerApp.setLoader(true);
        api.post(endpoint, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    window.location.reload();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    return (



        <div className="app">
            <div className="container bold-container-content" style={{ background: "#ffffff" }}>
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Notes" {...a11yProps(0)} />
                                    <Tab label="Meditation" {...a11yProps(1)} />
                                    <Tab label="Allergies" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {notes?.map((note, index) => (
                                        <React.Fragment key={index}>

                                            <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                    {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                                                    <Avatar sx={{ bgcolor: "#254c99" }}>{note.created_by.slice(0, 2).toUpperCase()}</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={note.created_by}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {note.customernote_text}
                                                            </Typography>
                                                            {` — ${handlerApp.getDate(note.customernote_created_at)}`}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </React.Fragment>
                                    ))}
                                </List>
                                <div className="col-md-6">
                                    <button className="btn btn-primary-yellow-1" type="button" onClick={handleClickOpen} >
                                        {t("notes.create")}
                                    </button>
                                </div>

                                <React.Fragment>
                                    <BootstrapDialog
                                        onClose={handleClose}
                                        aria-labelledby="customized-dialog-title"
                                        open={open}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title" style={{ color: "#000000" }}>
                                            {t("notes.create")}
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            style={{ color: "#000000" }}
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers>
                                            <div className="row">
                                                <div className="col-md-12 mb-2">
                                                    <TextField className="text-view" label={t("notes.title")} variant="outlined" size="small"
                                                        {...register("customernote_text", { required: true, maxLength: 40, })}
                                                        value={formData.customernote_text}
                                                        onChange={handleForm}
                                                        multiline
                                                        rows={5}
                                                        error={errors.customernote_text != null}
                                                        helperText={errors.customernote_text?.type === 'required' ? t("errors.required") : ''}
                                                    />
                                                </div>

                                                <div className="col-md-12 mb-2" >
                                                    <button className="btn btn-primary-yellow-1" style={{ width: '100%' }} type="button" onClick={handleSubmit(handleSubmitForm)} >
                                                        {t("notes.create")}
                                                    </button>
                                                </div>
                                            </div>

                                        </DialogContent>
                                    </BootstrapDialog>
                                </React.Fragment>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                Meditation
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                Allergies
                            </CustomTabPanel>

                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notes;