import HandlerApp from "../../../utils/handlerApp";
import { useEffect, useState, useContext } from "react";
import { Checkbox } from '@mui/material';
import { useFetch } from "../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import NavbarEditServices from "../ServicesLocations/NavbarEditServices";
import CardSimpleWithImageServices from "../ServicesLocations/CardSimpleWithImageServices";



const TeamServicesScreen = ({ route }) => {
  const { id } = useParams();
  const endpoint = "services/services-barber";
  const parent = "Services";
  const [t] = useTranslation("global");
  const [data, setData] = useState([]);
  const handlerApp = HandlerApp();
  const [permissions, setPermissions] = useState([]);
  const [menus, setMenus] = useState([]);
  const { app } = useContext(AppContext);
  const api = useFetch();
  const title = t('servicesLocation.updateServicesLocation');
  const nav = useNavigate();


  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm()
  // const [formData, setFormData] = useState([]);
  const [formData, setFormData] = useState(
    {
      servicebarber_price: "",
      servicebarber_new_client_price: "",
      servicebarber_duration: "",
      servicebarber_fee: "",
      servicebarber_fee_value: "",
      servicebarber_id: "",
      servicelocation_id: "",
      id: "",
      user_id: "",
      servicebarber_active: ""
    }
  );

  const handleCancel = (e) => {
    e.preventDefault();
    nav(-1);
  }

  useEffect(() => {
    setMenus(app.menus[parent]);
    setPermissions(app.permissions[endpoint])
  }, [app])

  useEffect(() => {
    sendRequest();
  }, [])


  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    // Actualizar el estado de los checkboxes en cada CardSimpleWithImageServices
    setData((prevData) =>
        prevData.map((item) => ({ ...item, checkboxActive: !selectAllChecked }))
    );
};

  const handleCheckboxChangeInCard = (id, isChecked) => {
    // Actualizar el estado de los checkboxes en el componente principal
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, checkboxActive: isChecked } : item
      )
    );
  };


  const sendRequest = () => {
    handlerApp.setLoader(true);
    api.get(endpoint + "/" + id, {
      action: "getAllBarberForServices"
    })
      .then(response => {
        if (response.success) {
          let d = response.data;
          setData(d);
          handleDependencies(d);
          handlerApp.setLoader(false);
        } else {
          handlerApp.handlerResponse(response);
          handlerApp.setLoader(false);
        }
      })
      .catch(error => {
        handlerApp.showError(t(error.message));
        handlerApp.setLoader(false);
      })
  }


  const handleDependencies = (data) => {
    const newData = data.map((item, index) => {
      let d = filterData(item, formData);
      return d
    })

    setFormData(newData);
  }

  const filterData = (data, formData) => {

    let finalData = {};
    for (let key in formData) {
      finalData[key] = data[key];
      setValue(key, data[key]);
    }
    return finalData;
  }

  const handleUpdateForm = () => {
    handlerApp.setLoader(true);
    api.put("services/services-barber-location/"+ id, formData)
      .then(response => {
        if (response.success) {
          handlerApp.showOk(response);
          handlerApp.setLoader(false);
          nav("/" + endpoint);
        } else {
          handlerApp.handlerResponse(response);
          handlerApp.setLoader(false);
        }
      })
      .catch(error => {
        handlerApp.showError(t(error.message));
        handlerApp.setLoader(false);
      })
  }



  return (
    <div className="app container">
      <div className="d-flex justify-content-between">
        <section className="section-title-page">
          <h3 className="title-page ps-3">{t(title)}</h3>
        </section>
        <div className="d-flex">
          <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>Cancel</p>
        </div>
      </div>
      <NavbarEditServices id={id} user_id={formData.user_id} />
      <div>
      <Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />
        <span>Select all</span>
        <div>
          {data.map((item, index) => (
            <CardSimpleWithImageServices
              key={index.toString()}
              item={item}
              index={index}
              onCheckboxChange={handleCheckboxChangeInCard}
              selectAllChecked={selectAllChecked}
              setData={setData}
              formData={formData}
              setFormData={setFormData}
            />
          ))}
        </div>
      </div>
      <div className="row d-flex justify-content-end">
        <div className="d-flex justify-content-end me-5">
          {id && <button className="btn btn-primary-yellow-1" onClick={handleUpdateForm}>{t('update')}</button>}
        </div>
      </div>
    </div>
  );
};

export default TeamServicesScreen;
