import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Logout} from "../../utils/BoldLogin";
import { NavLink } from "react-router-dom";
import HandlerApp from "../../utils/handlerApp";


const MainHeader = ({toggleAside, locations, handleLocation, locationLabel}) => {
    
    const logout = Logout();
    const [t, i18n] = useTranslation("global");
    const [language,setLanguage] = useState("");
    const nav = useNavigate();
    const handlerApp = HandlerApp()
    
    useEffect(()=>{
        if(localStorage.getItem("language")){
            i18n.changeLanguage(localStorage.getItem("language"))
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])

    const handleLogout = () => {
        logout.closeSession();
    }
    const handleAccount =()=>{
        let user = handlerApp.getUser();
        nav('/configuration/profile/'+user);
    }
    const handleDarkMode = ()=>{
        let html = document.querySelector("html");
        if(html.classList.contains("dark")){
            localStorage.setItem("theme", "light");
        }else{
            localStorage.setItem("theme", "dark");
        }
        html.classList.toggle("dark");
    }
    return (
        <div className="container-menu d-flex align-items-center justify-content-between">
            <div className="d-flex">
                <span onClick={toggleAside} className="material-symbols-outlined" role="button">menu</span>
                <div className="title-dashboard">{t('home')}</div>
            </div>
            <div className="actions-dashboard">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle btn-location" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="location-label">{locationLabel}</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <button className="dropdown-item" onClick={()=>{handleLocation("")}}>Global</button>
                        </li>
                        {
                            locations.map(e=>{
                                return (<li key={e.location_id}>
                                    <button className="dropdown-item" onClick={()=>{handleLocation(e.location_id)}}>{e.location_name}</button>
                                </li>)
                            })
                        }
                    </ul>
                </div>
                <button className="btn-action-dash">
                    <span className="material-symbols-outlined">notifications</span>
                </button>
                {/* <button className="btn-action-dash"><span className="material-symbols-outlined">settings</span></button> */}
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button>
                        </li>
                        <li>
                            <button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button>
                        </li>
                    </ul>
                </div>
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">account_circle</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <NavLink to="/">
                                <button  className="dropdown-item">{t('home')}</button>
                            </NavLink>
                            <button className="dropdown-item dropdown-item-icon-left" onClick={handleDarkMode}>
                                <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.7188 13.875C15.9141 14.2266 15.9141 14.6953 15.6406 15.0078C13.9609 17.0781 11.5391 18.25 8.88281 18.25C4.11719 18.25 0.25 14.3438 0.25 9.5C0.25 4.69531 4.11719 0.75 8.88281 0.75C9.35156 0.75 10.0547 0.828125 10.4844 0.90625C10.9141 0.984375 11.2266 1.33594 11.3047 1.72656C11.3438 2.15625 11.1484 2.58594 10.7969 2.78125C9.03906 3.79688 7.90625 5.71094 7.90625 7.78125C7.90625 9.5 8.6875 11.1016 10.0156 12.2344C11.3047 13.2891 13.0234 13.7188 14.6641 13.4062C15.0938 13.3281 15.4844 13.5234 15.7188 13.875ZM8.88281 17C10.9141 17 12.8281 16.1797 14.2344 14.7344C12.4375 14.8906 10.6406 14.3438 9.19531 13.1719C7.59375 11.8438 6.65625 9.89062 6.65625 7.78125C6.65625 5.47656 7.78906 3.36719 9.58594 2.07812C9.35156 2.03906 9.07812 2 8.88281 2C4.82031 2 1.5 5.39844 1.5 9.5C1.5 13.6406 4.82031 17 8.88281 17Z" fill="#37465A"/>
                                </svg>
                                <span>{t("darkMode")}</span>
                            </button>
                            <button className="dropdown-item" onClick={handleAccount}>{t("myAccount")}</button>
                            <button className="dropdown-item" onClick={handleLogout}>{t("logout")}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default MainHeader;