import "./ViewField.css";

const ViewField = ({label, value})=>{
    return(
        <div className="field-view">
            <span className="label-field">{label}</span>
            <span className="value-field">{value}</span>
        </div>
    )
}

export default ViewField;