import './DragAndDrop.css';
import Stack from '@mui/material/Stack';
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";

const DragAndDrop = ({ data, setData, keyString, name, image, keyOrder }) => {

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active.id !== over.id) {
      setData((item) => {
        const oldIndex = item.findIndex((row) => row.id === active.id);
        const newIndex = item.findIndex((row) => row.id === over.id);
        reOrderData(arrayMove(item, oldIndex, newIndex));
        return arrayMove(item, oldIndex, newIndex);
      });
    }
  };

  const reOrderData = (value) => {
    const updatedData = value.map((item, index) => {
      const updatedStatus = item[keyOrder] = index + 1;
      return { ...item, [keyOrder]: updatedStatus };
    });
    setData(updatedData);
  }
  const DragComponent = ({ item }) => {

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item[keyString] });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <section style={style} className="cardStyle cardColor my-2">
        <Stack direction="row" spacing={2} style={{ alignItems: "center", padding: 10 }} ref={setNodeRef} {...attributes} {...listeners}>
          <i className="fa-solid fa-bars"></i>
          {
            item[image] === null ?
              <img className="image" src={imageNotFound} alt="Not Found" /> :
              <img className="image" src={item[image]} alt={item[name]} />
          }
          <section className='textContainerStyle'>
            <p className="textCard colorText">{item[name]}</p>
          </section>
        </Stack>
      </section>);
  }

  return (
    <div className="flex justify-center items-center">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={data}
            strategy={verticalListSortingStrategy}
          >
            {data.map((item) => (
              <DragComponent key={item.id} item={item} />
            ))}
          </SortableContext>
        </DndContext>
    </div>
  );
}

export default DragAndDrop;
