import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import notFound from './../../../assets/img/image-not-found.webp';
import Header from "../../molecules/Header";

const Services = () => {
    const endpoint = "services";
    const parent = "Services";
    const [t] = useTranslation("global");
    const title = t('servicesComponent.services');
    const description = t("servicesComponent.serviceDescription");
    const prefix = "service";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        categories: [],
        taxes: []
    });
    
    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    // filtros
    
    // const [category,setCategory] = useState([]);
    // const [locations, setLocations] = useState([]);
    // useEffect(()=>{
    //     setFilter
    // },[category,locations])


    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id,action)=>{
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        {
            id: 'service_name',
            name: t('servicesComponent.service'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.service_image != null ) ? 
                            <img className="barber-image-inside" src={row.service_image} alt={row.service_name}/>
                            : <img className="barber-image-inside" src={notFound} alt="Not Found"/>}
                        </div>
                        <p>{row.service_name}</p>
                    </div>
                )
            },
            sortable: true
        },
        {
            id: 'servicecategory_name',
            name: t('categoryComponent.category'),
            selector: row => row.servicecategory_name,
            sortable: true
        },
        {
            id: 'service_duration',
            name: t('servicesComponent.duration'),
            selector: row => row.service_duration,
            sortable: true,
            width: "110px"
        },
        {
            id: 'service_price',
            name: t('servicesComponent.price'),
            selector: row => row.service_price,
            sortable: true,
            width: "110px"
        },
        {
            id: 'service_active',
            name: t('status'),
            cell: (row)=> {  return handlerApp.ChipActive(row.service_active) },
            selector: row => row.service_active === 1?  "Active" : "Inactive",
            sortable: true,
            width: "120px"
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions2(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'filter',
            service_combo: '0'
        }
        api.get(endpoint, body)
        .then(response => {
            if(response.success){
                setFilters({...filters,
                    categories: getFiltersFromData(response.data,'servicecategory_name'),
                    taxes: getFiltersFromData(response.data,'taxgroup_name')
                });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const getFiltersFromData = (data,key)=>{
        let filter = [];
        data.forEach(element => {
            if(!filter.includes(element[key]) ){
                filter.push(element[key]);
            }
        });
        let finalFilter = [];
        filter.map(e => {
            if(e != null){
                finalFilter.push({code: e, name: e})
            }
        });
        return finalFilter;
    }

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.service_id && item.service_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.service_name.toLowerCase().includes(filterText.toLowerCase())
            || item.servicecategory_name.toLowerCase().includes(filterText.toLowerCase())
            || item.service_price && item.service_price.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.service_duration && item.service_duration.toString().toLowerCase().includes(filterText.toLowerCase())
            // || item.taxgroup_name?.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            <Header endpoint={endpoint} parent={parent} title={title} description={description}/>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("servicesComponent.serviceslist")}</h4>
                <div className="row filter-elements">
                    <div className="col-md-3">
                        <BoldSelect title={t("categoryComponent.category")} name="category" onChange={e => setFilterText(e.target.value)} value="" options={filters.categories}></BoldSelect>
                    </div>
                    {/* TODO: INCLUIR EL FILTRO SOLO PARA LOS SERVICE LOCATION Y CUANDO EL NEGOCIO SOLO TIENE UNA SUCURSAL */}
                    {/* <div className="col-md-3">
                        <BoldSelect title={t("taxesComponent.taxgroup_name")} name="level" onChange={e => setFilterText(e.target.value)} value="" options={filters.taxes}></BoldSelect>
                    </div> */}
                    <div className=" col-md-6 offset-md-3">
                        <div className="input-icon input-search serch-container d-flex flex-row ">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {/* {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>} */}
                            <button className="btn btn-primary-yellow-1" onClick={(e)=>{e.preventDefault(); }}>{t("search")} </button>
                            {/* <span className="material-symbols-outlined">search</span> */}
                        </div>       
                    </div>
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                persistTableHead
            />
        </div>
    )
}

export default Services;