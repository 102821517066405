
import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";

const CompaniesForms = () => {
    const endpoint = "companies";
    const parent = "Company";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('companyComponent.createCompany');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();
    const [images, setImages] = useState([]);

    // parametros
    const [identificatioType, setIdentificationType] = useState([]);

    // formulario
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        company_identification_type: "",
        company_identification: "",
        company_name: "",
        company_short_name: "",
        company_logo: "",
        company_phone: "",
        company_ext: "",
        company_barbers: ""
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);


    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    console.log("--------------->");
                    console.log(response.data);
                    setIdentificationType(handlerApp.getResultFromObject(response.data, "company_identification_type"));
                    if (id !== undefined) {
                        setTitle('companyComponent.updateCompany')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }, [])

    const handleImages = (e) => {
        setImages(e)
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        console.log(data);
        handlerApp.setLoader(true);
        api.post(endpoint, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }


    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <TextField label={t("name")} variant="outlined" size="small"
                                {...register("company_name", { required: true, maxLength: 40 })}
                                value={formData.company_name}
                                onChange={handleForm}
                                error={errors.company_name != null}
                                helperText={
                                    errors.company_name?.type === 'required' ? t("errors.required") :
                                        errors.company_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="company_short_name" label={t('companyComponent.DBA')} value={formData.company_short_name} variant="outlined" size="small" onChange={handleForm} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('companyComponent.identificationType')}
                                value={formData.company_identification_type}
                                name="company_identification_type"
                                onChange={handleForm}
                                options={identificatioType}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("companyComponent.identification")} variant="outlined" size="small"
                                {...register("company_identification", { required: true, maxLength: 15 })}
                                value={formData.company_identification}
                                onChange={handleForm}
                                error={errors.company_identification != null}
                                helperText={
                                    errors.company_identification?.type === 'required' ? t("errors.required") :
                                        errors.company_identification?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="company_website" label={t('website')} value={formData.company_website} variant="outlined" size="small" onChange={handleForm} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="company_barbers" label={t('companyComponent.companyBarbers')} value={formData.company_barbers} variant="outlined" size="small" onChange={handleForm} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="company_ext" label={t('ext')} value={formData.company_ext} variant="outlined" size="small" onChange={handleForm} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("phone")} variant="outlined" size="small"
                                {...register("company_phone", { required: true, maxLength: 10 })}
                                value={formData.company_phone}
                                onChange={handleForm}
                                error={errors.company_phone != null}
                                helperText={
                                    errors.company_phone?.type === 'required' ? t("errors.required") :
                                        errors.company_phone?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <BoldFilesUpload returnImages={handleImages} multiple={false} value={formData.company_logo} />
                        </div>






                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CompaniesForms;