import Navbar from "../Navbar";
import HandlerApp from "../../../../utils/handlerApp";
import { Link } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { Checkbox, TextField } from '@mui/material';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";



const NotificacionesCustomerView = () => {
    const endpoint = "/customer/show/notification";
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const [formData, setFormData] = useState({
        customernotification_rescheduling_sms: false,
        customernotification_rescheduling_email: false,
        customernotification_cancel_sms: false,
        customernotification_cancel_email: false,
        customernotification_appointment_sms: false,
        customernotification_appointment_email: false,
        customernotification_barber_reminder_sms: false,
        customernotification_barber_reminder_email: false,
        customernotification_first_customer_reminder_sms: false,
        customernotification_first_customer_reminder_email: false,
        customernotification_second_customer_reminder_sms: false,
        customernotification_second_customer_reminder_email: false,
        customernotification_late_arrival_sms: false,
        customernotification_late_arrival_email: false,
        customernotification_missed_appoinment_msm: false,
        customernotification_missed_appoinment_email: false,
        customernotification_active: false,
        customernotification_created_by: false,
        customernotification_created_at: false,
        customernotification_modified_by: false,
        customernotification_modified_at: false,
        customernotification_delete: false,
    });

    const { id } = useParams();
    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                    setTitle(data.user_name);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    const back = () => {
        nav(-1);
    };

    const handleChange = ()=>{
        
    }

    return (

        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">{title}</h3>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container bold-container-content">
                <Navbar customer_id={data.customer_id}/>
                <div className="row np-row">
                    {/* Columna izquierda */}
                    <div className="col-md-6 mb-2 prueba">
                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.rescheduling')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_rescheduling_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_rescheduling_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.cancellations')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_cancel_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_cancel_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.appointmentAvailabilitySuggestions')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_appointment_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_appointment_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.reminderForBarber')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_barber_reminder_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_barber_reminder_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_barber_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_barber_reminder} />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.firstClientReminder')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_first_customer_reminder_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_first_customer_reminder_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_first_customer_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_first_customer_reminder} />
                            </div>
                        </div>
                    </div>

                    {/* Columna derecha */}
                    <div className="col-md-6 mb-2 prueba">
                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.secondClientReminder')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_second_customer_reminder_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_second_customer_reminder_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_second_customer_reminder_sms : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_second_customer_reminder_sms} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_second_customer_reminder_sms : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_second_customer_reminder_sms} />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.lateArrival')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_late_arrival_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_late_arrival_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title">{t('notifications.missedAppointment')}</h4>
                            <div className="checkbox-group check-text">
                                <p className="ms-1 opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_missed_appoinment_email}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium"
                                        checked={formData.customernotification_missed_appoinment_sms}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="ms-3 check-title m-3">{t('notifications.readyBarber')}</h4>
                            <div class="d-flex col-9 justify-content-between ms-1 check-text">
                                <p className="ms-1 opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-section-view row">
                <div className="col-md-6">
                    <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                </div>
                <div className="col-md-6">
                    <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                </div>
                <div className="col-md-6">
                    {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                </div>
                <div className="col-md-6">
                    {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                </div>
            </div>
        </div>
    );
}

export default NotificacionesCustomerView;