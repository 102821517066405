import { Box, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";
import { LocationContext } from "../../../context/LocationContext";
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";


const ResourcesForm = ({open, setOpen, id, request, setLevelId}) => {

    const endpoint = "services/resources";
    const [t, i18n] = useTranslation("global");

    const [title, setTitle] = useState('resourcesComponent.create');
    const api = useFetch();
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [formData, setFormData] = useState({
        location_id: "",
        resource_name: "",
        resource_description: "",
        resource_quantity: "",
    });


    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    // location
    const { location } = useContext(LocationContext);
    const validateLocation = true;
    useEffect(() => {
        if (app.hasOwnProperty('location')) {
            if (validateLocation) {   // valida si esta activa la verificacion del sucursal.
                if (location === "") { // no hay sucursal seleccionada
                    handlerApp.showAlertLocation(); // muestra una notificacion al usuario solicitando que por favor seleccione una sucursal.
                    setData([]);
                } else {
                    handlerApp.setLoader(true);
                    if (id !== undefined && id !== null) {
                        setTitle('resourcesComponent.update')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                }
            } else {
                sendRequest();  // agrego xq esta pantalla depende de las sucursales en el estado.
            }
        }
    }, [location])



    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }

    useEffect(() => {
        handlerApp.setLoader(true);
        if (id !== undefined && id !== null) {
            sendRequest();
        } else {
            handlerApp.setLoader(false);
        }
    }, [id]);



    // useEffect(()=>{
    //     handlerApp.setLoader(true);
    //     api.get(endpoint,{
    //         location
    //     })
    //     .then(response =>{
    //         if(response.success){
    //             setData(response.data)
    //             if(id !== undefined){
    //                 setTitle(t('resourcesComponent.update'));
    //                 sendRequest();
    //             }else{
    //                 handlerApp.setLoader(false);
    //             }
    //         }else{
    //             handlerApp.setLoader(false);
    //         }
    //     })
    //     .catch(error=>{
    //         handlerApp.setLoader(false);
    //         handlerApp.showError(t(error.message));
    //     })
    // },[])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...data,
            location_id: location
        }
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    request();
                    setOpen(false);
                    handlerApp.showOk(response);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...data,
            location_id: location
        }
        api.put(endpoint + "/" + id, data)
            .then(response => {
                if (response.success) {
                    request();
                    setOpen(false);
                    handlerApp.showOk(response);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        height: 400,
        bgcolor: 'background.paper',
        border: '1px solid #fff',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        
      };
      

    // const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setLevelId(null);
        setOpen(false);
    }

    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className= "titleModal" variant="h6" component="h2">
          {t(title)}
          </Typography>
          <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#000000" }}
                sx={{
                    position: 'absolute',
                    right: 25,
                    top: 25,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit((id === null) ? handleSubmitForm : handleUpdateForm)}>
          <div className="row">
          <div className="col-md-6 mb-2">
                            <TextField label={t('resourcesComponent.resource')} variant="outlined" size="small"
                                {...register("resource_name", { required: true, maxLength: 60 })}
                                value={formData.resource_name}
                                onChange={handleForm}
                                error={errors.resource_name != null}
                                helperText={
                                    errors.resource_name?.type === 'required' ? t("errors.required") :
                                        errors.resource_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                        <TextField label={t('resourcesComponent.quantity')} variant="outlined" size="small"
                                {...register("resource_quantity", { required: true, maxLength: 60 })}
                                value={formData.resource_quantity}
                                onChange={handleForm}
                                error={errors.resource_quantity != null}
                                helperText={
                                    errors.resource_quantity?.type === 'required' ? t("errors.required") :
                                        errors.resource_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-12 mt-3 mb-2">
                        <TextField label={t('description')} variant="outlined" size="small"
                                {...register("resource_description", { required: true, maxLength: 60 })}
                                multiline rows={3}
                                value={formData.resource_description}
                                onChange={handleForm}
                                error={errors.resource_description != null}
                                helperText={
                                    errors.resource_description?.type === 'required' ? t("errors.required") :
                                        errors.resource_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>

                            </div>
                            <div className="divisorResource"></div>
                                <div className="col-md-12 pt-3 mb-2" >
                                    {id === null && <button className="btn btn-primary-yellow-1" type="submit" style={{ width: '100%' }}>
                                    {t('submit')}
                                    </button>}
                                    {id && <button className="btn btn-primary-yellow-1" type="submit" style={{ width: '100%' }}>{t('update')}</button>}
                                </div>
                            </form>
        </Box>
      </Modal>
    )
}

export default ResourcesForm;