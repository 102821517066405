import React, { useEffect, useState } from 'react';
import { NavLink} from "react-router-dom";
import { useTranslation } from 'react-i18next';


const NavbarEditServices = (props) => {
    const [t] = useTranslation('global');

    const [data, setData] = useState({
        id:         props?.id,
        user_id:    props?.user_id
    });
    
    useEffect(()=>{
        // console.log(props);
        // console.log(data.id,"id");
        if(data.id !== undefined && data.id !== ''){
            // console.log(1)
            localStorage.setItem('navbar-id',data.id);
        }else{
            // console.log(2)
            let id = localStorage.getItem('navbar-id');
            setData({...data, id: id});
            // console.log(id,"id");
        }

        if(data.user_id !== undefined && data.user_id !== ''){
            // console.log(3)
            localStorage.setItem('navbar-user_id',data.user_id);
        }else{
            // console.log(4)
            let user_id = localStorage.getItem('navbar-user_id');
            setData({...data, user_id: user_id});
            // console.log(user_id,"user_id");
        }

    },[props])

    console.log(data)

    return (
        <nav className="child-menu">
            <NavLink to={`/services/services-locations/edit/${data.id}`} activeclassname="active" end>{t('servicesBarbers.general')}</NavLink>
            <NavLink to={`/services/team/edit/${data.id}`} activeclassname="active" end>{t('team.team')}</NavLink>
        </nav>
    );
};



export default NavbarEditServices;