import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import notFound from "../../../assets/img/image-not-found.webp";
import ListServicesCategories from "./ListServicesCategories";
import { LocationContext } from "../../../context/LocationContext";

const ServicesCategories = () => {
    const endpoint = "services/categories";
    const parent = "Services";
    const [t] = useTranslation("global");
    const title = t('servicesCategorie.servicesCategory');
    const prefix = "servicecategory";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const { app } = useContext(AppContext);
    const [filterText, setFilterText] = useState('');
    const [filters, setFilters] = useState({
        location: [],
        type: []
    });

    const { location } = useContext(LocationContext);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        if (location === "") {
            if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                handlerApp.showAlertLocation();
                setData([]);
            }
        } else {
            sendRequest();
        }
    }, [location])

    useEffect(() => {
        handleFilter();
    }, [filterText])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }


    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
            .then(response => {
                if (response.success) {
                    setFilters({
                        ...filters,
                        type: handlerApp.getFiltersFromData(response.data, 'servicecategory_name'),
                        location: handlerApp.getFiltersFromData(response.data, 'location_name')
                    });
                    setData(response.data);
                    setDataFilter(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }



    
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.servicecategory_id && item.servicecategory_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.servicecategory_name.toLowerCase().includes(filterText.toLowerCase())
            || item.servicecategory_name_spanish.toLowerCase().includes(filterText.toLowerCase())
            || item.location_name.toLowerCase().includes(filterText.toLowerCase())
            || item.servicecategory_num_products?.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleCheckboxChangeInCard = (id, isChecked) => {
        // Actualizar el estado de los checkboxes en el componente principal
        setData((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, checkboxActive: isChecked } : item
            )
        );
    };

    const handleFilterDataLocation = (value) => {

        const filteredData = data.filter(item => {
            return item.location_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const handleFilterDataCompany = (value) => {
     
        const filteredData = data.filter(item => {
            return item.servicecategory_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const handleFilter = (value) => {
     
        const filteredData = data.filter(item => {
            // Filtrar por servicecategory_name o cualquier otro campo que desees
            return item.servicecategory_name.toLowerCase().includes(filterText.toLowerCase());
          });
        setDataFilter(filteredData);
    }

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">En esta sección podrás tener todo el control de tus productos.</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions()}
                </div>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>Lista de </h4>
                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title={t("locationComponent.location")} name="location_name" onChange={e => handleFilterDataLocation(e.target.value)} value="" options={filters.location}></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title={t("servicesCategorie.nameEnglish")} name="servicecategory_name" onChange={e => handleFilterDataCompany(e.target.value)} value="" options={filters.type}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText("") }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                        <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>


                </div>
            </section>

            <div>
                {dataFilter.map((item, index) => (
                    <ListServicesCategories
                        key={index.toString()}
                        item={item}
                        onCheckboxChange={handleCheckboxChangeInCard}
                        selectAllChecked={selectAllChecked}
                        setData={setData}
                        deleteRow={deleteRow}
                        data={data}
                    />
                ))}
            </div>

            {/* <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                persistTableHead
            /> */}
        </div>
    )
}

export default ServicesCategories;