import { useEffect, useState } from "react";

const SwitchObject = ({label, id, name, value, onChange}) =>{
    const [val, setVal] = useState(value);

    useEffect(()=>{
        setVal((value === 1) ? true : false);
    },[value])

    const handleCheck = (e)=>{
        setVal(e.target.checked);
        onChange(e);
    }

    return(
        <div className="contai-checkbox">
            <input type="checkbox" id={id} name={name} className="mi-checkbox" checked={val} onChange={handleCheck} />
            <label htmlFor={id} className="custom-checkbox"></label><p className="flex justify-content-center">{label}</p>
        </div>
    )
}
export default SwitchObject;