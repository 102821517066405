import TableCustomers from "./TableCustomers";
import HandlerApp from "../../../../utils/handlerApp";
import HeaderScreen from "../../HeaderScreen/HeaderScreen";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import HeaderEditScreen from "../../HeaderScreen/HeaderEditScreen";

const NewCustomersForm = ()=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const endpoint      = "customer";
    const parent        = "Customers";
    const title         = t('customers.createCustomer');
    const description   = t('customers.descriptionScreen');
    
    const actions = [
        {name: t("customers.action.create"), endpoint: `/${endpoint}/create`, icon: "fa-user-plus"},
        {name: t("customers.action.importClients"), endpoint: `/${endpoint}/import`, icon: "fa-arrow-up-to-line"},
        {name: t("customers.action.exportClients"), endpoint: `/${endpoint}/exports`, icon: "fa-arrow-down-to-line"}
    ]

    const {app}                             = useContext(AppContext);
    const [data, setData]                   = useState([]);
    const [permissions, setPermissions]     = useState([]);

    useEffect(() => {
        // setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            console.log(response);
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    

    return(
        <div className="app container">
            <HeaderEditScreen title={title} description={description} actions={actions}/>
        </div>
    )
}

export default NewCustomersForm;