import "./CardInfo.css";

const CardInfo = ({title, value})=>{
    return(
        <div className="d-flex bold-card text-center bold-card">
            <p className="title-card">{title}</p>
            <p className="value-card">{value}</p>
        </div>
    )
}

export default CardInfo;