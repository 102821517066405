

const ButtonLinkInline = ({label, onClick, type="submit"})=>{
    return(
        <button 
            className="btn buttonLink btn-inline" 
            type={type === "submit" ? "submit" : "button"} 
            onClick={(onClick !== undefined) ? onClick : null}
        >
            {label}
        </button>
    )
}

export default ButtonLinkInline;