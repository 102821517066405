import { useTranslation } from "react-i18next";
import HeaderScreen from "../../HeaderScreen/HeaderScreen";
import { useFetch } from "../../../../hooks/useFecth";
import HandlerApp from "../../../../utils/handlerApp";
import TableLocations from "./TableLocations";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { NavLink } from "react-router-dom";



const NewLocations = ()=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const endpoint      = "companies/locations";
    const parent        = "Company";
    const title         = t('locationComponent.locations');
    const description   = t('locationComponent.locationDescription');

    const actions = [
        {name: t("locationComponent.createLocation"), endpoint: `/${endpoint}/create`, icon: "fa-plus"}
    ];

    const {app}                             = useContext(AppContext);
    const [data, setData]                   = useState([]);
    const [permissions, setPermissions]     = useState([]);
    const [menus,setMenus]                  = useState([]);
    const [filters,setFilters]              = useState({country: [],company: []});

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setFilters({...filters,
                    company: handlerApp.getFiltersFromData(response.data,'company_name'),
                    country: handlerApp.getFiltersFromData(response.data,'country_name')
                });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <HeaderScreen title={title} description={description} actions={actions}/>
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            <TableLocations data={data} setData={setData} endpoint={endpoint} permissions={permissions} prefix={"location"} setRefresh={sendRequest}/>
        </div>
    )
}

export default NewLocations;