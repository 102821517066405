import { useFetch } from "../hooks/useFecth";
import HandlerApp from "./handlerApp";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 */
const HandlerCountriesStatesCities = () => {
    const endpoint              = "general"
    const api                   = useFetch();
    const handlerApp            = HandlerApp();

    /**
     * @author Daniel Bolivar
     * @version 1.0.0
     * @intern setea el loader de la aplicacion
     */
    const setLoader = (bool)=>{
        handlerApp.setLoader(bool);
    }

    const getStates = (country)=>{
        return new Promise( (resolve, reject)=>{
            if (country !== undefined && country !== "") {
                setLoader(true);
                api.get(endpoint+"/get-states", {
                    country: country
                })
                .then(response => {
                    if (response.success) {
                        setLoader(false);
                        resolve(response.data);
                    } else {
                        setLoader(false);
                        handlerApp.handlerResponse(response)
                        resolve([]);
                    }
                })
                .catch(error => {
                    handlerApp.showError(error.message);
                    setLoader(false);
                    resolve([]);
                })
            }
        });
    }

    const getCities = (country, state)=>{
        return new Promise( (resolve, reject)=>{
            if (state !== undefined && state !== "") {
                setLoader(true);
                api.get(endpoint+"/get-cities", {
                    country: country,
                    state: state
                })
                .then(response => {
                    if (response.success) {
                        setLoader(false);
                        resolve(response.data);
                    } else {
                        setLoader(false);
                        handlerApp.handlerResponse(response)
                        resolve([]);
                    }
                })
                .catch(error => {
                    handlerApp.showError(error.message);
                    setLoader(false);
                    resolve([]);
                })
            }
        });
    }

    return {
        getStates,
        getCities
    }
}

export default HandlerCountriesStatesCities;