
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Step, StepButton, StepContent, StepLabel, Stepper, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import BoldMultiplesSelect from "../../../utils/BoldMultiplesSelects";
import { useTranslation } from "react-i18next";

// import simpleImg from "../../../assets/img/burger.jpg";
// import combosImg from "../../../assets/img/combos.jpg";
// import dessertImg from "../../../assets/img/dessert.jpg";
// import pizzaImg from "../../../assets/img/pizza.jpg";
// import drinkImg from "../../../assets/img/drink.jpg";
// import modalSimple from "../../../assets/img/modal-simple.jpg";
// import modalSq from "../../../assets/img/modal-sq.jpg";
// import modalCustom from "../../../assets/img/modal-custom.jpg";
// import pizzaSilueta from "../../../assets/img/pizza-silueta.jpg";


const ProductsForms = () => {
    const endpoint = "inventory/products";
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState('productComponent.createProduct');
    const api = useFetch();
    const nav = useNavigate();
    const { id } = useParams();
    const handlerApp = HandlerApp();

    // Permisos
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])


    // Data
    const [formData, setFormData] = useState({
        product_name: "",
        product_name_spanish: "",
        product_group: "",
        product_sku: "",
        product_price: "",
        product_cost: "",
        product_currency: "",
        product_link: null,
        product_description: "",
        product_description_spanish: "",
        product_short_description: "",
        product_short_description_spanish: "",
        product_slug: "",
        product_not_available: false,
        product_details: [],
        product_view: "",
        product_drink: false,
        drinks_ids: [],
        product_combo: false,
        combos_ids: [],
        product_pizza: false,
        productpizza_id: null,
        product_dessert: false,
        ingredients: [],
        extras: [],
        // categories:                         [],
        category_id: null,
        subcategory_id: null,
        images: []
    });

    // const [checked,setChecked] = useState([])
    // categories
    const [categoriesList, setCategoriesList] = useState([]);
    const [category, setCategory] = useState(null);
    // subcategories
    const [subcategoriesList, setSubcategoriesList] = useState([]);
    const [subcategory, setSubcategory] = useState("");
    //items
    const [itemList, setItemList] = useState([]);
    // const [item, setItem] = useState([]);
    // combos
    const [comboList, setComboList] = useState([]);
    // drinks
    const [drinkList, setDrinkList] = useState([]);
    // pizzas configuration
    const [pizzasConfiguration, setPizzasConfiguration] = useState([]);
    //productView
    const [productsView, setProductsView] = useState([]);

    // BoldFilesUploads - resultado de carga de imagenes.
    const [images, setImages] = useState([]);
    const handleImages = (e) => {
        setImages(e)
    }

    // nuevo
    const [productAssociateCombo, setProductAssociateCombo] = useState(false);
    const [productAsociateDrink, setProductAsociateDrink] = useState(false);

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setCategory(data.category_id);

        // extras
        let auxExtras = [];
        data.extras?.forEach(e => {
            auxExtras.push(e.item_id);
        })
        d.extras = auxExtras;

        // ingredients
        let auxIngredients = [];
        data.ingredients?.forEach(e => {
            auxIngredients.push(e.item_id);
        })
        d.ingredients = auxIngredients;
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    // Get Product
    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    // if(data.productpizza_id !== null && data.productpizza_id !== ""){
                    //     completedStep(1);
                    //     handleStep(1);
                    // }
                    console.log(data.product_combo, "product_combo");
                    console.log(data.product_dessert, "product_dessert");
                    console.log(data.product_drink, "product_drink");
                    console.log(data.productAssociateCombo, "productAssociateCombo");
                    console.log(data.productAsociateDrink, "productAsociateDrink");
                    console.log(data.product_group, "product_group");
                    console.log(data.product_pizza, "product_pizza");
                    console.log(data.product_view, "product_view");
                    console.log(activeStep, "activeStep");
                    if (data.combos_ids.length > 0) {
                        setProductAssociateCombo(true);
                    }
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }

    // Get Params
    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setCategoriesList(handlerApp.getResultFromObject(response.data, 'categories'));
                    setProductsView(handlerApp.getResultFromObject(response.data, 'product_view'));
                    setComboList(handlerApp.getResultFromObject(response.data, 'combo'));
                    setItemList(handlerApp.getResultFromObject(response.data, 'items'));
                    setDrinkList(handlerApp.getResultFromObject(response.data, 'drinks'));

                    // nombre de configuracion de pizza personalizado.
                    let pizza = JSON.parse(response.data[0].pizzas_configuration)
                    /* for(let e of pizza){
                        e.name = e.name+", slices: "+e.slices+", diametter: "+e.diametter;
                    } */
                    setPizzasConfiguration(pizza);

                    if (id !== undefined) {
                        setTitle('productComponent.updateProduct');
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }, [])

    const handleCategory = (value) => {
        console.log(value);
        setCategory(value);
        setSubcategory("");
        setFormData({ ...formData, category_id: value, subcategory_id: "" });
        // setSubcategoriesList([]);
    }

    const handleSubcategory = (value) => {
        console.log(value);
        setSubcategory(value);
        setFormData({ ...formData, subcategory_id: value })
    }

    useEffect(() => {
        if (category !== null) {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: 'getSubcategories',
                category: category
            })
                .then(response => {
                    if (response.success) {
                        setSubcategoriesList(response.data);
                        handlerApp.setLoader(false);
                    } else {
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                }).catch(error => {
                    handlerApp.showError(t(error.message));
                    handlerApp.setLoader(false);
                })
        }
    }, [category]);


    // --------------- FORM -----------------
    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // estandarizar
    const handleCheck = (e) => {
        let key = e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.checked
        });
    }

    // estandarizar.
    const returnObjectFromMultipleSelect = (options, values) => {
        let element = [];
        values?.forEach(val => {
            options.forEach(opt => {
                if (val === opt.code) {
                    element.push(opt);
                }
            })
        })
        return element;
    }

    const handleConsole = (e) => {
        e.preventDefault();

        let extras = returnObjectFromMultipleSelect(itemList, formData.extras);
        let ingredients = returnObjectFromMultipleSelect(itemList, formData.ingredients);
        let combos_ids = returnObjectFromMultipleSelect(comboList, formData.combos_ids);
        let drinks_ids = returnObjectFromMultipleSelect(drinkList, formData.drinks_ids);
        // let categories = returnObjectFromMultipleSelect(categoriesList,formData.categories);
        let location_id = handlerApp.getLocation();
        let body = { ...formData, extras, ingredients, combos_ids, drinks_ids, images, location_id };
        console.log(body);
    }
    // save data
    const handleSubmit = (e) => {
        e.preventDefault();

        let extras = returnObjectFromMultipleSelect(itemList, formData.extras);
        let ingredients = returnObjectFromMultipleSelect(itemList, formData.ingredients);
        let combos_ids = returnObjectFromMultipleSelect(comboList, formData.combos_ids);
        let drinks_ids = returnObjectFromMultipleSelect(drinkList, formData.drinks_ids);
        // let categories = returnObjectFromMultipleSelect(categoriesList,formData.categories);
        let location_id = handlerApp.getLocation();
        let body = { ...formData, extras, ingredients, combos_ids, drinks_ids, images, location_id };
        console.log(body);

        handlerApp.setLoader(true);
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        let extras;
        let ingredients;
        let combos_ids;
        let drinks_ids;
        if (formData.extras) {
            extras = returnObjectFromMultipleSelect(itemList, formData.extras);
        }
        if (formData.ingredients) {
            ingredients = returnObjectFromMultipleSelect(itemList, formData.ingredients);
        }
        if (formData.combos_ids) {
            combos_ids = returnObjectFromMultipleSelect(comboList, formData.combos_ids);
        }
        if (formData.drinks_ids) {
            drinks_ids = returnObjectFromMultipleSelect(drinkList, formData.drinks_ids);
        }
        // let categories = returnObjectFromMultipleSelect(categoriesList,formData.categories);
        let body = { ...formData, extras, ingredients, combos_ids, drinks_ids, images };
        console.log(body);
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    // funciones de stepper
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    const handleStep = (step) => {
        setActiveStep(step);
    }
    const completedStep = (index, value) => {
        if (value === null) {
            setCompleted({ ...completed, [index]: true })
        } else {
            setCompleted({ ...completed, [index]: value })
        }
    }
    const back = () => {
        nav(-1);
    }
    return (
        <div className="app container">
            <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
            <div className="title-section-forms">
                {t(title)}
            </div>
            {/* <Stepper nonLinear activeStep={activeStep} alternativeLabel> */}
            <Stepper className="mb-4" activeStep={activeStep} alternativeLabel>
                <Step key="1" completed={completed[0]}>
                    <StepButton color="inherit" onClick={
                        () => {
                            handleStep(0);
                            completedStep(0, false);
                            setFormData({
                                ...formData,
                                product_drink: false,
                                product_combo: false,
                                product_view: "",
                                product_dessert: false,
                                product_pizza: false,
                                productpizza_id: null
                            });
                            setProductAssociateCombo(false);
                        }
                    }>
                        1
                    </StepButton>
                </Step>
                <Step key="2" completed={completed[1]}>
                    <StepButton color="inherit" onClick={
                        () => { handleStep(1) }}>
                        2
                    </StepButton>
                </Step>
                <Step key="3">
                    <StepButton color="inherit" onClick={
                        () => { handleStep(2) }}>
                        3
                    </StepButton>
                </Step>
                <Step key="4">
                    <StepButton color="inherit" onClick={
                        () => { handleStep(3) }}>
                        4
                    </StepButton>
                </Step>
            </Stepper>
            <>
                {/* {activeStep === 0 && formData.product_drink != true && formData.product_combo != true && formData.product_view === "" && */}
                {activeStep === 0 && formData.product_drink != true && formData.product_combo != true && formData.product_pizza != true &&
                    (
                        <>
                            <div className="container bold-container-content">
                                <center className="mb-4 mt-3">{t('productComponent.kindOfProduct')}</center>
                                <div className="row np-row">
                                    <div className="col-md-3">
                                        <div className={`card-option ${formData.product_view == 'NORMAL' && formData.product_dessert == false ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    completedStep(0);
                                                    setFormData({ ...formData, product_view: "NORMAL", product_pizza: false, product_combo: false, product_dessert: false, product_drink: false, productpizza_id: null })
                                                    handleStep(1);
                                                }
                                            }>
                                            <div className="card-option-img-content">
                                                {/* <img className="card-option-img" src={simpleImg}></img> */}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Simple
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={`card-option ${formData.product_view === "COMBO" ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    setFormData({ ...formData, product_combo: true, product_view: "", product_drink: false, product_dessert: false });
                                                    setProductAssociateCombo(false);
                                                }
                                            }>
                                            <div className="card-option-img-content">
                                                {/* <img className="card-option-img" src={combosImg}></img> */}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Combos
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={`card-option ${formData.product_view == 'PIZZA' ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    setFormData({ ...formData, product_view: "PIZZA", product_pizza: true, product_dessert: false, product_drink: false, product_combo: false })
                                                }
                                            }>
                                            <div className="card-option-img-content">
                                                {/* <img className="card-option-img" src={pizzaImg}></img> */}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Pizza
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={`card-option ${formData.product_view == "MODAL_DRINK_SIMPLE"
                                            || formData.product_view == "MODAL_DRINK_SEMICUSTOM"
                                            || formData.product_view == "MODAL_DRINK_CUSTOM" ? "selected" : ""}`}
                                            role="button" onClick={
                                                () => {
                                                    completedStep(0);
                                                    setFormData({ ...formData, product_drink: true, product_pizza: false, product_combo: false, product_dessert: false, productpizza_id: null })
                                                }
                                            }>
                                            <div className="card-option-img-content">
                                                {/* <img className="card-option-img" src={drinkImg}></img> */}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    {t('productComponent.drink')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className={`card-option ${formData.product_dessert == true && formData.product_view == "NORMAL" ? "selected" : ""}`} role="button" onClick={
                                            () => {
                                                completedStep(0);
                                                setFormData({ ...formData, product_view: "NORMAL", product_dessert: true, product_drink: false, product_pizza: false, product_combo: false, productpizza_id: null })
                                                handleStep(1);
                                            }
                                        }>
                                            <div className="card-option-img-content">
                                                {/* <img className="card-option-img" src={dessertImg}></img> */}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    {t('productComponent.dessert')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                {activeStep === 0 && formData.product_drink == true && (
                    <>
                        <div className="container bold-container-content">
                            <center className="mb-4 mt-3">{t('productComponent.drinkPresentation')}</center>
                            <div className="row np-row flex justify-content-center mb-4">
                                <div className="col-md-3">
                                    <div className={`card-option ${formData.product_view == 'MODAL_DRINK_SIMPLE' ? "selected" : ""} `} role="button" onClick={
                                        () => {
                                            setFormData({ ...formData, product_view: "MODAL_DRINK_SIMPLE" })
                                            handleStep(1);
                                        }
                                    }>
                                        <div className="card-option-img-content">
                                            {/* <img className="card-option-img" src={modalSimple}></img> */}
                                        </div>
                                        <div className="card-option-content">
                                            <div className="card-option-title">
                                                Simple
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className={`card-option ${formData.product_view == 'MODAL_DRINK_SEMICUSTOM' ? "selected" : ""}`} role="button" onClick={
                                        () => {
                                            setFormData({ ...formData, product_view: "MODAL_DRINK_SEMICUSTOM" })
                                            handleStep(1);
                                        }
                                    }>
                                        <div className="card-option-img-content">
                                            {/* <img className="card-option-img" src={modalSq}></img> */}
                                        </div>
                                        <div className="card-option-content">
                                            <div className="card-option-title">
                                                {t('productComponent.semicustomizable')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className={`card-option ${formData.product_view == 'MODAL_DRINK_CUSTOM' ? "selected" : ""}`} role="button" onClick={
                                        () => {
                                            completedStep(0);
                                            setFormData({ ...formData, product_view: "MODAL_DRINK_CUSTOM" });
                                            handleStep(1);
                                        }}>
                                        <div className="card-option-img-content">
                                            {/* <img className="card-option-img" src={modalCustom}></img> */}
                                        </div>
                                        <div className="card-option-content">
                                            <div className="card-option-title">
                                                {t('productComponent.customizable')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button className="bold-btn bold-btn-light" onClick={
                                    () => {
                                        handleStep(0);
                                        setFormData({ ...formData, product_drink: false })
                                    }
                                }
                                >{t('back')}</button>
                            </div>
                        </div>
                    </>
                )}
                {/* {activeStep === 0 && formData.product_view === "PIZZA" && formData.product_pizza == true && formData?.productpizza_id === null && ( */}
                {activeStep === 0 && formData.product_view === "PIZZA" && formData.product_pizza == true && (
                    <>
                        <div className="container bold-container-content">
                            <center className="mb-4 mt-3">{t('productComponent.sizePizza')}</center>
                            <div className="row np-row flex justify-content-center mb-4">
                                {pizzasConfiguration.map(item => {
                                    return (
                                        <div className="col-6 col-md-3" key={item.code}>
                                            <div className={`card-option ${item.code == formData.productpizza_id ? "selected" : ""}`} role="button"
                                                onClick={
                                                    () => {
                                                        completedStep(0);
                                                        setFormData({ ...formData, productpizza_id: item.code });
                                                        handleStep(1);
                                                    }
                                                }>
                                                <div className="card-option-img-content">
                                                    {/* <img className="card-option-img" src={pizzaSilueta}></img> */}
                                                </div>
                                                <div className="card-option-content">
                                                    <div className="card-option-title">
                                                        {item.name}<br />
                                                        {item.diametter !== "" && <span className="text-gray light small-text d-block">Diametter: {item.diametter}</span>}
                                                        {item.slices !== "" && <span className="text-gray light small-text d-block">Slices: {item.slices}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button className="bold-btn bold-btn-light" onClick={
                                    () => {
                                        if (formData.product_combo) {
                                            setFormData({ ...formData, product_pizza: false, product_view: "COMBO" });
                                        } else {
                                            setFormData({ ...formData, product_pizza: false })
                                        }
                                    }
                                }>{t('back')}</button>
                            </div>
                        </div>
                    </>
                )}

                {/* Seleccion si es de tipo combo, que producto? pizza o normal. */}
                {/* {activeStep === 0 && formData.product_combo == true && formData.product_view === "" && ( */}
                {activeStep === 0 && formData.product_combo == true && (formData.product_view === "" || formData.product_view == "COMBO" || formData.product_view == "NORMAL") && (
                    <>
                        <div className="container bold-container-content">
                            <center className="mb-4 mt-3">{t('productComponent.productType')}</center>
                            <div className="row np-row flex justify-content-center mb-4">
                                <div className="col-md-3">
                                    <div className={`card-option ${formData.product_view == "NORMAL" ? "selected" : ""}`} role="button"
                                        onClick={
                                            () => {
                                                completedStep(0);
                                                setFormData({ ...formData, product_view: "NORMAL", productpizza_id: "" });
                                                handleStep(1);
                                            }
                                        }>
                                        <div className="card-option-img-content">
                                            {/* <img className="card-option-img" src={simpleImg}></img> */}
                                        </div>
                                        <div className="card-option-content">
                                            <div className="card-option-title">
                                                Simple
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className={`card-option ${formData.product_view == "PIZZA" ? "selected" : ""}`} role="button"
                                        onClick={
                                            () => {
                                                setFormData({ ...formData, product_view: "PIZZA", product_pizza: true });
                                            }
                                        }>
                                        <div className="card-option-img-content">
                                            {/* <img className="card-option-img" src={pizzaImg}></img> */}
                                        </div>
                                        <div className="card-option-content">
                                            <div className="card-option-title">
                                                Pizza
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button className="bold-btn bold-btn-light" onClick={
                                    () => {
                                        handleStep(0);
                                        setFormData({ ...formData, product_view: "COMBO", product_combo: false })
                                    }
                                }
                                >{t('back')}</button>
                            </div>
                        </div>
                    </>
                )}
                {activeStep === 1 &&
                    (
                        <div className="container bold-container-content">
                            <form>
                                <div className="container row mb-4">
                                    <div className="form-title col-md-12">
                                        {t('productComponent.infoGeneral')}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField name="product_name" onChange={handleForm} value={formData.product_name} label={t('name')} required variant="standard" size="small" />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField name="product_name_spanish" onChange={handleForm} value={formData.product_name_spanish} label={t('spanishName')} variant="standard" size="small" />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField name="product_group" onChange={handleForm} value={formData.product_group} label={t('productComponent.group')} variant="standard" size="small" />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField name="product_sku" onChange={handleForm} value={formData.product_sku} label="SKU" required variant="standard" size="small" />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField name="product_price" onChange={handleForm} value={formData.product_price} label={t('productComponent.price')} required variant="standard" size="small" />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField name="product_cost" onChange={handleForm} value={formData.product_cost} label={t('productComponent.cost')} variant="standard" size="small" />
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                <BoldSelect title={t('productComponent.currency')} value={formData.product_currency} name="product_currency" options={currencies} onChange={handleForm} />
                            </div> */}
                                    <div className="col-md-6 mb-2">
                                        <BoldSelect title={t("categoryComponent.category")} value={formData.category_id} name="category_id" options={categoriesList} onChange={handleForm} dependency={handleCategory} />
                                        {/* <BoldMultiplesSelect title="Categories" name="categories" value={formData.categories} onChange={handleForm} options={categoriesList}/> */}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <BoldSelect title={t("subcategoryComponent.subcategory")} value={formData.subcategory_id} name="subcategory_id" options={subcategoriesList} onChange={handleForm} dependency={handleSubcategory} />
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <div className="row np-row">
                                            <div className="col-md-6">
                                                <FormControlLabel control={<Checkbox name="product_not_available" checked={formData.product_not_available} onChange={handleCheck} />} label={t('productComponent.productNotAvailable')} /><br />
                                                <span className="text-gray light small-text">{t('productComponent.notAvailableDetail')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <button className="bold-btn bold-btn-light" onClick={
                                        (e) => {
                                            e.preventDefault();
                                            // setFormData({...formData,
                                            //     product_drink: false, 
                                            //     product_combo: false, 
                                            //     product_view: "", 
                                            //     product_dessert: false, 
                                            //     product_pizza: false,
                                            //     productpizza_id: null,
                                            // });
                                            handleStep(0);
                                        }
                                    }>{t('back')}</button>
                                    <button className="bold-btn bold-btn-dark" onClick={(e) => {
                                        if (formData.product_view !== "MODAL_DRINK_SIMPLE" && formData.product_view !== "MODAL_DRINK_SEMICUSTOM" && formData.product_dessert !== true) {
                                            e.preventDefault();
                                            handleStep(2);
                                        } else {
                                            e.preventDefault();
                                            handleStep(3);
                                        }
                                    }}>{t('next')}</button>
                                </div>
                            </form>
                        </div>
                    )}
                {activeStep === 2 &&
                    (
                        <div className="container bold-container-content">
                            <div className="container row">

                                <div className="form-title col-md-12">
                                    {t('productComponent.additionalInformation')}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <TextField className="bold-text-field-material" name="product_description" value={formData.product_description} onChange={handleForm} multiline rows={3} label={t('englishDescription')} size="small" />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <TextField className="bold-text-field-material" name="product_short_description" value={formData.product_short_description} onChange={handleForm} multiline rows={3} label={t('shortEnglishDescription')} size="small" />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <TextField className="bold-text-field-material" name="product_description_spanish" value={formData.product_description_spanish} onChange={handleForm} label={t('spanishDescription')} multiline rows={3} size="small" />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <TextField className="bold-text-field-material" name="product_short_description_spanish" value={formData.product_short_description_spanish} onChange={handleForm} label={t('shortSpanishDescription')} multiline rows={3} size="small" />
                                </div>
                                {
                                    formData.product_view !== "MODAL_DRINK_SIMPLE" && formData.product_view !== "MODAL_DRINK_SEMICUSTOM" && (
                                        <>
                                            <div className="col-md-6 mb-2">
                                                <BoldMultiplesSelect title={t("productComponent.ingredients")} name="ingredients" value={formData.ingredients} onChange={handleForm} options={itemList} />
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <BoldMultiplesSelect title="Extras" name="extras" value={formData.extras} onChange={handleForm} options={itemList} />
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.product_combo !== true && formData.product_drink !== true &&
                                    (
                                        <>
                                            <div className="form-title col-md-12">
                                                Asociar Combos Traducir
                                            </div>
                                            <div className="col-md-6">
                                                <FormControlLabel control={<Checkbox name="product_asociar_combo" checked={productAssociateCombo} onChange={(e) => { setProductAssociateCombo(!productAssociateCombo) }} />} label={t('productComponent.associateCombo')} />
                                                {
                                                    productAssociateCombo &&
                                                    <BoldMultiplesSelect title="Combos" name="combos_ids" value={formData.combos_ids} onChange={handleForm} options={comboList} />
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <span className="text-gray light small-text">{t('productComponent.associateComboExplain')}</span>
                                                {/* <span className="text-gray light small-text">Indica que el producto se puede llevar en combo</span> */}
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.product_combo === true && (

                                        <div className="col-md-12 mb-2">
                                            <div className="form-title col-md-12">
                                                Bebidas Traducir
                                            </div>
                                            <div className="row np-row">
                                                <div className="col-md-6">
                                                    <FormControlLabel control={<Checkbox name="product_combo" checked={productAsociateDrink} onChange={(e) => { setProductAsociateDrink(!productAsociateDrink) }} />} label={t('productComponent.associateDrink')} />
                                                    {
                                                        productAsociateDrink &&
                                                        <BoldMultiplesSelect title="Drinks" name="drinks_ids" value={formData.drinks_ids} onChange={handleForm} options={drinkList} />
                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    <span className="text-gray light small-text">{t('productComponent.associateDrinksExplain')}.</span>
                                                    {/* <span className="text-gray light small-text">Indica que el producto se puede llevar en combo</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button className="bold-btn bold-btn-light" onClick={
                                    () => {
                                        handleStep(1);
                                    }
                                }
                                >{t('back')}</button>
                                <button className="bold-btn bold-btn-dark" onClick={(e) => {
                                    e.preventDefault();
                                    handleStep(3);
                                }}>{t('next')}</button>
                            </div>
                        </div>
                    )}
                {activeStep === 3 &&
                    (
                        <div className="container bold-container-content">
                            <div className="container row">
                                <div className="form-title col-md-12">
                                    {t('images')}
                                </div>
                                <div className="col-md-12 mb-2">
                                    <BoldFilesUpload returnImages={handleImages} multiple={false} value={formData.images} />
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-3 d-flex justify-content-around">
                                        <button className="bold-btn bold-btn-light" onClick={
                                            (e) => {
                                                if (formData.product_view !== "MODAL_DRINK_SIMPLE" && formData.product_view !== "MODAL_DRINK_SEMICUSTOM" && formData.product_dessert !== true) {
                                                    handleStep(2);
                                                } else {
                                                    handleStep(1);
                                                }
                                            }
                                        }
                                        >{t('back')}</button>
                                        <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                        {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('submit')}</button>}
                                        {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>Update</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </>
            {/* {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleConsole}>console</button>} */}
            <button onClick={handleConsole}>Btn</button>
        </div>
    )
}

export default ProductsForms;