import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import { TextField } from "@mui/material";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";

const ProductLocation2Form = () => {

    const endpoint = 'configuration/locations/products';
    const api = useFetch();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const { id } = useParams();
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState('productLocationComponent.create');

    //Permisos
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    // location
    const { location } = useContext(LocationContext);
    const validateLocation = true;
    useEffect(() => {
        if (app.hasOwnProperty('location')) {
            if (validateLocation) {   // valida si esta activa la verificacion del sucursal.
                if (location === "") { // no hay sucursal seleccionada
                    handlerApp.showAlertLocation(); // muestra una notificacion al usuario solicitando que por favor seleccione una sucursal.
                    setData([]);
                } else {
                    getParamsUpdate();  // agrego xq esta pantalla depende de las sucursales en el estado.
                }
            } else {
                getParamsUpdate();  // agrego xq esta pantalla depende de las sucursales en el estado.
            }
        }
    }, [location])


    const [products, setProducts] = useState([]);
    // const [locations, setLocations] = useState([]);
    const [taxgroup, setTaxesGroup] = useState([]);
    const [printers, setPrinters] = useState([]);

    // Data
    const [formData, setFormData] = useState({
        product_id: '',
        location_id: '',
        productlocation_price: '',
        taxgroup_id: '',
        productlocation_discount: '',
        productlocation_discount_start: '',
        productlocation_discount_end: '',
        productlocation_stock: 0,
        productlocation_min_stock: '',
        productlocation_max_stock: '',
        productlocation_not_available: false,
        printer_id: ''
    });

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
        // setData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            console.log("key", key);
            if (data[key] != null && data[key] != undefined) {
                finalData[key] = data[key];
            } else {
                finalData[key] = formData[key];
            }
            console.log("data", data[key]);
        }
        console.log(finalData);
        return finalData;
    }
    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                    handlerApp.handlerResponse(response);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: 'getParamsUpdate',
            location
        })
            .then(response => {
                if (response.success) {
                    console.log(response.data)
                    setProducts(handlerApp.getResultFromObject(response.data, 'products'));
                    // setLocations(handlerApp.getResultFromObject(response.data, 'locations'));
                    setTaxesGroup(handlerApp.getResultFromObject(response.data, 'tax_group'));
                    setPrinters(handlerApp.getResultFromObject(response.data, 'printers'))
                    if (id !== undefined) {
                        setTitle('productLocationComponent.update');
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }
    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);
        let body = {
            ...formData,
            location_id: location
        }
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                } else {
                    handlerApp.handlerResponse(response);
                }
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
    const handleUpdate = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);
        let body = {
            ...formData, location_id: location
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
            </div>
            <div className="section-forms">
                <form>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <BoldSelect title={t('productComponent.product')} value={formData.product_id} name="product_id" options={products} onChange={handleForm} required />
                        </div>
                        {/* <div className="col-md-6 mb-2">
                            <BoldSelect title={t('locationComponent.location')} value={formData.location_id} name="location_id" options={locations} onChange={handleForm} />
                        </div> */}
                        <div className="col-md-6 mb-2">
                            <TextField name="productlocation_price" onChange={handleForm} value={formData.productlocation_price} label={t('productComponent.price')} variant="standard" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelect title={t('taxesComponent.taxgroup_name')} value={formData.taxgroup_id} name="taxgroup_id" options={taxgroup} onChange={handleForm} required />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="productlocation_discount" onChange={handleForm} value={formData.productlocation_discount} label={t('productComponent.discount')} variant="standard" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="productlocation_discount_start" onChange={handleForm} value={formData.productlocation_discount_start} label={t('productLocationComponent.discount_start')} variant="standard" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="productlocation_discount_end" onChange={handleForm} value={formData.productlocation_discount_end} label={t('productLocationComponent.discount_end')} variant="standard" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="productlocation_stock" onChange={handleForm} value={formData.productlocation_stock} label={t('productLocationComponent.stock')} variant="standard" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="productlocation_min_stock" onChange={handleForm} value={formData.productlocation_min_stock} label={t('productLocationComponent.min_stock')} variant="standard" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="productlocation_max_stock" onChange={handleForm} value={formData.productlocation_max_stock} label={t('productLocationComponent.max_stock')} variant="standard" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelect title={t('printersComponent.printer')} value={formData.printer_id} name="printer_id" options={printers} onChange={handleForm} required />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>Update</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ProductLocation2Form;