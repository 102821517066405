
import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";

const UsersLocationsForms = () => {

    const endpoint = "administration/users/locations";
    const [t] = useTranslation("global");
    
    const title = t('usersLocationsComponent.createUserLocation');
    const api = useFetch();
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const {id} = useParams();

    const [formData,setFormData] = useState({
        location_id:           "",
        user_id:               "",
        userlocation_position: ""
    });

    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const {app} = useContext(AppContext);
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    const handleDependencies = (data)=>{
        let d = filterData(data,formData);
        setFormData(d);
    }

    const filterData = (data,formData)=>{
        let finalData = {};
        for(let key in formData){
            finalData[key] = data[key];
        }
        return finalData;
    }

    const sendRequest = ()=>{
        api.get(endpoint+"/"+id,)
        .then(response =>{
            if(response.success){
                let data = response.data[0];
                handleDependencies(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint,{
            action:"getParamsUpdate"
        })
        .then(response =>{
            if(response.success){
                setData(response.data)
                setUsers(handlerApp.getResultFromObject(response.data,'users'))
                setLocations(handlerApp.getResultFromObject(response.data,'locations'))
                
                if(id !== undefined){
                    sendRequest();
                }else{
                    handlerApp.setLoader(false);
                }
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    },[])

    const handleForm = (e,name=null,dependency=null)=>{
        let key = (name!==null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if(dependency !== null){
            dependency(e.target.value);
        }
    }
    
    const handleCancel = (e)=>{
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmit = (e)=>{
        e.preventDefault();
        handlerApp.setLoader(true);
        
        api.post(endpoint,formData)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
    
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdate = (e)=>{
        e.preventDefault();
        handlerApp.setLoader(true);
     
        api.put(endpoint+"/"+id,formData)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
            </div>
            <div className="section-forms">
                <form>
                    <div className="row">
                        <div className="col-md-4 mb-2">
                            <BoldSelect title={t('locationComponent.location')} value={formData.location_id} name="location_id" options={locations} onChange={handleForm} />
                        </div>
                        <div className="col-md-4 mb-2">
                            <BoldSelect title={t('users')} value={formData.user_id} name="user_id" options={users} onChange={handleForm} />
                        </div>
                        <div className="col-md-4 mb-2">
                            <TextField name="userlocation_position" onChange={handleForm} value={formData.userlocation_position} label={t('usersLocationsComponent.userlocation_position')} required variant="standard" size="small" />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>Update</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UsersLocationsForms;