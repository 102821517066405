import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HandlerApp from "../../../utils/handlerApp";
import notFound from "../../../assets/img/image-not-found.webp";



const ListServicesCategories = ({ item, isChecked, onCheckboxChange, setData, deleteRow, data }) => {
    const endpoint = "services/categories";
    const [iconSeleccionado, setIconSeleccionado] = useState(false);
    const [subIconSeleccionado, setSubIconSeleccionado] = useState(false);
    const [t, i18n] = useTranslation("global");
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const api = useFetch();

    const handleCheckboxChange = () => {
        onCheckboxChange(item.id, !isChecked); // Llama al manejador del componente padre
    };

    const handleIconClick = () => {
        setIconSeleccionado(!iconSeleccionado);
    };

    const handleNavClick = (id) => {
        nav('/services/view/' + id)
    }

    const handleUpdateClick = (id) => {
        nav("/" + endpoint + "/edit/" + id);
    }

    const handleShowService = (item) => {

        item.show = !item.show;

        let stateData = data.map(elementos => {
            elementos.services.map(service => {
                if (service.service_id === item.service_id) {
                    // service.show != service.show;
                }
            })

            return elementos;
        })
        console.log(stateData);
        setData(stateData);
    }

    return (
        <>
            <div className={`row containerCardFluid ${iconSeleccionado ? 'colorCardServices' : ''}`}>

                <div className="col-md-11">
                    <div className="row alignCardLeft" >
                        <div className="col-md-12 image-with-name">
                            <p style={{ color: iconSeleccionado ? '#ffffff' : 'inherit', fontWeight: 400, fontSize: 18 }} >{item.servicecategory_name}</p>
                        </div>
                        {
                            iconSeleccionado &&
                            <div className="col-md-12 image-with-name">
                                <p style={{ color: iconSeleccionado ? '#ffffff' : 'inherit', fontSize: 16 }} >{item.servicecategory_description}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-1">
                    <div className="row">
                        <div className="col-md-6 iconCardMore" style={{ backgroundColor: iconSeleccionado ? '#F0B669' : 'inherit' }} >
                            <MoreHorizIcon style={{ fontSize: 18 }} data-bs-toggle="dropdown" aria-expanded="false" />
                            <ul className="dropdown-menu dropdrown-services-categories" >
                                {
                                    (
                                        <>
                                            <li>
                                                <button className="btn" title="crear" onClick={() => handleUpdateClick(item.servicecategory_id)}>
                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.1914 1.13281C10.8262 0.498047 11.8672 0.498047 12.502 1.13281L13.4922 2.12305C14.127 2.75781 14.127 3.79883 13.4922 4.43359L12.2734 5.65234L8.97266 2.35156L10.1914 1.13281ZM11.6895 6.23633L5.77344 12.1523C5.51953 12.4062 5.18945 12.6094 4.83398 12.7109L1.76172 13.5996C1.55859 13.6758 1.33008 13.625 1.17773 13.4473C1 13.2949 0.949219 13.0664 1 12.8633L1.91406 9.79102C2.01562 9.43555 2.21875 9.10547 2.47266 8.85156L8.38867 2.93555L11.6895 6.23633Z" fill="#37465A" />
                                                    </svg>
                                                    {t("servicesCategorie.updateServicesCategory")}
                                                </button>
                                            </li>
                                            <li>
                                                <button className="btn" title="crear" onClick={() => deleteRow(item.servicecategory_id)}>
                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.875 1.1875C0.875 0.882812 1.10938 0.625 1.4375 0.625H2.5625C2.86719 0.625 3.125 0.882812 3.125 1.1875V2.3125C3.125 2.64062 2.86719 2.875 2.5625 2.875H1.4375C1.10938 2.875 0.875 2.64062 0.875 2.3125V1.1875ZM2.375 2.125V1.375H1.625V2.125H2.375ZM12.125 1.375C12.3125 1.375 12.5 1.5625 12.5 1.75C12.5 1.96094 12.3125 2.125 12.125 2.125H4.625C4.41406 2.125 4.25 1.96094 4.25 1.75C4.25 1.5625 4.41406 1.375 4.625 1.375H12.125ZM12.125 5.125C12.3125 5.125 12.5 5.3125 12.5 5.5C12.5 5.71094 12.3125 5.875 12.125 5.875H4.625C4.41406 5.875 4.25 5.71094 4.25 5.5C4.25 5.3125 4.41406 5.125 4.625 5.125H12.125ZM12.125 8.875C12.3125 8.875 12.5 9.0625 12.5 9.25C12.5 9.46094 12.3125 9.625 12.125 9.625H4.625C4.41406 9.625 4.25 9.46094 4.25 9.25C4.25 9.0625 4.41406 8.875 4.625 8.875H12.125ZM2.5625 4.375C2.86719 4.375 3.125 4.63281 3.125 4.9375V6.0625C3.125 6.39062 2.86719 6.625 2.5625 6.625H1.4375C1.10938 6.625 0.875 6.39062 0.875 6.0625V4.9375C0.875 4.63281 1.10938 4.375 1.4375 4.375H2.5625ZM1.625 5.125V5.875H2.375V5.125H1.625ZM0.875 8.6875C0.875 8.38281 1.10938 8.125 1.4375 8.125H2.5625C2.86719 8.125 3.125 8.38281 3.125 8.6875V9.8125C3.125 10.1406 2.86719 10.375 2.5625 10.375H1.4375C1.10938 10.375 0.875 10.1406 0.875 9.8125V8.6875ZM2.375 9.625V8.875H1.625V9.625H2.375Z" fill="#37465A" />
                                                    </svg>
                                                    {t("servicesCategorie.deleteServicesCategory")}
                                                </button>
                                            </li>
                                        </>
                                    )
                                }
                            </ul>
                        </div>


                        <div className="col-md-6 iconCardChevron" style={{ backgroundColor: iconSeleccionado ? '#F0B669' : 'inherit' }} onClick={handleIconClick}>
                            <KeyboardArrowDownIcon style={{ fontSize: 18, transform: iconSeleccionado ? 'rotate(-90deg)' : 'none' }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Lista secundaria (renderizada) */}
            {
                item.services.length > 0 &&
                <div className={`row subContainer-card ${iconSeleccionado ? 'oculto' : ''}`} style={{ width: "100%" }}>
                    {
                        item.services.map((row, index) => (
                            <div className="col-md-12">
                                <div className={`row subContainer-card-fluid`} style={{  height: row.show ? 80 : ''  }}>
                                    <div className="row">
                                        <div className="col-md-1 alignCardLeftServicesImg" >
                                            {
                                                (item.service_image != null) ? <img className="bold-image-table" src={row.service_image} alt={row.service_name} /> : <img className="bold-image-table" src={notFound} alt={item.service_name} />
                                            }
                                        </div>

                                        <div className="col-md-10" >
                                            <div className="row">
                                                <div className="col-md-12 alignCardLeftServicesName content-services-title">
                                                    <p className="title-services" >
                                                        {row.service_name} {row.show && <br />}
                                                        {
                                                            row.show &&
                                                            <p className="time-services">
                                                              {row.service_description}
                                                            </p>
                                                        }
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-1 iconCardServices" style={{ backgroundColor: '#ffffff', marginBottom: 6 }} >
                                            <KeyboardArrowRightIcon style={{ fontSize: 18, transform: row.show ? 'rotate(-90deg)' : 'none' }} onClick={() => handleShowService(row)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="content-time-services">
                                            {
                                                row.show &&
                                                <p className="time-services">
                                                    <AccessTimeIcon style={{ fontSize: 18, marginBottom: 3 }} /> {row.service_duration} Min
                                                </p>
                                            }
                                        </div>
                                        <div className="content-price-services">
                                            {
                                                row.show &&
                                                <p className="price-services">
                                                    {handlerApp.formatCurrency(row.service_price)}
                                                </p>
                                            }
                                        </div>
                                    </div>



                                </div>
                            </div>
                        ))
                    }
                </div>
            }



        </>


    );
};
export default ListServicesCategories;