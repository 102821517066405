import { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router";

import Aside from "../../organisms/Aside";
import Loader from "../Loader";
import Footer from "../../organisms/Footer";
import MainHeader from "../../organisms/MainHeader";
import "../../../assets/css/BoldFramework.css";
import "../../../assets/css/App.css";
import "./style.css";
import "./dark-style.css";

import { LocationContext } from "../../../context/LocationContext";
import { LoaderContext } from "../../../context/LoaderContext";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { Logout } from "../../../utils/BoldLogin";
import { useTranslation } from "react-i18next";

const Init = () => {
    // TODO: revisar la doble carga de la solicitud al endpoint cuando recargo la pagina, se debe a la actualizacion del contexto de la aplicacion.
    const {loader }             = useContext(LoaderContext);
    const {app,setApp}          = useContext(AppContext);
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const logout                = Logout();
    const [t] = useTranslation("global");

    // Menu
    const [resultMenu, setResultMenu] = useState();
    const [menu, setMenu] = useState([]);
    const [loaderMenu,setLoaderMenu] = useState(true);

    // Responsive
    const [mobile,setMobile]    = useState(false);
    const [show,setShow]        = useState(false);
    const contentAside          = useRef();
    const globalContentAside    = useRef();
    const contentGlobalApp      = useRef();


    // Locations
    const [locationLabel,setLocationLabel]  = useState("");
    // const [location,setLocation]            = useState("");
    const {location,setLocation} = useContext(LocationContext);
    const [locations,setLocations]          = useState([]);
    useEffect(()=>{
        setLabelLocation(localStorage.getItem("currentLocation"));
    },[locations])

    const handleLocation = (id)=>{
        setLocation(id);
        localStorage.setItem('currentLocation',id);
        setLabelLocation(id);
        // setApp({...app,location:id});
        setLocation(id);
    }

    useEffect(()=>{
        // menu - aside 
        getMenu();

        // Locations
        let locationList = JSON.parse(localStorage.getItem("locations"));
        if(localStorage.getItem("currentLocation") !== null && localStorage.getItem("currentLocation") !== undefined && localStorage.getItem("currentLocation") !== ""){
            setLocation(localStorage.getItem("currentLocation"));
        }
        setLocations(locationList);

        // Responsive
        let isMobile = (window.innerWidth < 768) ? true: false;
        setMobile(isMobile);
        // // if(!isMobile){
        // //     setShow(true);
        // // }
        window.addEventListener('resize',()=>{
            let isMobile = (window.innerWidth < 768) ? true: false;
            setMobile(isMobile);
        })

        // modo oscuro
        const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        let htmls = document.getElementsByTagName("html");
        let theme = localStorage.getItem("theme");
        if(theme !== null ){
            htmls[0].className = theme;
        }else{
            if (isDarkMode) {
                htmls[0].className = 'dark';
            } else {
                htmls[0].className = '';
            } 
        }
    },[])

    // Menu - Aside
    const getMenu = ()=>{
        api.post("/menu")
        .then(response => {
            if(response.success){
                setResultMenu(response.data)
                setLoaderMenu(false);
            }else{
                if(response.message == "0021"){
                    // TODO traducir
                    handlerApp.showError(t("0021"));
                    logout.closeSession();
                }
            }
        })
        .catch(error => {
            setLoaderMenu(false);
        });
    }
    useEffect(() => {
        let aux = "";
        let parentMenus = [];
        let permissions = []
        let objMenus = [];
        resultMenu?.forEach(title => {
            if (title.menu_parent !== aux && title.menu_parent !== '') {
                aux = title.menu_parent;
                let childrens = [];
                let objChildren = [];
                resultMenu?.forEach(song => {
                    if (song.menu_parent === aux && song.menu_children !== '') {
                        childrens.push(song)
                        let perm = song.permissions;
                        perm = perm.split('|');
                        permissions[song.menu_children_action] = perm;
                        title.childrens = childrens;
                        objChildren.push({
                            menu_children: song.menu_children,
                            menu_children_action: song.menu_children_action
                        });
                    }
                })

                parentMenus.push(title);
                objMenus[title.menu_parent] = objChildren;
            }
        })
        setMenu(parentMenus);

        // Actualizacion de contexto
        setApp({...app,menus:objMenus, permissions, location});

    }, [resultMenu])

    // ---------------------- Responsive
    useEffect(()=>{
        if(mobile){
            setShow(false);
        }else{
            setShow(false);
        }
        // if(!mobile){
        //     setShow(true)
        // }
    },[mobile])

    useEffect(()=>{
        if(show){
            contentAside.current.className="aside show";
            contentGlobalApp.current.className="bold-container";
            if(mobile){
                globalContentAside.current.className="shadow-aside";
            }
        }else{
            if(mobile){
                contentAside.current.className="aside hide";
                contentGlobalApp.current.className="bold-container full";
                globalContentAside.current.className="";
            }else{
                contentGlobalApp.current.className="bold-container full";
                contentAside.current.className = "aside hide";
            }
            
        }
    //     if(show){
    //         contentAside.current.className="aside show";
    //         contentGlobalApp.current.className="bold-container";
    //         if(mobile){
    //             globalContentAside.current.className="shadow-aside";
    //         }
    //     }else{
    //         if(mobile){
    //             contentAside.current.className="aside hide";
                
    //         }else{
    //             contentGlobalApp.current.className="bold-container full";
    //             contentAside.current.className = "aside hide";
    //         }
    //         globalContentAside.current.className="";
    //     }
    },[show])

    const toggleAside = ()=>{
        setShow(!show);
        // if(!mobile){
        //     contentGlobalApp.current.className="bold-container full";
        //     contentAside.current.className = "aside hide";
        // }
        // setShow(!show)
    }
    // ---------------------- /Responsive


    // Location
    const setLabelLocation = (id)=>{
        let element = locations.filter(e => e.location_id.toString() === id.toString() );
        if(element.length > 0){
            setLocationLabel(element[0]?.location_name);
        }else{
            setLocationLabel("Global")
        }
    }

    

    
    return (
        <div className="bold-application">
            <div ref={globalContentAside}>
                <div className="menu-btn-toggle" role="button" onClick={toggleAside}>
                    {/* <span className="material-symbols-outlined">arrow_back_ios_new</span> */}
                    &times;
                </div>
                <div ref={contentAside} className={ mobile ? 'aside hide': 'aside hide'}>
                    <Aside loaderMenu={loaderMenu} menu={menu} />
                </div>
            </div>
            <div ref={contentGlobalApp} className="bold-container full">
                <MainHeader toggleAside={toggleAside} locations={locations} handleLocation={handleLocation} locationLabel={locationLabel}/>
                { loader && <Loader/>}
                <Outlet />
                <Footer />
            </div>
        </div>
    )
}
export default Init;