import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { LocationContext } from "../../../context/LocationContext";

const ProductLocation2 = () =>{
    const endpoint = 'configuration/locations/products';
    const api = useFetch();
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const nav = useNavigate();
    const [t, i18n] = useTranslation("global");
    let title = t('productLocationComponent.productLocation');
    const handlerApp = HandlerApp();

    // Permisos
    const [permissions,setPermissions] = useState([]);
    const {app} = useContext(AppContext);
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    // location
    const {location} = useContext(LocationContext);
    const validateLocation = true;
    useEffect(()=>{
        if(location === ""){
            if(handlerApp.getLocation() === null  || handlerApp.getLocation() === ""){
                handlerApp.showAlertLocation();
                setData([]);
            }
        }else{
            sendRequest();
        }
    },[location])

    const sendRequest=()=>{
        handlerApp.setLoader(true);
        api.get(endpoint,{
            location,
            action: 'frontendLocation'
        })
        .then(response =>{
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
                console.log(data);
            }else{
                setData([]);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            console.log(error);
            handlerApp.setLoader(false);
    })
    }
    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }
    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    useEffect(()=>{
        if(Array.isArray(data)){
            let filter = data?.filter(
                item => item.product_name && item.product_name.toLowerCase().includes(filterText.toLowerCase())
                    || item.location_name?.toString().toLowerCase().includes(filterText.toLowerCase())
                    || item.productlocation_price?.toString().includes(filterText.toLowerCase())
                    || item.productlocation_stock?.toString().includes(filterText.toLowerCase())
                    || item.productlocation_min_stock?.toString().includes(filterText.toLowerCase())
                    || item.productlocation_discount?.toString().includes(filterText.toLowerCase())
                    || item.productlocation_id?.toString().includes(filterText.toLowerCase())
                    || item.printer_name?.toLowerCase().includes(filterText.toLowerCase())
            );
            setFilteredItems(filter);
        }
    },[data,filterText])

    const productNotAvailable = (productlocation_id)=>{
        let table = data;
        let elemento = 0;
        let status = false;
        table.forEach( (element,index)=> {
            if(element.productlocation_id == productlocation_id){
                elemento = index;
                status = !element.productlocation_not_available;
                // setData(...table,table[index].product_not_available = !element.productlocation_not_available);
                table[index].productlocation_not_available = status;
            }
        });
        setData([...data,table]);

        // handlerApp.setLoader(true);
        let body = {
            action: 'productNotAvailable',
            status
        }
        api.put(endpoint+"/"+productlocation_id,body)
        .then(response => {
            // success
        })
        .catch(error => {
            table[elemento].productlocation_not_available = !status;
            setData([...data,table]);
        })

    }


    const columns = [
        {
            id: 'productlocation_id',
            name: 'ID',
            selector: row => row.productlocation_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'product_name',
            name: t('productLocationComponent.product_name'),
            selector: row => row.product_name,
            sortable: true,
        },
        {
            id: 'taxgroup_name',
            name: t('taxesComponent.taxgroup_name'),
            selector: row => row.taxgroup_name,
            sortable: true,
        },
        {
            id: 'productlocation_price',
            name: t('productComponent.price'),
            // cell: (row) => {return handlerApp.formatCurrency(row.productlocation_price)},
            selector: row => handlerApp.formatCurrency(row.productlocation_price),
            sortable: true,
            width: "100px"
        },
        {
            id: 'productlocation_discount',
            name: '% ' + t('productComponent.discount'),
            selector: row => (row.productlocation_discount > 0)? handlerApp.formatPercent(row.productlocation_discount):'0%',
            sortable: true,
        },
        {
            id: 'printer_name',
            name: t('printersComponent.printers'),
            selector: row => row.printer_name,
            sortable: true
        },
        {
            name: t('action'),
            cell:(row) => { 
                return <>
                    {permissions?.indexOf('update') && 
                    <div>
                        {row.productlocation_not_available == true && 
                            <button className="btn-table-actions wx2" title="Product not available" onClick={e=>{productNotAvailable(row.productlocation_id)}} id={row.ID}>
                                <span className="material-symbols-outlined inactive">production_quantity_limits</span>
                                <span className="xsmall">No disponible</span>
                            </button>
                        }
                        {row.productlocation_not_available == false &&
                            <button className="btn-table-actions wx2" title="Product available" onClick={e=>{productNotAvailable(row.productlocation_id)}} id={row.ID}>
                                <span className="material-symbols-outlined active">production_quantity_limits</span>
                                <span className="xsmall">Disponible</span>
                            </button>
                        }
                    </div>
                    }
                    { handlerApp.actions(row,permissions,'productlocation',handlerAction)}
                </>
            },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    return (
        <div className="app container">
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <h3>{title}</h3>
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data= {filteredItems}
                        pagination
                        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        selectableRows
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    )
}
export default ProductLocation2;