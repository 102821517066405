import { Box, Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, val, theme) {
    return {
      fontWeight:
        val.indexOf(option.code) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const BoldMultiplesSelect = ({title, value, name, options, onChange})=>{
    
    const [val, setVal] = useState([]);
    const theme = useTheme();

    useEffect(()=>{
        if(value){
            if(options.length > 0){
                setVal(value);
            }
        }
    },[options])

    const handleChange = (e) => {
        setVal(e.target.value);
        // console.log(e.target.value);
        onChange(e,name);
    };

    /* const handleClick = (e) => {
        e.preventDefault();
        console.log(val);
    }; */

    return(
        <FormControl className="w-100" variant="standard" size="small">
        <InputLabel id={"label-"+title}>{title}</InputLabel>
        <Select
            labelId={"label-"+title}
            id={"chip-"+title}
            multiple
            value={val}
            onChange={handleChange}
            // input={<OutlinedInput id={"select-multiple-chip-"+title} label={title} />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                    options.map(element=>{
                        return selected.map(value=>(value===element.code && <Chip key={element.code} label={element.name}/>))
                    })
                }
                </Box>
            )}
            MenuProps={MenuProps}
        >
            {
            options.map( (option,index) =>
                <MenuItem value={option.code} key={option.name+'-'+index} style={getStyles(option, val, theme)}>{option.name}</MenuItem>
            )
            }
        </Select>
        {/* <button onClick={handleClick}>Result</button> */}
    </FormControl>
        // <FormControl className="w-100" variant="standard" size="small">
        //     <InputLabel id={"label-"+title}>{title}</InputLabel>
        //     <Select
        //         labelId={"label-"+title}
        //         id={"chip-"+title}
        //         multiple
        //         value={personName}
        //         onChange={handleChange}
        //         input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        //         renderValue={(selected) => (
        //             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        //             {
        //                 names.map(val=>{
        //                     return selected.map(value=>(value===val.code && <Chip key={val.code} label={val.name} />))
        //                 }
                         
        //                 )
        //             }
        //             </Box>
        //         )}
        //         MenuProps={MenuProps}
        //     >
        //     {names.map((name) => (
        //         <MenuItem
        //         key={name.code}
        //         value={name.code}
        //         style={getStyles(name, personName, theme)}
        //         >
        //         {name.name}
        //         </MenuItem>
        //     ))}
        //     </Select>
        //     <button onClick={handleClick}>Result</button>
        // </FormControl>
        
    )
}

export default BoldMultiplesSelect;