import axios from "axios";
import env from "../../../env-local";
import logo from "../../../assets/img/logo.png";
import ButtonLink from "../../atoms/Buttons/ButtonLink";
import logoDark from "../../../assets/img/logo_black.png";
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, CircularProgress } from "@mui/material";
import { ReactComponent as EmptyImport } from '../../../assets/img/icons/actions/importCustomers.svg';

const ImportCustomerI = ()=>{

    const [loader, setLoader] = useState(false);
    const [t,i18n] = useTranslation("global");
    const [language,setLanguage] = useState("");
    const [error, setError] = useState();

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;
    
    const nav = useNavigate();

    const importClient = ()=>{
        console.log("import clients");
    }

    const doItLater = ()=>{
        let headers = {
            Authorization: (localStorage.getItem("token")) ? localStorage.getItem("token") : ""
        }
        axios.post(env.urlBackend + '/charge-customer/user-update',{},{
            headers
        })
        .then(response => {
            if(response.data.success){
                setLoader(false);
                nav("/calendar");
            }else{
                setError(t(response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
    }

    return(
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                        <h1 className="title-login">{t("customers.welcomeToTeam")}</h1>
                        <div className="text-center my-3">
                            <EmptyImport/>
                        </div>
                        <p className="light">{t("customers.barberComunitty")}</p>
                        <p className="light">{t("customers.barberComunittyThanks")}</p>
                        <div className="text-center">
                            <ButtonPrimary label={t('customers.importClients')} onClick={()=>{ importClient() }} type="button" />
                            <ButtonLink label={t('customers.doItLater')} onClick={()=>{ doItLater() }} />
                        </div>
                        {error &&
                            <Alert className="mb-3" severity="error">{error}</Alert>
                        }
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('contact')}}>
                <span className="material-symbols-outlined">help</span>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )

}


export default ImportCustomerI;