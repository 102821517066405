import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import Header from "../../molecules/Header";

const Companies = () => {
    const endpoint = "companies";
    const parent = "Company";
    const [t] = useTranslation("global");
    const title = t('companyComponent.companies');
    const prefix = "company";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        typeIdentification: []
    });
    
    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id,action)=>{
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        // {
        //     id: 'company_id',
        //     name: 'id',
        //     selector: row => row.company_id,
        //     sortable: true,
        //     width: "70px"
        // },
        {
            id: 'company_name',
            name: t('companyComponent.company'),
            selector: row => row.company_name,
            sortable: true
        },
        {
            id: 'company_identification',
            name: t('companyComponent.identification'),
            selector: row => row.company_identification,
            sortable: true
        },
        {
            id: 'company_phone',
            name: t('phone'),
            selector: row => row.company_phone,
            sortable: true
        },
        {
            id: 'company_active',
            name: t('active'),
            width: "100px",
            cell: (row) => { return handlerApp.ChipActive(row.company_active) },
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions2(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setFilters({...filters,
                    typeIdentification: handlerApp.getFiltersFromData(response.data,'company_identification_type'),
                });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

  

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.company_id && item.company_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.company_name.toLowerCase().includes(filterText.toLowerCase())
            || item.company_phone?.toLowerCase().includes(filterText.toLowerCase())
            || item.company_identification?.toLowerCase().includes(filterText.toLowerCase())
            || item.company_identification_type?.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            <Header endpoint={endpoint} parent={parent} title={title} description={t("companyComponent.description")} />
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>Lista de </h4>
                <div className="row">
                    <div className="col-md-6">
                        <BoldSelect title={t("companyComponent.identificationType")} name="typeIdentification" onChange={ e => setFilterText(e.target.value)} value="" options={filters.typeIdentification}></BoldSelect>
                    </div>
                    <div className="col-md-6">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    {/* <div className="col-md-1 d-flex justify-content-end">
                        { permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        ) }
                        <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div> */}

                    
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                persistTableHead
            />
        </div>
    )
}

export default Companies;