import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import env from "../env-local";
import HandlerApp from "../utils/handlerApp";

export const useFetch = () => {
    const instance = axios.create({
        baseURL: env.urlBackend,
        headers: {
            Authorization: (localStorage.getItem("token")) ? localStorage.getItem("token") : "",
            Profile: (localStorage.getItem("profile")) ? localStorage.getItem("profile") : "",
            Company: (localStorage.getItem("company")) ? localStorage.getItem("company") : "",
            Location: (localStorage.getItem("location")) ? localStorage.getItem("location") : ""
        }
    })
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const logout = () => {
        localStorage.clear();
        Swal.fire({
            title: "",
            text: "Session Expired",
            icon: "info"
        });
    }

    const notPermissions = ()=>{
        Swal.fire({
            title: "",
            text: "Not permission",
            icon: "warning"
        });
        handlerApp.setLoader(false);
        nav("/");
    }

    const post = async (url, body = {},params="") => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            instance.post(url,body)
            .then( response =>{
                if (response.data.success) {
                    resolve(response.data);
                }else{
                    if (response.data.action === "closeSession") {
                        logout()
                        nav("/authenticate");
                    }else if(response.data.code === "0001"){
                        notPermissions();
                    }else {
                        resolve(response.data);
                    }
                }
            }).catch(error=>{
                reject(error.response.data);
            })
        });
    }

    const put = async (url, body = {},params="") => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            try{
                instance.put(url,body)
                .then( response =>{
                    if (response.data.success) {
                        resolve(response.data);
                    }else{
                        if (response.data.action === "closeSession") {
                            logout()
                            nav("/authenticate");
                        }else if(response.data.code === "0001"){
                            notPermissions();
                        }else {
                            resolve(response.data);
                        }
                    }
                }).catch(error=>{
                    reject(error.response.data);
                })
            }catch(error){
                reject(error.response.data);
            }
            
        });
    }

    const get = async (url, params) => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then( response =>{
                if (response.data.success) {
                    resolve(response.data);
                }else{
                    if (response.data.action === "closeSession") {
                        logout()
                        nav("/authenticate");
                    }else if(response.data.code === "0001"){
                        notPermissions();
                    }else {
                        resolve(response.data);
                    }
                }
            }).catch(error=>{
                reject(error.response.data);
            })
        });
    }
    const deleteMethod = async (url, body = {}, params="") => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            instance.delete(url,body)
            .then( response =>{
                if (response.data.success) {
                    resolve(response.data);
                }else{
                    if (response.data.action === "closeSession") {
                        logout()
                        nav("/authenticate");
                    }else if(response.data.code === "0001"){
                        notPermissions();
                    }else {
                        resolve(response.data);
                    }
                }
            }).catch(error=>{
                reject(error.response.data);
            })
        });
    }

    return { post, put, get, deleteMethod, nav };
}