import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../theme/Barberlytics/css/login.css"
import logo from "../../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import axios from "axios";
import env from "../../env-local";
import ButtonLink from "../atoms/Buttons/ButtonLink";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const PrivacyPoliciesScreen = () => {
    const [loader, setLoader] = useState(false);
    const nav = useNavigate();

    const [t,i18n] = useTranslation("global");
    const [language,setLanguage] = useState("");
    const [privacy,setPrivacy] = useState("");

    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang === "" || lang === null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
        sendRequest();
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])


    const sendRequest = () => {
        setLoader(true);
        axios.get(env.urlBackend+'/configuration/public-policies')
        .then(response => {
            if (response.data.success) {
                let data = response.data.data;
                for(let element of data){
                    if(element.policy_type !== "Terms and Conditions"){
                        setPrivacy(element.policy_content);
                    }
                }
                setLoader(false);
            } else {
                setLoader(false);
                Swal.fire({
                    title: '',
                    text: (response.data.message === null) ? 'An error has ocurred' : t(response.data.message),
                    icon: 'error'
                })
            }
        })
        .catch(error=>{
            setLoader(false);
            Swal.fire({
                title: '',
                text: (error.data.message === null) ? 'An error has ocurred' : t(error.data.message),
                icon: 'error'
            })
        })
    };
    
    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={logo} alt="logo-brava" className="logo-login"/>
                        <h1 className="title-login">{t("policiesComponent.privacyPolicies")}</h1>
                        <div className="text-box-policies">
                            <div dangerouslySetInnerHTML={{ __html: privacy }}>
                            </div>
                        </div>
                        <center>
                            <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>} onClick={()=> nav("/authenticate")} type="button"/>
                        </center>
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('contact')}}>
                <span className="material-symbols-outlined">help</span>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default PrivacyPoliciesScreen;