import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";


const UsersCompaniesView = () => {
    const endpoint = "configuration/userscompanies";
    const [t] = useTranslation("global");
    const prefix = "usercompany";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const { id } = useParams();
    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let data = response.data[0];
                    setTitle(data.storage_name);
                    setData(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }, [])

    const back = () => {
        nav(-1);
    }

    return (
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('fullname')}</dt>
                        <dd>{data.people_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('username')}</dt>
                        <dd>{data.user_username}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('email')}</dt>
                        <dd>{data.person_email}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('phone')}</dt>
                        <dd>{data.person_phone}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('companyComponent.company')} </dt>
                        <dd>{data.company_name}</dd>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data["created_by"]}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data[prefix + "_created_at"])}</span>
                    </div>
                    <div className="col-md-6">
                        {data["modified_by"] !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data["modified_by"]}</span>}
                    </div>
                    <div className="col-md-6">
                        {data[prefix + "_modified_at"] !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data[prefix + "_modified_at"])}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersCompaniesView;