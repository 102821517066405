import { useTranslation } from 'react-i18next';
import './HeaderScreen.css';
import { Link } from 'react-router-dom';

const HeaderScreen = ({title, description, actions, library="fontawesome"}) =>{
    const [t] = useTranslation('global');

    return(
        <section className="container-header-screen">
            <div className="d-flex justify-content-between w-100">
                <section className="section-title-screen">
                    <h3 className="title-screen">{title}</h3>
                    <p className="light">{description}</p>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {t("actions")}
                    </button>
                    <ul className="dropdown-menu">
                        { 
                            actions = actions.map( (x, i) =>{
                                return <li key={i}>
                                            <Link to={x.hasOwnProperty("function")? "#" : x.endpoint} className="dropdown-item actions-link" onClick={x.function}> 
                                                <div className="dropdown-item-group">
                                                    {
                                                        (library === "fontawesome") ?
                                                            <span className="dropdown-item-icon">
                                                                <i className={`fa-regular ${x.icon}`}></i> 
                                                            </span>
                                                        :
                                                            <span className="dropdown-item-icon">
                                                                <img src={x.icon} />
                                                            </span>
                                                    }                                                   
                                                    <span className="dropdown-item-title">{x.name}</span> 
                                                </div>
                                            </Link>
                                        </li>
                            })
                        }
                    </ul>
                    {/* {handlerApp.returnDropDownActions()} */}
                </div>
            </div>
        </section>
    )
}
export default HeaderScreen;