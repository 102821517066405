
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import { DatePicker } from "@mui/x-date-pickers";
import { LocationContext } from "../../../context/LocationContext";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";

import moment from "moment/moment";
import NavbarEditServices from "./NavbarEditServices";

const ServicesLocationForms = () => {

    const endpoint = "services/services-locations";
    const parent = "Services";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState(t('servicesLocation.createServicesLocation'));
    const api = useFetch();
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();

    // parametros
    const [services, setServices] = useState([]);
    const [taxesgroup, setTaxesgroup] = useState([]);
    const [startDiscount, setStartDiscount] = useState("");
    const [endDiscount, setEndDiscount] = useState("");
    const { location } = useContext(LocationContext);

    const [formData, setFormData] = useState({
        service_id: "",
        location_id: "",
        servicelocation_price: "",
        taxgroup_id: "",
        servicelocation_visibility: null,
        servicelocation_discount: 0,
        servicelocation_discount_start: null,
        servicelocation_discount_end: null,
    });

    const [data, setData] = useState([]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    const validateLocation = true;
    useEffect(() => {
        if (app.hasOwnProperty('location')) {
            if (validateLocation) {   // valida si esta activa la verificacion del sucursal.
                if (location === "") { // no hay sucursal seleccionada
                    handlerApp.showAlertLocation(); // muestra una notificacion al usuario solicitando que por favor seleccione una sucursal.
                } else {
                    getParamsUpdate();  // agrego xq esta pantalla depende de las sucursales en el estado.
                }
            } else {
                getParamsUpdate();  // agrego xq esta pantalla depende de las sucursales en el estado.
            }
        }
    }, [location])


    useEffect(() => {
        if (startDiscount?.hasOwnProperty('_d')) {
            setFormData({ ...formData, servicelocation_discount_start: startDiscount?.format('YYYY-MM-DD') });
        }
    }, [startDiscount]);

    useEffect(() => {
        if (endDiscount?.hasOwnProperty('_d')) {
            setFormData({ ...formData, servicelocation_discount_end: endDiscount?.format('YYYY-MM-DD') });
        }
    }, [endDiscount]);



    const handleDependencies = (data) => {

        let d = filterData(data, formData);

        if (d.servicelocation_discount_start != null) {
            setStartDiscount(moment(d.servicelocation_discount_start));
        }

        if (d.servicelocation_discount_end != null) {
            setEndDiscount(moment(d.servicelocation_discount_end));
        }

        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: 'getParamsUpdate',
            location
        })
            .then(response => {
                if (response.success) {
                    setServices(handlerApp.getResultFromObject(response.data, 'services'));
                    setTaxesgroup(handlerApp.getResultFromObject(response.data, 'taxesgroup'));

                    if (id !== undefined) {
                        setTitle(t('servicesLocation.updateServicesLocation'));
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitForm = (data)=>{
        console.log(data);
        handlerApp.setLoader(true);
        let body = {
            ...formData,
            location_id: location
        }
        api.post(endpoint,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
      
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data)=>{
        handlerApp.setLoader(true);
        let body = {
            ...formData, 
            location_id: location
        }
        api.put(endpoint+"/"+id,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCheck = (e) => {
        let key = e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.checked
        });
    }

    return (
        <div className="app container">
    <div className="d-flex justify-content-between">
        <section className="section-title-page">
            <h3 className="title-page ps-3">{t(title)}</h3>
        </section>
        <div className="d-flex">
            <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>Cancel</p>
        </div>
    </div>
            <NavbarEditServices id={id} user_id={formData.user_id}/>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <TextField name="servicelocation_price" onChange={handleForm} value={formData.servicelocation_price} label={t('servicesLocation.price')} required variant="outlined" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('services')}
                                value={formData.service_id}
                                name="service_id"
                                onChange={handleForm}
                                options={services}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('taxesComponent.title')}
                                value={formData.country_code}
                                name="taxgroup_id"
                                onChange={handleForm}
                                options={taxesgroup}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="servicelocation_discount" onChange={handleForm} value={formData.servicelocation_discount} label={t('productComponent.discount')} variant="outlined" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('productLocationComponent.discount_start')}
                                format="YYYY/MM/DD"
                                name="servicelocation_discount_start"
                                variant="outlined"
                                onChange={(val) => { setStartDiscount(val) }}
                                value={startDiscount}
                                slotProps={{ textField: { size: 'small', variant: "outlined" } }}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('productLocationComponent.discount_end')}
                                format="YYYY/MM/DD"
                                name="servicelocation_discount_end"
                                variant="outlined"
                                onChange={(val) => { setEndDiscount(val) }}
                                value={endDiscount}
                                slotProps={{ textField: { size: 'small', variant: "outlined" } }}
                            />
                        </div>

                        <div className="col-md-12 mb-2">
                            <div className="row np-row">
                                <div className="col-md-12">
                                    <FormControlLabel control={<Checkbox name="servicelocation_visibility" checked={formData.servicelocation_visibility} onChange={handleCheck} />} label={t('servicesLocation.visibility')} /><br />
                                    <span className="text-gray light small-text">{t('servicesLocation.visibilityDetail')}</span>
                                </div>
                            </div>
                        </div>


                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ServicesLocationForms;
