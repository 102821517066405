import React, { useEffect } from "react";
import { AppContextProvider } from "../context/AppContext"
import { LoaderContextProvider } from "../context/LoaderContext";
import { LocationContextProvider } from "../context/LocationContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import global_es from "../translate/es/global.json";
import global_en from "../translate/en/global.json";
import Init from "./theme/Barberlytics/Init";

const App =()=>{
    const browserLanguage = navigator.language

    i18next.init({
        interpolation: {escapeValue: false},
        lng: browserLanguage,
        resources: {
            es: {
                global: global_es
            },
            en: {
                global: global_en
            }
        }
    })


    return(
        <I18nextProvider i18n={i18next}>
            <AppContextProvider>
                <LoaderContextProvider>
                    <LocationContextProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Init/>
                        </LocalizationProvider>
                    </LocationContextProvider>
                </LoaderContextProvider>
            </AppContextProvider>
        </I18nextProvider>
    )
}

export default App;