import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";


const ProductsView = ()=>{
    const endpoint = "inventory/products";
    const [t,i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.product_name);
                
                // categories
                let categories = JSON.parse(response.data[0].categories);
                response.data[0].categories = categories[0]?.category_name;

                // subcategories
                let subcategories = JSON.parse(response.data[0].subcategories);
                response.data[0].subcategories = subcategories[0]?.subcategory_name;
                // extras
                let extras = response.data[0].extras;
                response.data[0].extras = printData(extras,"item_name");

                // ingredients
                let ingredients = response.data[0].ingredients;
                response.data[0].ingredients = printData(ingredients,"item_name");
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    },[])

    const printData = (data,key)=>{
        let str = "";
        data.forEach((element,index) => {
            if(index == 0){
                str+= element[key];
            }else{
                str+= ","+element[key];
            }
        });
        return str;
    }

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row np-row">
                    { data.images !== "" && 
                        <>
                        <div className="col-md-4 d-flex mb-4">
                            <section className="section-image-view" style={{backgroundImage: `url('${data.images}')`}}>
                            </section>
                        </div>
                        </>
                    }
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.product')}:</dt>
                                <dd>{data.product_name}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.productSpanishName')}:</dt>
                                <dd>{data.product_name_spanish}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('categoryComponent.category')}:</dt>
                                <dd>{data.categories}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('subcategoryComponent.subcategory')}:</dt>
                                <dd>{data.subcategories}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>SKU:</dt>
                                <dd>{data.product_sku}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.price')}:</dt>
                                <dd>{handlerApp.formatCurrency(data.product_price)}</dd>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('productComponent.cost')}:</dt>
                        <dd>{handlerApp.formatCurrency(data.product_cost)}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('productComponent.currency')}:</dt>
                        <dd>{data.product_currency}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('productComponent.productView')}:</dt>
                        <dd>{data.product_view}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>{t('productComponent.link')}:</dt>
                        <dd>{data.product_link}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>{t('englishDescription')}</dt>
                        <dd>{data.product_description}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>{t('spanishDescription')}</dt>
                        <dd>{data.product_description_spanish}</dd>
                    </div>
                    <div className="form-title col-md-12">
                        {t('productComponent.additionalInformation')}
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>Extras</dt>
                        <dd>{data.extras}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>{t('productComponent.ingredients')}</dt>
                        <dd>{data.ingredients}</dd>
                    </div>
                </div>
                <div className="footer-section-view row mt-4">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.product_created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.product_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.product_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.product_modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.product_modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.product_modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default ProductsView;