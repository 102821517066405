import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfiniteAddItems from "../../../utils/InfiniteAddItems/InfiniteAddItems";
import { useForm } from "react-hook-form";


const TaxesGroup = ()=>{
    const endpoint = "configuration/taxesgroup";
    const [t,i18n] = useTranslation("global");
    const title = t('taxesComponent.title');
    const api = useFetch();
    const nav = useNavigate();
    const {id} = useParams();
    const handlerApp = HandlerApp();
    let location = handlerApp.getLocation();
    
    const [data, setData] = useState();
    const {register, handleSubmit, formState : {errors}, setValue} = useForm()


    // Permisos
    const [permissions,setPermissions] = useState([]);
    const {app} = useContext(AppContext);
    useEffect(()=>{
        setPermissions(app.permissions[endpoint]);
        location = handlerApp.getLocation();
    },[app])

    // Data
    const [formData,setFormData] = useState({
        taxgroup_name: "",
        location_id:   "",
        taxgroup_description: "",
        taxes:         []
    });
    
    const handleDependencies = (data)=>{
        let d = filterData(data,formData);
        setFormData(d);
        setData(d);
    }

    const filterData = (data,formData)=>{
        let finalData = {};
        for(let key in formData){
            finalData[key] = data[key];
        }
        return finalData;
    }

    const [fields,setFields] = useState();

    useEffect(()=>{
        setFields([
            {
                type: "text",
                name: "tax_name",
                label: t('taxesComponent.tax_name'),
                variable: 'text'
            },
            {
                type: "percent",
                name: "tax_percentage",
                label: t('taxesComponent.tax_percentage'),
                variable: 'numeric'
            }
        ])
    },[])

    const sendRequest = ()=>{
        api.get(endpoint+"/"+id,)
        .then(response =>{
            if(response.success){
                let data = response.data[0];
                setData(response.data[0]);
                handleDependencies(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    useEffect(()=>{
        handlerApp.setLoader(true);
        if(id !== undefined){
            sendRequest();
        }else{
            handlerApp.setLoader(false);
        }
    },[])
    

    const handleInfiniteAdd = (result,name)=>{
        setFormData({...formData, [name]: result});
        
    }

        // --------------- FORM -----------------
    const handleForm = (e,name=null,dependency=null)=>{
        let key = (name!==null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value,
        });
        if(dependency !== null){
            dependency(e.target.value);
        }
    }

    const back = ()=>{
        nav(-1);
    }

    // save data
    const handleSubmitForm = (e)=>{
        e.preventDefault();
        handlerApp.setLoader(true);
        // handlerApp.setLoader(true);
        let body = {
            ...formData, location_id:location
        }  
        api.post(endpoint,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (e)=>{
        e.preventDefault();
        handlerApp.setLoader(true);
        let body = {
            ...formData, location_id:location
        }
        api.put(endpoint+"/"+id,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const handleCancel = (e)=>{
        e.preventDefault();
        nav(-1);
    }

    return (
        <div className="app container">
            <span className="material-symbols-outlined" role="button" onClick={back}>arrow_back</span>
            <div className="d-block text-center mb-4">
                <h3>{title}</h3>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <TextField label={t("taxesComponent.taxgroup_name")} variant="outlined" size="small"
                                {...register("taxgroup_name",{ required: true, maxLength: 40})}
                                value={formData.taxgroup_name}
                                onChange={handleForm}
                                error={errors.taxgroup_name!= null}
                                helperText = {
                                    errors.taxgroup_name?.type === 'required' ? t("errors.required") : 
                                    errors.taxgroup_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-12 mt-2 mb-2">
                            <TextField name="taxgroup_description" multiline rows={3} size="small"
                                {...register("taxgroup_description",{ required: false, minLength: 10})}
                                onChange={handleForm} 
                                value={formData.taxgroup_description} 
                                label={t('taxesComponent.description')} 
                                helperText = {
                                    errors.taxgroup_description?.type === 'minLength' ? t("errors.minLength") : ""
                                }
                                 />
                        </div>
                        <div className="col-md-12 mb-2 ph-20">
                            <InfiniteAddItems name="taxes" fields={fields} values={formData.taxes} onChange={handleInfiniteAdd} title={t('taxesComponent.tax_associated')} requestChange={data}/>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>

                {/* <form>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <TextField name="taxgroup_name" onChange={handleForm} value={formData.taxgroup_name} label={t('taxesComponent.taxgroup_name')} required variant="outlined" size="small" />
                        </div>
                        <div className="col-md-12 mt-2 mb-2">
                            <TextField name="taxgroup_description" onChange={handleForm} value={formData.taxgroup_description} label={t('taxesComponent.description')} multiline rows={3} size="small" />
                        </div>
                        <div className="col-md-12 mb-2 ph-20">
                            <InfiniteAddItems name="taxes" fields={fields} values={formData.taxes} onChange={handleInfiniteAdd} title={t('taxesComponent.tax_associated')} requestChange={data}/>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('save')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>Update</button>}
                            </div>
                        </div>
                    </div>
                </form> */}
            </div>
        </div>   
    )
}

export default TaxesGroup;