
import { useEffect, useState, useMemo, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../../context/LocationContext";
import ResourcesForm from "./ResourcesForm";
import ResourcesView from "./ResourcesView";

const Resources = ({id}) => {
    const endpoint = "services/resources";
    const parent = "Services";
    const [t,i18n] = useTranslation("global");
    const title = t('resourcesComponent.resources');
    const prefix = "resource";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        location: [],
        type: []
    });

    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    const [levelId, setLevelId] = useState(null);
    const [levelIdView, setLevelIdView] = useState(null);
    
    // filtros
    // const [type,setType] = useState([]);
    // const [locations, setLocations] = useState([]);
    // useEffect(()=>{
    //     setFilte
    // },[type,locations])

    // location
    const {location} = useContext(LocationContext);
    const validateLocation = true;
    useEffect(()=>{
        setPermissions(app.permissions[endpoint]);
        if(app.hasOwnProperty('location')){
            if(validateLocation){   // valida si esta activa la verificacion del sucursal.
                if(location === ""){ // no hay sucursal seleccionada
                    handlerApp.showAlertLocation(); // muestra una notificacion al usuario solicitando que por favor seleccione una sucursal.
                    setData([]);
                }else{
                    setData([]);
                    sendRequest();  // agrego xq esta pantalla depende de las sucursales en el estado.
                }
            }else{
                setData([]);
                sendRequest();  // agrego xq esta pantalla depende de las sucursales en el estado.
            }
        }
    },[location])

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                setLevelIdView(id);
                setOpenView(true);
            break;
            case 'update':
                setLevelId(id);
                setOpen(true);
            break;
            case 'delete':
                deleteRow(id);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        {
            id: 'resource_id',
            name: 'id',
            selector: row => row.resource_id,
            sortable: true,
        },
        {
            id: 'resource_name',
            name: t('resourcesComponent.resource'),
            selector: row => row.resource_name,
            sortable: true
        },
        {
            id: 'resource_quantity',
            name: t('resourcesComponent.quantity'),
            selector: row => row.resource_quantity,
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions2(row,permissions,'resource',handlerAction)},
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint, {
            location : location
        })
        .then(response => {
            if(response.success){
                // setFilters({...filters,
                //     'type': getFiltersFromData(response.data,'storage_type'),
                //     location: getFiltersFromData(response.data,'location_name')
                // });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const getFiltersFromData = (data,key)=>{
        let filter = [];
        data.forEach(element => {
            if(!filter.includes(element[key]) ){
                filter.push(element[key]);
            }
        });
        let finalFilter = [];
        filter.map(e => {
            if(e != null){
                finalFilter.push({code: e, name: e})
            }
        });
        return finalFilter;
    }


    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.resource_name && item.resource_name.toLowerCase().includes(filterText.toLowerCase())
            || item.resource_id.toString().toLowerCase().includes(filterText.toLowerCase())
    );

   
    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">En esta sección podrás tener todo el control de tus recursos.</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions()}
                </div>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>Lista de Recursos </h4>
                <div className="row">
                    <div className="col-md-4">
                        {/* <BoldSelect title={t("storageComponent.location")} name="location" onChange={ e => setFilterText(e.target.value)} value="" options={"1,2,3"}></BoldSelect> */}
                    </div>
                    <div className="col-md-4">
                        {/* <BoldSelect title={t("storageComponent.storageType")} name="type" onChange={ e => setFilterText(e.target.value)} value="" options={"1,2,3"}></BoldSelect> */}
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        { permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={handleClickOpen}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        ) }
                        <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>

                    
                </div>

                {
                    open && <ResourcesForm open={open} setOpen={setOpen} id={levelId} request={sendRequest} setLevelId={setLevelId}/>
                }

{
                    openView && <ResourcesView openView={openView} setOpenView={setOpenView} id={levelIdView} setLevelIdView={setLevelIdView}/>
                }

            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
            />
        </div>
    )
}

export default Resources;