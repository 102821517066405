import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import HandlerApp from "../../../../utils/handlerApp";
import {  useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderEditScreen from "../../HeaderScreen/HeaderEditScreen";
import { useForm } from "react-hook-form";
import BoldUploadImage from "../../BoldUploadImage/BoldUploadImage";
import BoldSelectValidated from "../../../../utils/BoldSelectValidated";
import HandlerCountriesStatesCities from "../../../../utils/handlerCountriesStatesCities";
import InputText from "../../componentes/Inputs/InputText/InputText";
import InputPhone from "../../componentes/Inputs/InputPhone/InputPhone";

const NewLocationsForm = ()=>{
    const endpoint              = "companies/locations";

    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const nav                   = useNavigate();
    const handlerLocations      = HandlerCountriesStatesCities();
    
    const params                = useParams();
    const id                    = params?.id;

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()
    
    const [title, setTitle]                 = useState(t('locationComponent.createLocation'));
    const [companies, setCompanies]         = useState([]);
    const [currencies, setCurrencies]       = useState([]);
    const [languages, setLanguages]         = useState([]);
    const [countries, setCountries]         = useState([]);
    const [states, setStates]               = useState([]);
    const [cities, setCities]               = useState([]);
    const [country, setCountry]             = useState("");
    const [state, setState]                 = useState("");
    const [city, setCity]                   = useState("");
    const [timezones, setTimezones]         = useState("");

    const [formData, setFormData] = useState({
        location_name: "",
        location_legal_name: "",
        location_tax_id: "",
        company_id: "",
        country_code: "",
        state_code: "",
        city_code: "",
        location_address: "",
        location_zipcode: "",
        location_phone:"",
        location_description:"",
        language_id: "",
        location_currency: "",
        location_image: "",
        location_timezone: "",
        location_gallery: []
    });


    useEffect(() => {
        getParams();
    }, [])

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setCompanies(handlerApp.getResultFromObject(response.data, 'companies'))
                setCountries(handlerApp.getResultFromObject(response.data, "countries"))
                setCurrencies(handlerApp.getResultFromObject(response.data, 'currencies'));
                setLanguages(handlerApp.getResultFromObject(response.data, 'languages'));
                setTimezones(handlerApp.getResultFromObject(response.data, 'timezones'));
                if (id !== undefined) {
                    setTitle(t('locationComponent.updateLocation'));
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            console.log(response);
            if (response.success) {
                // setData(response.data);
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        setCountry(data.country_code);
        setState(data.state_code);
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

     // save data
     const handleSubmitForm = (data) => {
        console.log(data);
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            console.log(response);
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        console.log(data);
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            console.log(response);
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }


    const handleImages = (e)=>{
        setValue('location_image',e);
    }

    /**
     * @deprecated Eliminar dependencia de handle form, user un nuevo select validate.
     */
    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    // countries
    const handleSelectCountry = (val) => {
        if (val != null) {
            setCountry(val);
            setStates([]);
            setCities([]);
        }
    }
    // states
    const handleSelectState = (val) => {
        setState(val);
        setCities([]);
    }
    // cities
    const handleSelectCity = (val) => {
        setCity(val);
    }

    useEffect(() => {
        if (country != null) {
            getState();
        }
    }, [country])

    useEffect(() => {
        if (state != null) {
            getCities()
        }
    }, [state])

    const getState = ()=>{
        handlerLocations.getStates(country)
        .then(data=>{
            setStates(data);
        })
    }

    const getCities = () => {
        handlerLocations.getCities(country,state)
        .then(data=>{
            setCities(data);
        })
    }

    return(
        <div className="app container">
            <HeaderEditScreen title={title}/>
            <div className="section-forms-location ms-4">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <section className="formularios row">
                        <div className="col-md-12 mb-4">
                            {/* <BoldUploadImage value={"https://png.pngtree.com/background/20230612/original/pngtree-wolf-animals-images-wallpaper-for-pc-384x480-picture-image_3180467.jpg"} /> */}            
                            <BoldUploadImage handleImages={handleImages} value={formData.location_image} icon="storefront" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("locationComponent.locationName")}
                                    name="location_name"
                                    errors={errors}
                                    rules={{ required: true, maxLength: 80 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("locationComponent.legalName")}
                                    name="location_legal_name"
                                    errors={errors}
                                    rules={{ required: true, maxLength: 120 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("locationComponent.taxId")}
                                    name="location_tax_id"
                                    errors={errors}
                                    rules={{ required: true, maxLength: 30 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('companyComponent.company')}
                                    value={formData.company_id}
                                    name="company_id"
                                    onChange={handleForm}
                                    options={companies}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('country')}
                                    value={formData.country_code}
                                    name="country_code"
                                    onChange={handleForm}
                                    options={countries}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectCountry}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('state')}
                                    value={formData.state_code}
                                    name="state_code"
                                    onChange={handleForm}
                                    options={states}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectState}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('city')}
                                    value={formData.city_code}
                                    name="city_code"
                                    onChange={handleForm}
                                    options={cities}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectCity}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("address")}
                                    name="location_address"
                                    register={register}
                                    errors={errors}
                                    rules={{  maxLength: 120 }}
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">    
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("zipCode")}
                                    name="location_zipcode"
                                    register={register}
                                    errors={errors}
                                    rules={{  maxLength: 8 }}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputPhone
                                    label={t("phone")}
                                    name="location_phone"
                                    register={register}
                                    errors={errors}
                                    control={control}
                                    onChange={(e)=>{console.log(e)}}
                                />
                                {/* <BoldInputPhone
                                    label={t("phone")}
                                    name="location_phone"
                                    register={register}
                                    errors={errors}
                                /> */}
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('locationComponent.currency')}
                                    value={formData.location_currency}
                                    name="location_currency"
                                    onChange={handleForm}
                                    options={currencies}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('locationComponent.language')}
                                    value={formData.language_id}
                                    name="language_id"
                                    onChange={handleForm}
                                    options={languages}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('timezone')}
                                    value={formData.location_timezone}
                                    name="location_timezone"
                                    onChange={handleForm}
                                    options={timezones}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputText
                                    label={t("taxesComponent.description")}
                                    name="location_description"
                                    multiline={true}
                                    rows={3}
                                    register={register}
                                    errors={errors}
                                    rules={{ maxLength: 300 }}
                                    control={control}
                                />
                            </div>
                        </div>
                    </section>
                    <div className="row d-flex justify-content-end">
                        <div className="d-flex justify-content-end me-5">
                            {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('create')}</button>}
                            {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                        </div>
                     </div>
                    
                </form>
            </div>
        </div>
    )
}

export default NewLocationsForm;