import { useEffect, useRef, useState } from "react";
import "./InfiniteAddItems.css";
import { useTranslation } from "react-i18next";

const InfiniteAddItems = ({fields,values,onChange, name, title, requestChange}) => {

    const container = useRef();
    const firstElement = useRef();
    const [elements,setElements] = useState(0);
    const [results,setResults] = useState({});
    const [t,i18n] = useTranslation("global");


    useEffect(()=>{
        let initialResult = [];
        resetData();
        if(values.length > 0){
            values.forEach((value,index)=>{
                addLineInitital(value,index+1);
                initialResult[index+1] = {};
                for(let e of fields){
                    let name = e.name
                    initialResult[index+1][name] = value[name];
                }
                console.log(initialResult);
                setResults(initialResult);
                window[name] = initialResult;
            })
        }else{
            resetData();
            addLineInitital();
        }
    },[requestChange])

    const resetData = ()=>{
        let lineElements = document.querySelectorAll("[data-row]");
        lineElements.forEach(element =>{element.remove()});
        setElements(0);
    }

    const addLineInitital=(value=null, index=null)=>{
        let clone = firstElement.current.cloneNode(true);
        clone.classList.remove('no-view');
        clone.dataset.row = index;
        let fields = clone.children;
        if(value !== null){
            for(let e of fields){
                if(e !== undefined){
                    let field = e.children[1];
                    let name = field.name;
                    field.value = value[name];
                }
            }
        }
        setElements(index);
        clone.addEventListener('change',handleFields2);
        container.current.append(clone)
    }

    const handleFields2 = (e)=>{
        let element = e.target;
        let parent = element.parentNode.parentNode;
        let sons = parent.querySelectorAll('.bold-infinite-field');
        let index = parent.dataset.row;
        let obj = {}
        sons.forEach(field => {
            console.log(field.dataset.variable); 
            if(field.dataset.variable==='numeric'){
                obj[field.name]= (!isNaN(parseInt(field.value))) ? parseInt(field.value): null;
            }else{
                obj[field.name]= field.value
            }
        });
        console.log(obj);
        let data =[];
        if(window[name] !=  undefined){
            data = window[name];
            data[index] = obj;
            window[name] = data;
        }else{
            data[index] = obj;
            window[name] = data;    
        }
        // data[index] = obj;
        // window[name] = data;
        setResults(data);
        // TODO: desserts configuration - eliminar handleFields y dejar solo este.
        // TODO: desserts configuration - terminar backend actualizar.
        // TODO: desserts configuration - solicitar que se seleccione una sucursal.
    }

    // const addLine = (e)=>{
    //     e.preventDefault();
    //     let clone = firstElement.current.cloneNode(true);
    //     clone.classList.remove('no-view');
    //     clone.dataset.row = elements + 1;
    //     clone.addEventListener('change',handleFields);
    //     setElements(elements+1);
    //     container.current.append(clone)
    // }

    const addLine = (e)=>{
        e.preventDefault();
        let clone = firstElement.current.cloneNode(true);
        clone.classList.remove('no-view');
        clone.dataset.row = elements + 1;
        clone.addEventListener('change',handleFields2);
        setElements(elements+1);
        container.current.append(clone);
    }

    const handleFields = (e)=>{
        let element = e.target;
        let parent = element.parentNode.parentNode;
        let sons = parent.querySelectorAll('.bold-infinite-field');
        let index = parent.dataset.row;
        let obj = {}
        sons.forEach(field => {
            obj[field.name]= field.value
        });
        // setResults({...results,[index]: obj});
        // window[name] = {...results,[index]: obj};
    }

    useEffect(()=>{
        onChange(results,name);
    },[results]);

    return (
        <>
            <div className="section-list infinite-rows">
                <div className="bold-infinite-title-section">
                    <h3>{title}</h3>
                    <button className="bold-infinite-option" onClick={addLine}>
                        <span className="material-symbols-outlined">add</span> {t('add')}
                    </button>
                </div>
                <div ref={container}>
                    <div ref={firstElement} data-row="0" className="bold-infinite-row mb-2 no-view">
                        {
                        (fields?.map((e,index)=>{
                            if(e.type === "select"){
                                return (
                                    <div key={e.name} className="bold-box-input">
                                        <label className="bold-infinite-label">{e.label}</label>
                                        <select className="bold-infinite-field" key={e.name} name={e.name} data-variable={e.variable}>
                                            <option key="init" value=""></option>
                                            {
                                            e.values?.map((option)=>{
                                                return <option key={option.code} value={option.code}>{option.name}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                )
                            }
                            if(e.type === "text"){
                                return (
                                    <div key={e.name} className="bold-box-input">
                                        <label className="bold-infinite-label">{e.label}</label>
                                        <input className="bold-infinite-field" type="text" name={e.name} data-variable={e.variable}></input>
                                    </div>
                                )
                            }
                            if(e.type === "percent"){
                                return (
                                    <div key={e.name} className="bold-box-input">
                                        <label className="bold-infinite-label">{e.label}</label>
                                        <input className="bold-infinite-field" type="text" name={e.name} data-variable={e.variable}></input>
                                    </div>
                                )
                            }
                        }))
                        }
                    </div>
                </div>
            </div>
            {/* <button onClick={(e)=>{
                e.preventDefault();
                console.log(results)
            }}>result</button> */}
        </>
    )
}

export default InfiniteAddItems;