import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import Header from "../../molecules/Header";

const Barbers = () => {
    const endpoint = "team/usersbarber";
    const parent = "Team";
    const [t] = useTranslation("global");
    const title = t('userBarbersComponent.team');
    const description =t('usersComponent.teamDescription');
    const prefix = "userbarber";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);
    const [filters, setFilters] = useState({
        location: [],
        type: []
    });
    const [type, setType] = useState("Commission");


    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    useEffect(() => {
        sendRequest();
    }, [type])


    // filtros

    // const [type,setType] = useState([]);
    // const [locations, setLocations] = useState([]);
    // useEffect(()=>{
    //     setFilte
    // },[type,locations])


    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const columns = [
        {
            id: 'userbarber_id',
            name: 'id',
            selector: row => row.userbarber_id,
            sortable: true,
            width: "50px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_fullname',
            name: t('name'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.user_photo != null ) ? 
                            <img className="barber-image-inside" src={row.user_photo} alt={row.person_fullname}/>
                            : <div className="barber-image-inside">{row.person_firstname.substring(0,1)+row.person_surname.substring(0,1)}</div>
                            }
                        </div>
                        <p>{row.person_fullname}</p>
                    </div>
                )
            },
            sortable: true,
            width: "200px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_id',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'barberlevel_name',
            name: t('levelComponent.level'),
            selector: row => row.barberlevel_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_email',
            name: t('email'),
            selector: row => row.person_email,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_phone',
            name: t('phone'),
            selector: row => row.person_phone,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'userbarber_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.userbarber_active) },
            selector: row => row.customer_active === 1 ? "Active" : "Inactive",
            sortable: true,
            width: "120px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            name: t("action"),
            cell: (row) => { return handlerApp.actions2(row, permissions, prefix, handlerAction) },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px",
            conditionalCellStyles: handlerApp.borderColumn()
        }
    ];

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: 'filter',
            'userbarber_type': type
        })
            .then(response => {
                if (response.success) {
                    setFilters({
                        ...filters,
                        location: handlerApp.getFiltersFromData(response.data, 'location_name'),
                        level: handlerApp.getFiltersFromData(response.data, 'barberlevel_name')
                    });
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }




    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.userbarber_id && item.userbarber_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.person_fullname.toLowerCase().includes(filterText.toLowerCase())
            || item.barberlevel_name.toLowerCase().includes(filterText.toLowerCase())
            || item.userbarber_reputation?.toLowerCase().includes(filterText.toLowerCase())
            || item.person_email.toLowerCase().includes(filterText.toLowerCase())
            || item.userbarber_type.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.person_phone.toString().toLowerCase().includes(filterText.toLowerCase())

    );

    return (
        <div className="app container">
            
            <Header endpoint={endpoint} parent={parent} title={title} description={description} />
            
            {/* Espacio para los botones */}
            <div className="btn-group btn-group-sm btn-group-main" id="barber-buttons-group" role="group" aria-label="buttons">
                <input type="radio" className="btn-check" name="userbarber_type" id="commission" value="Commission" onChange={(e) => { setType(e.target.value) }} checked={type == "Commission" ? true : false} />
                <label className="btn btn-outline-primary btn-border-none btn-barbertype" htmlFor="commission">{t("userBarbersComponent.commission")}</label>

                <input type="radio" className="btn-check" name="userbarber_type" id="rent" value="Rent" onChange={(e) => { setType(e.target.value) }} checked={type == "Rent" ? true : false} />
                <label className="btn btn-outline-primary btn-border-none btn-barbertype" htmlFor="rent">{t("userBarbersComponent.rent")}</label>

            </div>

            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("userBarbersComponent.barberlist")}</h4>
                <div className="row filter-elements">
                    <div className="col-md-3">
                        <BoldSelect title={t("locationComponent.location")} name="location" onChange={e => setFilterText(e.target.value)} value="" options={filters.location}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <BoldSelect title={t("levelComponent.level")} name="level" onChange={e => setFilterText(e.target.value)} value="" options={filters.level}></BoldSelect>
                    </div>
                    <div className=" col-md-6 ">
                        <div className="input-icon input-search serch-container d-flex flex-row ">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {/* {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>} */}
                            <button className="btn btn-primary-yellow-1" onClick={(e)=>{e.preventDefault(); }}>{t("search")} </button>
                            {/* <span className="material-symbols-outlined">search</span> */}
                        </div>
                        {/* <div className="col-md-1 d-flex justify-content-end">
                            {permissions?.indexOf("create") !== -1 && (
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                    <span className="material-symbols-outlined">add</span>
                                </button>
                            )}
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div> */}
                    </div>


                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                persistTableHead
                striped
            />
        </div>
    )
}

export default Barbers;