import HandlerApp from "../../../../utils/handlerApp";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import CardInfo from "../../componentes/CardInfo";
import RoundImage from "../../componentes/RoundImage";
import ViewField from "../../componentes/ViewField";

const NewLocationsView = ()=>{

    const [t]           = useTranslation("global");
    const api           = useFetch();
    const nav           = useNavigate();
    const {id}          = useParams();
    const handlerApp    = HandlerApp();
    
    const endpoint      = "companies/locations";
    const actions       = [
        {name: t("locationComponent.updateLocation"), endpoint: `/${endpoint}/edit/${id}`, icon: "fa-pen"}
    ]

    const [title]                   = useState(t('locationComponent.viewLocation'));
    const [location,setLocation]    = useState({});

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setLocation(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app container">
            <HeaderViewScreen title={title} actions={actions}/>
            <section className="info-entity d-flex row w-100 m-auto">
                <div className="d-flex justify-content-evenly mt-2">
                    <CardInfo title="clients" value={109}/>
                    <CardInfo title="teams" value={7}/>
                    <CardInfo title="Licensed" value={"10/05/2023"}/>
                </div>
                <div className="col-md-12 mt-3 mb-4">
                    <RoundImage src={location.location_image} icon="fa-store"/>
                </div>
                <div className="col-md-6">
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("name")} value={location.location_name} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("taxid")} value={location.location_tax_id} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("country")} value={location.country_name} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("state")} value={location.state_name} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("city")} value={location.city_name} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("address")} value={location.location_address} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("phone")} value={location.location_phone} />
                    </div>
                        
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("zipCode")} value={location.location_zipcode} />
                    </div>
                    <div className="col-md-12">
                        <ViewField label={t("taxesComponent.description")} value={location.location_description} />
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {location.location_created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(location.location_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {location.location_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {location.location_modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {location.location_modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(location.location_modified_at)}</span>}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default NewLocationsView;