import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import HandlerApp from "../../../../utils/handlerApp";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFecth";


const TableCustomers = ({data, endpoint, permissions, prefix, setRefresh})=>{

    const [t]                       = useTranslation("global");
    const handlerApp                = HandlerApp();
    const api                       = useFetch();
    const nav                       = useNavigate();

    const [internData, setInternData]                       = useState([]);
    const [filterText, setFilterText]                       = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = data?.filter(
        item => item.customer_id && item.customer_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.company_name.toLowerCase().includes(filterText.toLowerCase())
            || item.name_customer.toLowerCase().includes(filterText.toLowerCase())
            || item.customer_type?.toLowerCase().includes(filterText.toLowerCase())
            || item.customer_phone?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    useEffect(()=>{
        setInternData(data);
    }, [data])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                setRefresh();
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setRefresh();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setRefresh();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        // {
        //     id: 'customer_id',
        //     name: 'id',
        //     selector: row => row.customer_id,
        //     sortable: true,
        //     width: "60px"
        // },
        {
            id: 'customer_fullname',
            name: t('name'),
            cell: (row) => {
                return (
                    <div className="d-flex ai-center">
                        <Avatar sx={{ bgcolor: "#254c99" }}>{row.customer_fullname.slice(0, 2).toUpperCase()}</Avatar>
                        <span className="ps-3 ph-3">{row.customer_fullname}</span>
                    </div>
                );
            },
            sortable: true,
        },
        {
            id: 'location_id',
            name: t('storageComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'customer_type',
            name: t('customers.type'),
            cell: (row) => { return handlerApp.ChipCustomer(row.customer_type); },
            selector: row => row.customer_type,
            sortable: true
        },
        {
            id: 'customer_ltv',
            name: t('customers.ltv'),
            selector: () => '$700.00',
            sortable: true,
            width: "90px",
        },
        {
            id: 'customer_phone',
            name: t('phone'),
            selector: row => row.customer_phone,
            sortable: true
        },
        {
            id: 'customer_email',
            name: t('email'),
            selector: row => row.customer_email,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'customer_appoiments',
            name: t('customers.appoiments'),
            selector: () => '234',
            sortable: true,
            width: "145px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'customer_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.customer_active) },
            selector: row => row.customer_active === 1 ? "Active" : "Inactive",
            sortable: true,
            width: "100px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'action',
            name: t("action"),
            cell: (row) => { return handlerApp.actions2(row, permissions, prefix, handlerAction) },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    return(
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            persistTableHead
        />
    )
}
export default TableCustomers;