import TableCustomers from "./TableCustomers";
import HandlerApp from "../../../../utils/handlerApp";
import HeaderScreen from "../../HeaderScreen/HeaderScreen";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { NavLink } from "react-router-dom";

const NewCustomers = ()=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const endpoint      = "customers";
    const parent        = "Customers";
    const title         = t('customers.customers');
    const description   = t('customers.descriptionScreen');
    
    const actions = [
        {name: t("customers.action.create"), endpoint: `/${endpoint}/create`, icon: "fa-user-plus"},
        {name: t("customers.action.importClients"), endpoint: `/${endpoint}/import`, icon: "fa-arrow-up-to-line"},
        {name: t("customers.action.exportClients"), endpoint: `/${endpoint}/exports`, icon: "fa-arrow-down-to-line"}
    ];

    const {app}                             = useContext(AppContext);
    const [data, setData]                   = useState([]);
    const [menus,setMenus]                  = useState([]);
    const [permissions, setPermissions]     = useState([]);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions["customer"])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            console.log(response);
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    

    return(
        <div className="app container">
            <HeaderScreen title={title} description={description} actions={actions}/>
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <Header endpoint={endpoint} parent={parent} title={title} description={description}/> */}
            <TableCustomers data={data} endpoint={endpoint} permissions={permissions} prefix={"customer"} setRefresh={sendRequest}/>
        </div>
    )
}

export default NewCustomers;