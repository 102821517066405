import "./InputPhone.css";
import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Controller } from "react-hook-form";

/**
 * 
 * @param name Nombre de campo - key
 * @param label Etiqueta a mostrar del campo 
 * @param register register de react-hook-form
 * @param errors manejador de errores
 * @param onChange callback - funcion ejecutada por los cambios en el componenete, retorna (value, name)
 * @param data value - valor por defecto del componente
 * @author Daniel Bolivar - Github debb94 - daniel.bolivar.freelance@gmail.com
 * @since 2024-07-11
 * @version 1.0
 */
const InputPhone = ({name, label, register, errors, control, data})=>{
    const [t] = useTranslation("global");
    const [openOptions,setOpenOptions] = useState(false);
    const [value, setValue] = useState(data || "");
    const [country, setCountry] = useState(data?.substring(0,3) === "+57" ? "+57" : "+1");
    const [image, setImage] = useState();

    useEffect(()=>{
        let prefix = "";
        prefix = data?.substring(0,3);
        if(prefix === "+57"){
            setCountry("+57");
            setValue(data);
        }
        prefix = data?.substring(0,2);
        if(prefix === "+1"){
            setCountry("+1");
            setValue(data);
        }
    },[data]);

    useEffect(()=>{
        if(country){
            let img = options.filter(e=> e.value == country)
            setImage(img[0].image);
        }
        if(country === "+57" && value.substring(0,3) !== "+57"){
            setValue(country+value.substring(2));
        }
        if(country === "+1" && value.substring(0,2) !== "+1"){
            setValue(country+value.substring(3));
        }
    },[country])

    useEffect(()=>{
        // onChange(value, name);
    },[value])


    const options = [
        {
            value: "+1",
            label: "Estados Unidos",
            image: "/assets/countries/Estados-Unidos.png"
        },
        {
            value: "+57",
            label: "Colombia",
            image: "/assets/countries/Colombia.png"
        },
    ]

    return(
            // <Controller
            //     control={control}
            //     // rules={rules}
            //     name={name}
            //     render={({ field: { onChange, onBlur, value } }) => (
            //         <TextField
            //             // {...field}
            //             className="text-view"
            //             label={label}
            //             variant="outlined"
            //             onChange={(e)=>{
            //                 console.log(e);
            //                 let len = e.target.value.length;
            //                 if(len >= country.length && /^\+[1-9]\d{0,13}$/.test(e.target.value)){
            //                     onChange(e.target.value);
            //                 }
            //             }}
            //             size="small"
            //             {...register(name)}
            //             value={value || ''} // Asegúrate de que el valor se pase correctamente
            //             InputLabelProps={{
            //                 shrink: Boolean(value) || Boolean(value === 0) // Esto mueve el label hacia arriba si hay un valor en el campo
            //             }}
            //             error={Boolean(errors[name])}
            //             helperText={
            //                 errors[name]?.type === 'required' ? t("errors.required") :
            //                 errors[name]?.type === 'maxLength' ? t("errors.maxLength") : ""
            //             }
            //         />
            //     )}
            // />
            <>
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                            className="text-view"
                            label={label}
                            variant="outlined"
                            {...register(name)}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                console.log(newValue); // Imprime el valor que el usuario está escribiendo
                                let len = newValue.length;
                                if (len >= country.length && /^\+[1-9]\d{0,13}$/.test(newValue)) {
                                    onChange(newValue); // Actualiza el valor en el controlador si pasa la validación
                                }
                            }}
                            size="small"
                            value={value || ''} // Asegúrate de que el valor se pase correctamente
                            InputLabelProps={{
                                shrink: Boolean(value) || Boolean(value === 0) // Esto mueve el label hacia arriba si hay un valor en el campo
                            }}
                            error={Boolean(errors[name])}
                            helperText={
                                errors[name]?.type === 'required' ? t("errors.required") :
                                errors[name]?.type === 'maxLength' ? t("errors.maxLength") : ""
                            }
                        />
                    )}
                />
            </>
    )
}

export default InputPhone;