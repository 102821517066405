import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import './InputText.css';
import { Controller, useForm } from "react-hook-form";


const InputText = ({label,name,errors, rules, register,multiline=false,rows=3, control})=>{
    const [t] = useTranslation("global");
    return (
        <Controller
            control={control}
            rules={rules}
            name={name}
            render={({ field }) => (
                <TextField
                    {...field}
                    className="text-view"
                    label={label}
                    variant="outlined"
                    size="small"
                    {...register(name)}
                    value={field.value || ''} // Asegúrate de que el valor se pase correctamente
                    InputLabelProps={{
                        shrink: Boolean(field.value) || Boolean(field.value === 0) // Esto mueve el label hacia arriba si hay un valor en el campo
                    }}
                    multiline={multiline}
                    rows={rows}
                    error={Boolean(errors[name])}
                    helperText={
                        errors[name]?.type === 'required' ? t("errors.required") :
                        errors[name]?.type === 'maxLength' ? t("errors.maxLength") : ""
                    }
                />
            )}
        />
    )
}

export default InputText;