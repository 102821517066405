
import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";

const ServicesCategoriesForms = () => {
    const endpoint = "services/categories";
    const parent = "Services";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('servicesCategorie.createServicesCategory');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();
    const [images, setImages] = useState([]);


    // formulario
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        company_id: "",
        servicecategory_name: "",
        servicecategory_name_spanish: "",
        servicecategory_description: "",
        servicecategory_image: "",
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()



    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    if (id !== undefined) {
                        setTitle('servicesCategorie.updateServicesCategory')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                console.log("aqui 123");
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }, [])

    const handleImages = (e) => {
        setImages(e)
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData, servicecategory_image: images
        }
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        console.log(data);
        let body = {
            ...data, servicecategory_image: images
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }


    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <TextField label={t("englishName")} variant="outlined" size="small"
                                {...register("servicecategory_name", { required: true, maxLength: 40 })}
                                value={formData.servicecategory_name}
                                onChange={handleForm}
                                error={errors.servicecategory_name != null}
                                helperText={
                                    errors.servicecategory_name?.type === 'required' ? t("errors.required") :
                                        errors.servicecategory_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label={t("spanishName")} variant="outlined" size="small"
                                {...register("servicecategory_name_spanish", { required: false, maxLength: 100 })}
                                value={formData.servicecategory_name_spanish}
                                onChange={handleForm}
                                error={errors.servicecategory_name_spanish != null}
                                helperText={
                                    errors.servicecategory_name_spanish?.type === 'required' ? t("errors.required") :
                                        errors.servicecategory_name_spanish?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <TextField name="servicecategory_description" onChange={handleForm} value={formData.servicecategory_description} label={t('englishDescription')} multiline rows={3} size="small" />
                        </div>
                        <div className="col-md-12 mb-2">
                            <BoldFilesUpload returnImages={handleImages} multiple={false} value={formData.servicecategory_image} />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ServicesCategoriesForms;