import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';

const ServicesCategoriesView = () => {
    const endpoint = "services/categories";
    const [t, i18n] = useTranslation("global");
    const prefix = "category";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const { id } = useParams();
    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let data = response.data[0];
                    setTitle(data.category_name);
                    setData(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }, [])

    const back = () => {
        nav(-1);
    }

    console.log(data);

    const handleService = (id) => {
        nav("services/view/" + id);
    }

    return (
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>

                <div className="row np-row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                {(data.servicecategory_image === "" || data.servicecategory_image === undefined || data.servicecategory_image === null) &&
                                    <>
                                        <div className="col-md-12 d-flex mb-4 justify-content-center">
                                            <section className="section-image-view" style={{ backgroundImage: `url('${imageNotFound}')` }}>
                                            </section>
                                        </div>
                                    </>
                                }
                                {data.servicecategory_image !== "" && data.servicecategory_image !== undefined && data.servicecategory_image !== null &&
                                    <>
                                        <div className="col-md-12 d-flex mb-4 justify-content-center">
                                            <section className="section-image-view" style={{ backgroundImage: `url('${data.servicecategory_image}')` }}>
                                            </section>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("servicesCategorie.nameEnglish")} variant="outlined" size="small"
                                    value={data.servicecategory_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesCategorie.nameSpanish')} variant="outlined" size="small"
                                    value={data.servicecategory_name_spanish}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesCategorie.productsNumber')} variant="outlined" size="small"
                                    value={data.servicecategory_num_products}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('servicesCategorie.slug')} variant="outlined" size="small"
                                    value={data.servicecategory_slug}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('englishDescription')} variant="outlined" size="small"
                                    value={data.servicecategory_description}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>

                            <div className="col-md-12 mb-2">
                                <p>{t("servicesComponent.services")}</p>
                                <div className="d-flex justify-content-between" style={{ flexWrap: "wrap" }}>
                                    {data.services?.map((service, index) => (
                                        // <span className="areas-interest" style={{ marginTop: 10 }}  onClick={() => handleService(service.service_id)}>
                                        //     {service.service_name} 
                                        // </span>
                                        <Link to={`/services/view/${service.service_id}`} key={index} style={{ textDecoration: 'none',marginTop: 20 }}>
                                            <span className="areas-interest">{service.service_name}</span>
                                        </Link>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesCategoriesView;