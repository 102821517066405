import { Link, NavLink } from "react-router-dom";
import HandlerApp from "../../utils/handlerApp";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";


import "../../assets/css/App.css"
import { TeamCreateOptions } from "./TeamCreateOptions";
import { useTranslation } from "react-i18next";

//icons
import iconTeamMember from "../../assets/img/icons/actions/newMember.svg";
import iconLevel from "../../assets/img/icons/actions/newLevel.svg";
import iconLineUp from "../../assets/img/icons/actions/lineup-walkin.svg";

import iconService from "../../assets/img/icons/actions/newService.svg"
import iconCategory from "../../assets/img/icons/actions/newCategory.svg"
import iconCombo from "../../assets/img/icons/actions/newCombo.svg";
import iconResource from "../../assets/img/icons/actions/newSpecialResource.svg";
import iconBooking from "../../assets/img/icons/actions/bookingLineUp.svg";

const Header = ({endpoint, parent, title, description}) =>{

    const handlerApp = HandlerApp();
    const [menus,setMenus] = useState([]);
    const [permissions,setPermissions] = useState([]);
    const {app} = useContext(AppContext);
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [t, i18next] = useTranslation("global");

    let actions = [];

    //TODO: Validar los permisos del usuario en los actions

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    //functions
    const createMember = ()=> setOptionsVisible(!optionsVisible);

    //TODO: incluir el resto de parents con sus actions.
    switch(parent){
        case "Team":
            actions = [
                {name: t("usersComponent.createMember"), icon: iconTeamMember, function: createMember},
                {name: t("levelComponent.create"), endpoint: "/"+parent+"/barber-level/create", icon: iconLevel},
                {name: t("userBarbersComponent.Walkin&Online"), endpoint: "/team/users/walkin", icon: iconLineUp}
            ]
        break;
        case "Services":
            actions = [
                {name: t("servicesComponent.createService"), endpoint: "/"+parent+"/create", icon: iconService},
                {name: t("servicesComponent.createCategory"), endpoint: "/"+parent+"/categories/create", icon: iconCategory},
                {name: t("servicesComponent.createCombo"), endpoint: "/"+parent+"/combo/create", icon: iconCombo},
                {name: t("servicesComponent.createSpecialResource"), endpoint: "/"+parent+"/resources/create", icon: iconResource},
                {name: t("servicesComponent.bookingLineUp"), endpoint: '/services/lineup/combo', icon: iconBooking},
            ]
        break;
    }

    return(
        <section className="section_nav-header">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{description}</p>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {t("actions")}
                    </button>
                    <ul className="dropdown-menu">
                        { 
                        actions = actions.map(x =>{
                            return <li>
                                        <Link to={x.hasOwnProperty("function")? "#" : x.endpoint} className="dropdown-item actions-link" onClick={x.function}> 
                                            <div className="dropdown-item-group">
                                                <span className="dropdown-item-icon">
                                                    <img src={x.icon} />
                                                </span>
                                                <span className="dropdown-item-title">{x.name}</span> 
                                            </div>
                                        </Link>
                                    </li>
                        })
                        }
                    </ul>
                    {/* {handlerApp.returnDropDownActions()} */}
                </div>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>                    
                })}
            </nav>
            {(parent ==="Team") && <TeamCreateOptions endpoint={"team/users"} visible={optionsVisible} setOptionsVisible={setOptionsVisible}/>}
        </section>
    )

}
export default Header;