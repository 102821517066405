import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import HandlerApp from "../../../../utils/handlerApp";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFecth";


const TableLocations = ({data, endpoint, permissions, prefix, setRefresh})=>{

    const [t]                       = useTranslation("global");
    const handlerApp                = HandlerApp();
    const api                       = useFetch();
    const nav                       = useNavigate();

    const [internData, setInternData]                       = useState([]);
    const [filterText, setFilterText]                       = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = internData?.filter(
        item => item.location_id && item.location_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.location_name.toLowerCase().includes(filterText.toLowerCase())
            || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.country_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.location_address?.toLowerCase().includes(filterText.toLowerCase())
    );

    useEffect(()=>{
        setInternData(data);
    }, [data])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                setRefresh();
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setRefresh();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setRefresh();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        {
            id: 'location_name',
            name: t('name'),
            selector: row => row.location_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'team',
            name: t('userBarbersComponent.team'),
            selector: row => row.team,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn(),
            width: "90px"
        },
        {
            id: 'country_name',
            name: t('country'),
            selector: row => row.country_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'state_name',
            name: t('state'),
            selector: row => row.state_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'city_name',
            name: t('city'),
            selector: row => row.city_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_created_at',
            name: t('Date create'),
            selector: row => row.location_created_at,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.location_active) },
            selector: row => row.location_active === 1 ? "Active" : "Inactive",
            sortable: true,
            width: "120px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'action',
            name: t("actions"),
            cell:(row) => { return handlerApp.actions2(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true
        }
    ];

    return(
        <>
            <section className="filters">
                <h4>
                    {t('locationComponent.listLocation')}
                    <span className="counter-data-table">{internData.length} {(internData.length) ? t('locationComponent.location')+"s" : t('locationComponent.location') }</span>
                </h4>
                <div className="d-flex justify-content-end">
                    <div className="d-flex col-md-3">
                        <div className="input-icon input-search bg-transparent">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("searchIn")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                        <div className="col-md-3 d-flex justify-content-end">
                            <div className="btn-table-actions">
                                <button className="btn btn-primary-yellow-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('search')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                persistTableHead
            />
        </>
    )
}
export default TableLocations;