
import { useEffect, useState, useMemo, useContext } from "react";
import * as React from 'react';
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import Header from "../../molecules/Header";
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import BarberLevelsForm from "./BarberLevelsForm";
import BarberLevelsView from "./BarberLevelsView";

const BarberLevels = ({id}) => {
    const endpoint = "team/barber-level";
    const parent = "Team"
    const [t,i18n] = useTranslation("global");
    const title = t('levelComponent.levels');
    const description =t('usersComponent.teamDescription');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        location: [],
        type: []
    });

    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

     const [levelId, setLevelId] = useState(null);
     const [levelIdView, setLevelIdView] = useState(null);
    // filtros
    
    // const [type,setType] = useState([]);
    // const [locations, setLocations] = useState([]);
    // useEffect(()=>{
    //     setFilters
    // },[type,locations])

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                setLevelIdView(id);
                setOpenView(true);
            break;
            case 'update':
                setLevelId(id);
                setOpen(true);
            break;
            case 'delete':
                deleteRow(id);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }



    const columns = [
        // {
        //     id: 'barberlevel_id',
        //     name: 'id',
        //     selector: row => row.barberlevel_id,
        //     sortable: true,
        //     width: "75px"
        // },
        {
            id: 'barberlevel_name',
            name: t('levelComponent.level'),
            selector: row => row.barberlevel_name,
            sortable: true
        },
        {
            id: 'barberlevel_number_barbers',
            name: t('levelComponent.barbers'),
            selector: row => (row.barberlevel_number_barbers !== null && row.barberlevel_number_barbers !== "") ? row.barberlevel_number_barbers : 0,
            sortable: true
        },
        {
            id: 'barberlevel_comission',
            name: t('levelComponent.comission'),
            selector: row => handlerApp.formatPercent(row.barberlevel_comission),
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions2(row,permissions,'barberlevel',handlerAction)},
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }


    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.barberlevel_name && item.barberlevel_name.toLowerCase().includes(filterText.toLowerCase())
            || item.barberlevel_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.barberlevel_comission.toString().toLowerCase().includes(filterText.toLowerCase())
    );
 
    const handleOpen = () => setOpen(true);
   
    return (
        <div className="app container">
            <Header endpoint={endpoint} parent={parent} title={title} description={description} />

            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("levelComponent.listLevel")}</h4>
                <div className="row">
                    {/* <div className="col-md-4">
                        <BoldSelect title={t("storageComponent.location")} name="location" onChange={ e => setFilterText(e.target.value)} value="" options={filters.location}></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title={t("storageComponent.storageType")} name="type" onChange={ e => setFilterText(e.target.value)} value="" options={filters.type}></BoldSelect>
                    </div> */}
                    <div className="offset-md-8 col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        { permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={handleClickOpen}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        ) }
                        <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>

                    
                </div>
               
                <div>
      {
        open && <BarberLevelsForm open={open} setOpen={setOpen} id={levelId} request={sendRequest} setLevelId={setLevelId}/>
      }
      {
        openView && <BarberLevelsView openView={openView} setOpenView={setOpenView} id={levelIdView} setLevelIdView={setLevelIdView}/>
      }
     
    </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                persistTableHead
            />
        </div>
    )
}

export default BarberLevels;