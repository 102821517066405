import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import env from "../env-local";
import "./../assets/css/BoldFilesUpload.css";

const BoldFilesUpload = ({ returnImages, multiple, value }) => {

    const uploadSection = useRef();
    const boldInput = useRef();

    const [t,i18n] = useTranslation("global");

    // const formulario = useRef();
    const [loader, setLoader] = useState();
    const [formImages, setFormImages] = useState([]);

    // const [uploads, setUploads] = useState();
    // let imageList = [];
    // let formData = new FormData();

    useEffect(()=>{
        // console.log(value);
        let imageList = [];
        if(value?.length > 0){
            imageList.push({
                name: value,
                src: value
            })
        }
        setFormImages(imageList)
        returnImages(imageList);
    },[value]);

    const handleOnDragOver = (e) => {
        e.preventDefault();
        uploadSection.current.className = "section-bold-upload-files drag-over";
        // e.classList.add('drop-zone dragover');
        return false;
    };

    const handleOnDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log("ejecutado");
        const files = e.dataTransfer.files;
        // console.log(files);
        proccessImages(files, returnImages);
    }

    const handleClickAttach = (e) => {
        e.preventDefault();
        boldInput.current.click();
    }

    const onChangeUploads = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let images = e.target.files;
        let imageList = [];
        imageList = formImages;
        proccessImages(images, returnImages, imageList);
    }
    /* const sendState = (e) => {
        e.preventDefault();

        // formData.append("images[]", boldInput.current.files);
        // formulario.current.submit();

        // console.log("aca")
        // console.log(boldInput.current.files)
        // formData.append("images[]", boldInput.current.files);
        // fetch(env.urlBackend + "/upload-images", {
        //     method: "POST",
        //     body: JSON.stringify({
        //         images: formImages
        //     })
        // }).then(response => {
        //     console.log(response.data);
        // }).catch(error => {
        //     console.log(error);
        // })
    } */

    const proccessImages = (images, returnImages, imageList) => {
        setLoader(true);
        // let name = image.name.split('.');
        // let file = name[0];
        // let length = name.length;
        // let extension = name[length-1].toLowerCase();
        setFormImages([]);
        // multiples images ? si es falso solo se sube una imagen
        let bucleLength = (multiple) ? images.length : 1;
        imageList = [];
        if(value > 0){
            console.log("aca");
        }
        // if(!multiple){
        //     imageList = [];
        // }
        // console.log("aca")
        for (let i = 0; i < bucleLength; i++) {
            let image = images[i];
            // let infoImage = image.name.split('.');
            // let ext = infoImage[infoImage.length - 1].toLowerCase();
            let name = image.name;
            // Aqui se pueden agregar validaciones a las extenciones permitidas.
            buildImage(image, name) // podria cortarse las imagenes para reducir pesos.
                .then(result => {
                    imageList.push({
                        name: result.name,
                        src: result.pic.src
                    });
                    setFormImages(imageList);
                    returnImages(imageList);
                    if (i === images.length - 1) {
                        setLoader(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                    if (i === images.length - 1) {
                        setLoader(false);
                    }
                })
        }

    }

    const buildImage = (image, name) => {
        return new Promise((resolve, reject) => {
            let pic, fr;
            pic = new Image();
            fr = new FileReader();
            fr.onload = function () {
                pic.src = fr.result;
            }
            fr.readAsDataURL(image)

            pic.onload = () => {
                let obj = {
                    name,
                    pic
                }
                resolve(obj)
            }
        });
    }

    const removeItems = (e) => {
        e.preventDefault();
        boldInput.current.value = [];
        setFormImages([]);
    }


    return (
        <>
            {/* {loader && <div>
                <h1>CARGANDO...</h1>
            </div>
            } */}
            <div className="container-uploads">
                <div ref={uploadSection} className="section-bold-upload-files" onDragOver={handleOnDragOver} onDrop={handleOnDrop}>
                    {formImages.length > 0 &&
                        <>
                            {formImages.map((image, index) => (
                                <img src={image.src} key={index} alt={index} />
                            ))}
                        </>
                    }
                    {formImages.length === 0 &&
                        <div className="inner-upload">
                            <button className="bold-btn" onClick={handleClickAttach}>
                                <span className="material-symbols-outlined">
                                    attach_file
                                </span>
                            </button>
                            <span className="title-upload">
                                {t('dragAndDrop')}
                                
                            </span>
                        </div>
                    }
                </div>
                <div className="buttons-options">
                    <button onClick={handleClickAttach}>
                        <span className="material-symbols-outlined">
                            add
                        </span>
                        {t('add')}
                    </button>
                    <button onClick={removeItems}>
                        <span className="material-symbols-outlined">
                            delete_forever
                        </span>
                        {t('remove')}
                    </button>
                </div>
                <input ref={boldInput} onChange={onChangeUploads} hidden type="file" name="images[]" multiple={multiple} id="bold-upload-files"></input>
                {/* <form ref={formulario} action="http://localhost:8000/upload-images" method="POST" enctype="multipart/form-data">
                    <input ref={boldInput} onChange={onChangeUploads} type="file" name="images[]" multiple id="bold-upload-files"></input>
                </form> */}
            </div>
            {/* <button onClick={sendState}>Muestrame</button> */}
        </>
    )
}


export default BoldFilesUpload;