import './../../assets/css/App.css';
// import Widget from '../molecules/Widget';

function Home() {
  return (
    <div className="app">
      <div className='row'>
        {/* <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="1" title="Users" icon="person" value="30" compareText="New users the last week" compareValue="+50%" compareColor="green" />
        </div>
        <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="2" title="Projects" icon="rocket_launch" value="30" compareText="New users the last week" compareValue="+10%" compareColor="orange" />
        </div>
        <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="3" title="Websites" icon="browse_activity" value="30" compareText="New users the last week" compareValue="-10%" compareColor="red" />
        </div>
        <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="4" title="revenue" icon="attach_money" value="30" compareText="New users the last week" compareValue="+50%" compareColor="green" />
        </div> */}
      </div>
    </div>
  );
}

export default Home;
