import "./RoundImage.css";

const RoundImage = ({src, icon})=>{
    return(
        <>
            { 
                (src !== null) ?
                <section className="round-image-container">
                    <img className="round-image" src={src} alt="round-image"/>
                </section>
                :
                <section className="round-image-container">
                    <i className={`fa-regular ${icon}`}></i>
                </section>
            }
        </>
    )
}
export default RoundImage;