import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "../../../assets/css/TeamFeatures.css";
import HandlerApp from "../../../utils/handlerApp";


const CalendarView = ({ endpoint, visible, setOptionsVisibleView, setOptionsVisibleForm, visibleForm }) => {

    const [t, i18n] = useTranslation("global");
    const handlerApp = HandlerApp();


    const phone = <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.125 9.52734C13.7539 9.80078 14.1094 10.4844 13.918 11.168L13.3438 13.6562C13.2344 14.3125 12.6602 14.75 11.9766 14.75C5.35938 14.75 0 9.39062 0 2.77344C0 2.08984 0.4375 1.51562 1.09375 1.37891L3.58203 0.804688C4.26562 0.640625 4.94922 0.996094 5.22266 1.625L6.39844 4.35938C6.64453 4.93359 6.45312 5.61719 5.98828 6.02734L5.05859 6.76562C5.76953 7.96875 6.78125 8.98047 8.01172 9.69141L8.75 8.78906C9.16016 8.29688 9.84375 8.10547 10.4453 8.37891L13.125 9.52734ZM12.6602 10.8672C12.6875 10.8125 12.6602 10.7578 12.6055 10.7031L9.92578 9.55469C9.87109 9.52734 9.81641 9.55469 9.78906 9.58203L8.69531 10.9219C8.50391 11.168 8.14844 11.2227 7.875 11.0859C6.04297 10.1836 4.53906 8.67969 3.63672 6.84766C3.5 6.57422 3.58203 6.24609 3.80078 6.05469L5.14062 4.96094C5.19531 4.93359 5.19531 4.87891 5.16797 4.82422L4.01953 2.14453C3.99219 2.11719 3.96484 2.0625 3.91016 2.0625L3.88281 2.08984L1.39453 2.66406C1.33984 2.66406 1.28516 2.71875 1.28516 2.77344C1.28516 8.65234 6.09766 13.4375 11.9766 13.4375C12.0312 13.4375 12.0859 13.3828 12.0859 13.3555L12.6602 10.8672Z" fill="#37465A" />
    </svg>;

    const locationAdminIcon = <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.4375 2.5V16C2.4375 16.3164 2.68359 16.5625 3 16.5625H5.8125V14.3125C5.8125 13.3984 6.55078 12.625 7.5 12.625C8.41406 12.625 9.1875 13.3984 9.1875 14.3125V16.5625H12C12 16.7383 12 16.8789 12 17.0195C12 17.4766 12.1055 17.8984 12.3164 18.25C12.2109 18.25 12.1055 18.25 12 18.25H3C1.73438 18.25 0.75 17.2656 0.75 16V2.5C0.75 1.26953 1.73438 0.25 3 0.25H12C13.2305 0.25 14.25 1.26953 14.25 2.5V13.1875C13.5117 13.5742 12.9492 14.1719 12.5625 14.875V2.5C12.5625 2.21875 12.2812 1.9375 12 1.9375H3C2.68359 1.9375 2.4375 2.21875 2.4375 2.5ZM3.84375 3.90625C3.84375 3.625 4.08984 3.34375 4.40625 3.34375H6.09375C6.375 3.34375 6.65625 3.625 6.65625 3.90625V5.59375C6.65625 5.91016 6.375 6.15625 6.09375 6.15625H4.40625C4.08984 6.15625 3.84375 5.91016 3.84375 5.59375V3.90625ZM10.5938 3.34375C10.875 3.34375 11.1562 3.625 11.1562 3.90625V5.59375C11.1562 5.91016 10.875 6.15625 10.5938 6.15625H8.90625C8.58984 6.15625 8.34375 5.91016 8.34375 5.59375V3.90625C8.34375 3.625 8.58984 3.34375 8.90625 3.34375H10.5938ZM3.84375 8.40625C3.84375 8.125 4.08984 7.84375 4.40625 7.84375H6.09375C6.375 7.84375 6.65625 8.125 6.65625 8.40625V10.0938C6.65625 10.4102 6.375 10.6562 6.09375 10.6562H4.40625C4.08984 10.6562 3.84375 10.4102 3.84375 10.0938V8.40625ZM10.5938 7.84375C10.875 7.84375 11.1562 8.125 11.1562 8.40625V10.0938C11.1562 10.4102 10.875 10.6562 10.5938 10.6562H8.90625C8.58984 10.6562 8.34375 10.4102 8.34375 10.0938V8.40625C8.34375 8.125 8.58984 7.84375 8.90625 7.84375H10.5938ZM21 9.8125C21 11.3945 19.7344 12.625 18.1875 12.625C16.6055 12.625 15.375 11.3945 15.375 9.8125C15.375 8.26562 16.6055 7 18.1875 7C19.7344 7 21 8.26562 21 9.8125ZM13.125 17.0547C13.125 15.2266 14.5664 13.75 16.3945 13.75H19.9453C21.7734 13.75 23.25 15.2266 23.25 17.0547C23.25 17.7227 22.6875 18.25 22.0195 18.25H14.3203C13.6523 18.25 13.125 17.7227 13.125 17.0547Z" fill="white" />
    </svg>;


    const handleChandeModal = () => {
        setOptionsVisibleView(false);
        setOptionsVisibleForm(true);
    }



    return (
        <>
            {(visible) &&
                <section className="team_options-aside-fixed">
                    <div className="team_options-header">
                        <button className="team_options-close-button" onClick={() => { setOptionsVisibleView(!visible) }}>
                            <span class="material-symbols-outlined">
                                arrow_back
                            </span>
                        </button>
                        <h3 className="team_options-title">{t('calendar.viewAppoiment')}</h3>
                        <button className="team_options-close-button" onClick={handleChandeModal}>
                            <span className="material-symbols-outlined">Edit</span>
                        </button>
                    </div>
                    <div className="team_options-body">
                        <button className="team_card-creation">
                            <div className="team_circle-image"><span className="team_circle-icon">{locationAdminIcon}</span></div>
                            <div className="team_card-info">
                                <h3 className="team_card-info-title">Charlie Tailor</h3>
                                <p className="team_card-info-description">{phone} +1 408 2333 ****  </p>
                            </div>
                            <div className="team_card-info-right">
                                {
                                    handlerApp.ChipCustomer("New client")
                                }
                            </div>
                        </button>

                        <button className="team_card-creation">
                            <div className="team_circle-image"><span className="team_circle-icon">{locationAdminIcon}</span></div>
                            <div className="team_card-info">
                                <h3 className="team_card-info-title">{t('servicesComponent.service')}</h3>
                                <p className="team_card-info-description">Skin fades</p>
                            </div>
                        </button>


                        <button className="team_card-creation">
                            <div className="team_circle-image">
                                <span className="team_circle-icon">{locationAdminIcon}</span>
                            </div>
                            <div className="team_card-info">
                                <h3 className="team_card-info-title">{t('storageComponent.barber')}</h3>
                                <p className="team_card-info-description">
                                    Anderson Taylor
                                </p>
                            </div>
                        </button>

                        <button className="team_card-creation">
                            <div className="team_circle-image"><span className="team_circle-icon">{locationAdminIcon}</span></div>
                            <div className="team_card-info">
                                <h3 className="team_card-info-title">Date & Time</h3>
                                <p className="team_card-info-description">Jan 10 - 11:00 am (30 min)</p>
                            </div>
                        </button>
                    </div>

                    <div className="team_options-footer">
                        <div className="row d-flex justify-content-between mt-auto">
                            <div className="col-md-6 mb-2">
                                <p>Total</p>
                            </div>
                            <div className="col-md-6 mb-2 text-end">
                                <p>$40.00</p>
                            </div>
                            <div className="col-md-12 mb-2">
                                <button className="btn btn-primary-yellow-1 btn-full-width">{t('calendar.checkout')}</button>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}
export { CalendarView }
