
import { TextField } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";


const UserProfileForm = () => {

    const endpoint = "configuration/profile";
    const [t,i18n] = useTranslation('global');
    const [title, setTitle] = useState(t('myAccount'));

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const {app} = useContext(AppContext);
    
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    // utils
    const api = useFetch();
    const nav = useNavigate();
    const {id} = useParams();
    // data
    const [data, setData] = useState([]);
    // estado inicial de formulario
    const [formData,setFormData] = useState({
        person_firstname :              "",
        person_secondname :             "",
        person_surname:                 "",
        person_secondsurname:           "",
        person_sex:                     "",
        person_email:                   "",
        person_phone:                   "",
        person_occupation:              "",
        person_marital_status:          "",
        person_birthdate:               ""
    });


    const [maritalStatusList,setMaritalStatusList] = useState([]);
    const [sexList, setSexList] = useState([]);
    const [birthdate, setBirthdate] = useState("");

    const handleDependencies = (data)=>{
        let d = filterData(data,formData);
        if(d.person_birthdate!= null){
            setBirthdate(moment(d.person_birthdate));
        }
        setFormData(d);
    }

    const filterData = (data,formData)=>{
        let finalData = {};
        for(let key in formData){
            if(data[key] === undefined || data[key]===null){
                finalData[key] = "";
            }else{
                finalData[key] = data[key];
            }
        }
        return finalData;
    }

    const sendRequest = ()=>{
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                let data = response.data[0];
                setData(response.data)
                handleDependencies(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint,{
            action:"getParamsUpdate"
        })
        .then(response =>{
            if(response.success){
                setSexList(handlerApp.getResultFromObject(response.data,'sex'));
                setMaritalStatusList(handlerApp.getResultFromObject(response.data,'maritalstatus'));
                if(id !== undefined){
                    sendRequest();
                }else{
                    handlerApp.setLoader(false);
                }
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    },[])

    const handleForm = (e,name=null,dependency=null)=>{
        let key = (name!==null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if(dependency !== null){
            dependency(e.target.value);
        }
    }
    
    const handleCancel = (e)=>{
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmit = (e)=>{
        e.preventDefault();
        handlerApp.setLoader(true);
        api.post(endpoint,formData)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
         
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdate = (e)=>{
        e.preventDefault();
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id, formData)
        .then(response=>{
            if(response.success){
                // console.log(endpoint+'/'+id);
                // console.log(formData);
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/");
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            // console.log('error:' + endpoint+"/"+id, formData);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    useEffect(()=>{
        console.log(birthdate);
        if(birthdate?.hasOwnProperty('_d')){
            setFormData({...formData,person_birthdate: birthdate?.format('YYYY-MM-DD')});
        }
    },[birthdate]);
    
return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
            </div>
            <div className="section-forms">
                <form>
                    <div className="row">
                        <div className="col-md-12 form-username">
                            <div className="circle-name">{formData.person_firstname.substring(0,2)}</div>
                            <label>{data[0]?.user_username}</label>      
                        </div>
                        <div className="col-md-12 row">
                            <div className="col-md-6 mb-2">
                                <TextField name="person_firstname" onChange={handleForm} value={formData.person_firstname} label={t('name')} required variant="standard" size="small" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField name="person_surname" onChange={handleForm} value={formData.person_surname} label={t('surname')} required variant="standard" size="small" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField name="person_secondname" onChange={handleForm} value={formData.person_secondname} label={t('secondname')} variant="standard" size="small" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField name="person_secondsurname" onChange={handleForm} value={formData.person_secondsurname} label={t('secondsurname')} variant="standard" size="small" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <BoldSelect title={t('sex')} value={formData.person_sex} name="person_sex" options={sexList} onChange={handleForm} />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField name="person_email" onChange={handleForm} value={formData.person_email} label={t('email')} required variant="standard" size="small" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField name="person_phone" onChange={handleForm} value={formData.person_phone} label={t('phone')} required variant="standard" size="small" />
                            </div>
                            <div className="col-md-2 mb-2">
                                <TextField name="person_ext" onChange={handleForm} value={formData.person_ext} label={t('ext')} variant="standard" size="small" />
                            </div>
                            <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('personComponent.birthdate')}
                                format="YYYY/MM/DD"
                                name="person_birthdate" 
                                variant="standard"
                                onChange={(val)=>{ setBirthdate(val)}} 
                                value={birthdate}
                                slotProps={{ textField: { size: 'small',variant: "standard" } }}
                            />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField name="person_occupation" onChange={handleForm} value={formData.person_occupation} label={t('occupation')} variant="standard" size="small" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <BoldSelect title={t('maritalstatus')} value={formData.person_marital_status } name="person_marital_status" options={maritalStatusList} onChange={handleForm} />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>Update</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserProfileForm;
