import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";

const TaxesGroupView = () =>{
    
    const endpoint = 'configuration/taxesgroup';
    const [data, setData] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const api = useFetch();
    const {id} = useParams();
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const [t,i18n] = useTranslation("global");
    const [title, setTitle] = useState('');    

    useEffect(()=>{
        sendRequest();
    },[]);

    const sendRequest = () =>{
        handlerApp.setLoader(true);
        api.get(endpoint + '/'+ id)
        .then(response =>{
            if(response.success){
                let record = response.data[0];
                setData(record);
                setTaxes(record.taxes);
                setTitle((record.taxgroup_name).toUpperCase());
                handlerApp.setLoader(false);
            }
            else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const back = ()=>{
        nav(-1);
    }
   
    const columns = [
        {
            id: 'tax_name',
            name: t('taxesComponent.tax_name'),
            selector: row => row.tax_name,
            sortable: true,
        },
        {
            id: 'tax_percentage',
            name: t('taxesComponent.tax_percentage'),
            selector: row => row.tax_percentage,
            sortable: true,
        },
    ]
    
    return (
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row">
                <div className="col-md-6 mb-2">
                        <dt>{t('taxesComponent.taxgroup_name')}</dt>
                        <dd>{data.taxgroup_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.location')}</dt>
                        <dd>{data.location_name}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>{t('taxesComponent.description')}</dt>
                        <dd>{data.taxgroup_description}</dd>
                    </div>
                    
                    <div className="col-md-12">
                        <dt>{t('taxesComponent.tax_associated')}</dt>
                        {taxes.length > 0 ?  
                           (<DataTable
                                columns={columns}
                                data= {taxes}  
                            />)
                            : <dd>{t('taxesComponent.noTaxes')}</dd>
                        }      
                    </div>
                    
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.taxgroup_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.taxgroup_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.taxgroup_modified_at !== null && data.taxgroup_modified_at !== undefined && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.taxgroup_modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TaxesGroupView;